import clsx from 'clsx';
import { range } from 'ramda';
import { CSSProperties, FC, Fragment } from 'react';
import useEventsGridSettings from '../../hooks/useEventsGridSettings';

export type HoursBarProps = {
  style?: CSSProperties;
  className?: string;
};

const HoursBar: FC<HoursBarProps> = ({ className, style }) => {
  const { TOTAL_SEPARATORS, START_HOUR, END_HOUR } = useEventsGridSettings();
  const hours = range(START_HOUR, END_HOUR);

  return (
    <div
      className={clsx('sticky left-0 z-10 w-14 flex-none ring-1 ring-gray-100 pt-7 bg-gray-50', className)}
      style={style}
    >
      <div
        className='col-start-1 col-end-2 row-start-1 grid'
        style={{
          gridTemplateRows: `repeat(${TOTAL_SEPARATORS}, minmax(3.5rem, 1fr))`,
        }}
      >
        {/* before 1st hour sep */}
        {hours.map((hour) => (
          <Fragment key={hour}>
            <div>
              <div
                data-events-grid={`hour-${hour}`}
                className='sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400'
              >
                {hour}
              </div>
            </div>
            <div />
          </Fragment>
        ))}
        <div className={`row-start-${TOTAL_SEPARATORS} h-7`}>
          <div className='sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400'>
            {END_HOUR}
          </div>
        </div>
        {/* last hour sep */}
      </div>
    </div>
  );
};

HoursBar.displayName = 'HoursBar';

export default HoursBar;
