import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { FC, ReactNode } from 'react';
import Button from 'src/components/Button';
import FormDataRow, { FormDataRowProps } from 'src/components/FormDataRow';
import HelperText from 'src/components/common/HelperText';
import { twMerge } from 'tailwind-merge';
import ArrayInput from './ArrayInput';

export type ArrayTextFieldProps = {
  className?: string;
  FormDataRowProps?: Omit<FormDataRowProps, 'children' | 'label'>;
  disabled?: boolean;
  name: string;
  onChange: (value: string[]) => void;
  value: string[];
  hideErrorMessage?: boolean;
  error?: string;
  label: ReactNode;
};

const ArrayTextField: FC<ArrayTextFieldProps> = ({
  value,
  onChange,
  className,
  name,
  label,
  hideErrorMessage,
  error,
  disabled,
  FormDataRowProps,
}) => {
  const addInput = (): void => {
    const nextValue = [...value, ''];
    onChange(nextValue);
  };

  return (
    <FormDataRow
      labelClassName='self-start'
      label={
        <label className='flex' htmlFor={name}>
          {label}
          <Button disabled={disabled} variant='cta' size='small' onClick={addInput}>
            <PlusCircleIcon className='h-6 ml-1' />
          </Button>
        </label>
      }
      {...FormDataRowProps}
    >
      <div className={twMerge('flex flex-col gap-y-2 w-full', className)}>
        <ArrayInput name={name} value={value} onChange={onChange} />
        {!hideErrorMessage && error && <HelperText variant='error'>{error}</HelperText>}
      </div>
    </FormDataRow>
  );
};

export default ArrayTextField;
