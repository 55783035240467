import clsx from 'clsx';
import { FC, useState } from 'react';
import useTechnicianGridEventsFromDay from 'src/api/hooks/querySelectors/useTechnicianGridEventsFromDay';
import Month from 'src/components/Calendar/components/Month';
import { isXlOrBigger } from 'src/helpers/screenSize';
import useWindowWidth from 'src/hooks/useWindowWidth';
import useTechnicianCalendarOutletContext from 'src/pages/TechnicianAccountPage/hooks/useTechnicianCalendarOutletContext';
import DayEventsPopup from '../../components/DayEventsPopup';
import DayWithTechnicianEvents from '../../components/DayWithTechnicianEvents';
import EventsList from '../../components/EventsList';

const MonthTab: FC = () => {
  const { calendar, technicianId } = useTechnicianCalendarOutletContext();
  const { month, focusedDate, selectedDate, onDateClick } = calendar;
  const eventsFromSelectedDay = useTechnicianGridEventsFromDay(technicianId, selectedDate);

  const [dateToShowPopup, setDateToShowPopup] = useState<Date | null>(null);
  const [showDateEventsPopup, setShowDateEventsPopup] = useState(false);

  const windowWidth = useWindowWidth();
  const isXl = isXlOrBigger(windowWidth);

  const hasEventsFromSelectedDay = eventsFromSelectedDay.length > 0;

  const shouldShowEventsList = !isXl && hasEventsFromSelectedDay;

  return (
    <div className={clsx('flex flex-col border border-t-0 border-gray-300 overflow-hidden rounded-b-lg')}>
      <Month
        noBorderX
        noBorderBottom={!shouldShowEventsList}
        showWeekDays
        month={month}
        focusedDate={focusedDate}
        onDateClick={onDateClick}
        renderDay={({ dateInfo, onClick }) => (
          <DayWithTechnicianEvents
            key={dateInfo.date.getTime()}
            technicianId={technicianId}
            dateInfo={dateInfo}
            onClick={onClick}
            onShowMoreEvents={() => {
              setDateToShowPopup(dateInfo.date);
              setShowDateEventsPopup(true);
            }}
          />
        )}
      />
      {shouldShowEventsList && <EventsList events={eventsFromSelectedDay} />}
      <DayEventsPopup
        open={showDateEventsPopup}
        onClose={() => setShowDateEventsPopup(false)}
        date={dateToShowPopup}
        technicianId={technicianId}
      />
    </div>
  );
};

MonthTab.displayName = 'MonthTab';

export default MonthTab;
