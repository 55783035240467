import { useCallback } from 'react';
import { FC, useState } from 'react';
import { useApiClient } from 'src/api';
import PageContentHeader from 'src/components/PageContentHeader';
import WithLoader from 'src/components/utils/WithLoader';
import { write, utils } from 'xlsx';
import useSaveBlob from 'src/hooks/useSaveBlob';
import { FormattedMessage } from 'react-intl';
import BackIcon from 'src/components/Icons/BackIcon';
import { useNavigate } from 'react-router-dom';
import OrderToExcelFiltersFormDataProvider from './components/OrderToExcelFiltersFormDataProvider';
import useOrderToExcelRow from './hooks/useOrderToExcelRow';
import useOrderExcelRowHeader from './hooks/useOrderExcelRowHeader';
import { OrderToExcelFiltersSchema } from './components/OrderToExcelFiltersForm/schema';

export type ExportCustomFilteredOrdersPageProps = {
  //
};

const ExportCustomFilteredOrdersPage: FC<ExportCustomFilteredOrdersPageProps> = () => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState(false);
  const saveBlob = useSaveBlob();
  const back = useCallback(() => navigate(-1), [navigate]);
  const orderToExcelRow = useOrderToExcelRow();
  const excelHeader = useOrderExcelRowHeader();

  const handleDownload = useCallback(
    async (filters: OrderToExcelFiltersSchema) => {
      try {
        setIsLoading(true);
        const { technicianIds, statusProp, paymentMethods, ...restFitlers } = filters;
        const data = await apiClient.orders.getDetailedOrderList({
          ...restFitlers,
          ...(statusProp && { statusProp: statusProp.join(',') }),
          ...(technicianIds && { technicianIds: technicianIds.join(',') }),
          ...(paymentMethods && { paymentMethods: paymentMethods.join(',') }),
          orderBy: 'orderNumber',
          pageSize: Number.MAX_SAFE_INTEGER,
          pageIndex: 0,
        });
        const workbook = utils.book_new();
        const worksheet = utils.json_to_sheet([excelHeader, ...data.items.map(orderToExcelRow)], {
          skipHeader: true,
        });
        utils.book_append_sheet(workbook, worksheet);
        const buffer = write(workbook, { type: 'buffer', bookType: 'xlsx' });

        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveBlob(blob, `orders_${new Date().toISOString()}.xlsx`);
      } finally {
        setIsLoading(false);
      }
    },
    [apiClient],
  );

  return (
    <WithLoader isLoading={isLoading}>
      <PageContentHeader
        className='md:mb-20 mb-12'
        title={<FormattedMessage id='app.export.orders.cards.custom_export.title' />}
        hidePrimaryButton={true}
        secondaryButtonText={
          <>
            <BackIcon />
            <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
          </>
        }
        onSecondaryButtonClick={back}
      />
      <OrderToExcelFiltersFormDataProvider onSubmit={handleDownload} />
    </WithLoader>
  );
};

ExportCustomFilteredOrdersPage.displayName = 'ExportCustomFilteredOrdersPage';

export default ExportCustomFilteredOrdersPage;
