import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { OrderListItemResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useTechnicianMonthOrders = (
  technicianId: number,
  monthIndex: number,
  year: number,
): UseQueryResult<OrderListItemResponse[]> => {
  const apiClient = useApiClient();

  const { queryKey } = queryKeysFactory.technicianMonthOrders.list(technicianId, year, monthIndex);

  return useQuery(queryKey, () => apiClient.orders.getTechnicianMonthOrders({ technicianId, monthIndex, year }));
};

export default useTechnicianMonthOrders;
