import { FC } from 'react';
import useTechnicianGridEventsFromDay from 'src/api/hooks/querySelectors/useTechnicianGridEventsFromDay';
import Day from 'src/components/Calendar/components/Day';
import { DateInfo } from 'src/components/Calendar/types';

export type DayWithTechnicianEventsProps = {
  className?: string;
  dateInfo: DateInfo;
  onClick?: () => void;
  technicianId: number;
  onShowMoreEvents: () => void;
};

const DayWithTechnicianEvents: FC<DayWithTechnicianEventsProps> = ({
  className,
  technicianId,
  dateInfo,
  onClick,
  onShowMoreEvents,
}) => {
  const events = useTechnicianGridEventsFromDay(technicianId, dateInfo.date);

  return (
    <Day
      className={className}
      key={dateInfo.date.getTime()}
      onClick={onClick}
      onShowMoreEvents={onShowMoreEvents}
      dateInfo={dateInfo}
      events={events}
    />
  );
};

DayWithTechnicianEvents.displayName = 'DayWithTechnicianEvents';

export default DayWithTechnicianEvents;
