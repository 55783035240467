import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes, useCallback } from 'react';
import HelperText from 'src/components/common/HelperText';
import withController from 'src/hoc/withController';
import { FieldProps } from 'src/types/forms';

export type CheckboxBaseProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> & {
  hideErrorMessage?: boolean;
  error?: string | boolean;
  onChange: (value: boolean | null | undefined) => void;
};

export type CheckboxProps = Omit<FieldProps<boolean | null | undefined>, 'onChange'> & CheckboxBaseProps;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { value, checked, className, hideErrorMessage, error, onChange, ...rest } = props;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => onChange(e.target.checked),
    [onChange],
  );

  return (
    <div className='flex flex-col'>
      <input
        type='checkbox'
        className={clsx(
          'w-5 h-5 min-w-[1.25rem] min-h-[1.25rem] rounded-md shadow-md border border-gray-300 appearance-none checked:bg-primary',
          className,
        )}
        id={rest.name}
        {...rest}
        onChange={handleChange}
        checked={!!value ?? checked}
        ref={ref}
      />
      {!hideErrorMessage && error && <HelperText variant='error'>{error}</HelperText>}
    </div>
  );
});

export const CheckboxRhfc = withController(Checkbox);

export default Checkbox;
