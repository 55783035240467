import { FC, useEffect, useState } from 'react';
import AddServiceForm from '../AddServiceForm';
import {
  VatTariffResponse,
  HeadquartersResponse,
  OrderTypeListItemRepsonse,
  TenantConfigDto,
  NotificationResponse,
} from 'src/api';
import { OrderSchema } from '../ServiceForm/schema';
import AllTechniciansCalendar from 'src/pages/CalendarPage/components/AllTechniciansCalendar';
import { FormattedMessage } from 'react-intl';
import DoublePopup from 'src/components/DoublePopup';
import { GridEvent } from 'src/components/EventsGrid/types';
import { getDefaultPotentialEvents } from './helpers';

export type DesktopAddServicePopupProps = {
  open: boolean;
  onClose: () => void;
  defaultShowCalendar?: boolean;
  isLoading: boolean;
  defaultValues?: Partial<OrderSchema>;
  vatTariffs: VatTariffResponse[];
  headquarters: HeadquartersResponse[];
  orderTypes: OrderTypeListItemRepsonse[];
  config?: TenantConfigDto;
  notificationSettings?: NotificationResponse[];
};

const DesktopAddServicePopup: FC<DesktopAddServicePopupProps> = ({
  open,
  onClose,
  isLoading,
  config,
  orderTypes,
  defaultValues,
  vatTariffs,
  headquarters,
  notificationSettings,
  defaultShowCalendar,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);
  const [potentialEvents, setPotentialEvents] = useState<GridEvent[]>([]);

  useEffect(() => {
    setPotentialEvents(getDefaultPotentialEvents(defaultValues));
    setSelectedDate(defaultValues?._date ?? undefined);
  }, [defaultValues]);

  const handleClose = (): void => {
    setSelectedDate(undefined);
    setPotentialEvents([]);
    onClose();
  };

  const [showSecondPanel, setShowSecondPanel] = useState(!!defaultShowCalendar);

  return (
    <DoublePopup
      open={open && !isLoading}
      onClose={handleClose}
      rightTitle={null}
      containerClassName='w-[45vw]'
      leftTitle={<FormattedMessage id='app.order.add_order.popup_title' />}
      showSecondPanel={showSecondPanel}
      setShowSecondPanel={setShowSecondPanel}
      leftContent={
        <AddServiceForm
          defaultValues={defaultValues}
          config={config}
          orderTypes={orderTypes}
          vatTariffs={vatTariffs}
          headquarters={headquarters}
          notificationSettings={notificationSettings}
          onDateChange={setSelectedDate}
          onPotentialEventsChange={setPotentialEvents}
        />
      }
      rightContent={
        <AllTechniciansCalendar
          calendarOptions={{
            defaultFocusedDate: selectedDate,
          }}
          potentialEvents={potentialEvents}
          disableTechnicianPreviewOnClick
          disableEventPreviewOnClick
          disableDnd
          disableGridClick
        />
      }
    />
  );
};

export default DesktopAddServicePopup;
