import clsx from 'clsx';
import { startOfDay, addMinutes, format, addDays } from 'date-fns';
import { FC, useRef, useState } from 'react';
import useMoveOrder from 'src/api/hooks/mutations/order/useMoveOrder';
import useTechnicianGridEventsFromWeek from 'src/api/hooks/querySelectors/useTechnicianGridEventsFromWeek';
import EventsGrid from 'src/components/EventsGrid';
import DraggableEvent from 'src/components/EventsGrid/components/DraggableEvent';
import HorizontalLines from 'src/components/EventsGrid/components/HorizontalLines';
import HoursBar from 'src/components/EventsGrid/components/HoursBar';
import VerticalLines from 'src/components/EventsGrid/components/VerticalLines';
import { MINUTES_PER_ROW, ROWS_PER_HOUR } from 'src/components/EventsGrid/constants';
import { eventToGridWeek, getGridCellDimensions, gridToTimeSpanWeek } from 'src/components/EventsGrid/helpers/grid';
import useHandleDropEvent from 'src/components/EventsGrid/hooks/useHandleDropEvent';
import WithLoader from 'src/components/utils/WithLoader';
import { snapToHalfHour } from 'src/helpers/datetime';
import AddServicePopup from 'src/pages/HomePage/AddServicePopup';
import { OrderSchema } from 'src/pages/HomePage/ServiceForm/schema';
import useTechnicianCalendarOutletContext from 'src/pages/TechnicianAccountPage/hooks/useTechnicianCalendarOutletContext';
import WeekDaysHeader from '../../components/WeekDaysHeader';

const START_HOUR = 8;
const MIN_ENABLED_HOUR = 8;
const END_HOUR = 20;

const WeekTab: FC = () => {
  const { calendar, technicianId, disableDrag } = useTechnicianCalendarOutletContext();
  const { selectedDate, focusedDate } = calendar;
  const containerRef = useRef<HTMLDivElement>(null);
  const events = useTechnicianGridEventsFromWeek(technicianId, focusedDate);
  const { isLoading } = useMoveOrder();

  const handleDrop = useHandleDropEvent(events);

  const [isAddServicePopupOpen, setIsAddServicePopupOpen] = useState(false);
  const [addServicePopupDefaultValues, setAddServicePopupDefaultValues] = useState<Partial<OrderSchema>>({});

  const handleGridClick = (col: number, row: number): void => {
    const date = startOfDay(focusedDate);
    const start = addDays(addMinutes(date, snapToHalfHour(START_HOUR * 60 + MINUTES_PER_ROW * row)), col - 1);
    const end = addMinutes(start, 60);

    setAddServicePopupDefaultValues({
      technicianIds: [{ technicianId }],
      _date: start,
      _start: format(start, 'HH:mm'),
      _end: format(end, 'HH:mm'),
    });
    setIsAddServicePopupOpen(true);
  };

  return (
    <>
      {' '}
      <WithLoader isLoading={isLoading}>
        <div
          ref={containerRef}
          className={clsx('flex flex-col border border-gray-200 overflow-y-scroll max-h-[500px] rounded-b-lg')}
        >
          <WeekDaysHeader className='pl-14 pr-8' selectedDate={selectedDate} focusedDate={focusedDate} />
          <EventsGrid
            olClassName='pr-8 my-7'
            scrollContainerRef={containerRef}
            events={events}
            eventToGrid={eventToGridWeek}
            gridToTimeSpan={gridToTimeSpanWeek}
            getGridCellDimensions={getGridCellDimensions}
            cols={7}
            rows={ROWS_PER_HOUR * (END_HOUR - START_HOUR)}
            startHour={START_HOUR}
            endHour={END_HOUR}
            disabledColumns={[6, 7]}
            autoScrollToHour={MIN_ENABLED_HOUR}
            scrollTopOffest={64}
            minEnabledMinute={60 * MIN_ENABLED_HOUR}
            onDrop={handleDrop}
            onGridClick={handleGridClick}
            renderEvent={(event, props) => (
              <DraggableEvent key={event.id} disableDrag={disableDrag} {...event} {...props} />
            )}
            renderHoursBar={() => <HoursBar />}
            renderHorizontalLines={() => <HorizontalLines />}
            renderVerticalLines={() => <VerticalLines />}
          />
        </div>
      </WithLoader>
      <AddServicePopup
        defaultValues={addServicePopupDefaultValues}
        open={isAddServicePopupOpen}
        onClose={() => setIsAddServicePopupOpen(false)}
      />
    </>
  );
};

WeekTab.displayName = 'WeekTab';

export default WeekTab;
