import { FC } from 'react';
import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import { usePopupContext } from 'src/components/Popup/hook';
import FormDataRow from 'src/components/FormDataRow';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { FormattedMessage } from 'react-intl';
import Tooltip from 'src/components/common/Tooltip';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import useAddCustomTranslationPack from 'src/api/hooks/mutations/translations/useAddCustomTranslationPack';

export type AddCustomLanguageFormProps = {
  className?: string;
};

const AddCustomLanguageForm: FC<AddCustomLanguageFormProps> = ({ className }) => {
  const addTranslationPack = useAddCustomTranslationPack();
  const form = useForm<{ language: string }>();
  const { control, handleSubmit } = form;
  const { onClose } = usePopupContext();

  const onSubmit = (data: { language: string }): void => {
    addTranslationPack.mutate(data);
    onClose?.();
  };

  return (
    <FormProvider {...form}>
      <form className={clsx(className, 'flex flex-col w-full')} onSubmit={handleSubmit(onSubmit)}>
        <FormDataRow
          label={
            <div className='flex items-center'>
              <FormattedMessage id='app.common.language_code' />
              <Tooltip
                text={
                  <span className='inline-block max-w-sm w-max'>
                    <FormattedMessage id='app.language_code.tooltip' />
                  </span>
                }
              >
                <InformationCircleIcon className='w-6' />
              </Tooltip>
            </div>
          }
        >
          <TextFieldRhfc control={control} name='language' />
        </FormDataRow>
        <NewItemPopupFooter />
      </form>
    </FormProvider>
  );
};

AddCustomLanguageForm.displayName = 'AddCustomLanguageForm';

export default AddCustomLanguageForm;
