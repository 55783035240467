import { ColumnFiltersState } from '@tanstack/react-table';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import useUsers from 'src/api/hooks/queries/useUsers';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import useTableState from 'src/components/Table/hooks/useTableState';
import useLastDefined from 'src/hooks/useLastDefined';
import usePageBaseState from 'src/hooks/usePageBaseState';
import { UserFilter } from 'src/types/filters';
import AddUserForm from './AddUserForm';
import UserLimitExceededPopup from './UserLimitExceededPopup';
import UsersTable from './UsersTable';

const transformFilters = (filters: ColumnFiltersState): UserFilter => ({
  email: (filters.find((i) => i.id === 'user_email')?.value as string) ?? '',
  role: (filters.find((i) => i.id === 'role')?.value as string) ?? '',
});

const defaultSortingState = [{ id: 'user_email', desc: false }];

const UserPage: React.FC = () => {
  const { isFilterOpen, isPopupOpen, handleClosePopup, handleOpenPopup, handleToggleFilter } = usePageBaseState();

  const {
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
    debouncedServerFriendlyFilters,
    sortingQuery,
  } = useTableState<UserFilter>({
    transformFilters,
    defaultSortingState,
  });

  const { data: tenant, isLoading: isLoadingTenant } = useCurrentTenant();
  const { data, isLoading } = useUsers(debouncedServerFriendlyFilters, sortingQuery, pagination);
  const definedData = useLastDefined(data);

  const [showLimitPopup, setShowLimitPopup] = useState(false);

  const shouldShowLimitExceededPopup = !tenant || isLoadingTenant || tenant?.currentUserCount >= tenant?.maxUserCount;

  return (
    <>
      <PageContentHeader
        title={<FormattedMessage id='app.users_page.title' defaultMessage='Seznam uživatelů' />}
        mobilePrimaryButtonType='new'
        onSecondaryButtonClick={handleToggleFilter}
        onPrimaryButtonClick={shouldShowLimitExceededPopup ? () => setShowLimitPopup(true) : handleOpenPopup}
        primaryButtonText={<FormattedMessage id='app.users_page.new_user_button' defaultMessage='Nový uzivatel' />}
      />
      <UsersTable
        className='mt-16'
        isLoading={isLoading || isLoadingTenant}
        data={definedData?.items ?? []}
        isFilterOpen={isFilterOpen}
        columnFilters={columnFilters}
        sorting={sorting}
        pagination={pagination}
        handleFilter={setColumnFilters}
        handleSort={setSorting}
        handlePaginationChange={setPagination}
        paginationMeta={definedData?.meta}
      />
      <Outlet />
      <Popup title={<FormattedMessage id='app.users_page.popup_title' />} onClose={handleClosePopup} open={isPopupOpen}>
        <AddUserForm />
      </Popup>
      <UserLimitExceededPopup open={showLimitPopup} onClose={() => setShowLimitPopup(false)} />
    </>
  );
};

export default UserPage;
