import { XMarkIcon } from '@heroicons/react/24/outline';
import { pick } from 'ramda';
import { useCallback } from 'react';
import { useMemo } from 'react';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserPreferredColumResponse } from 'src/api';
import useUpdatePreferredColumns from 'src/api/hooks/mutations/userProfile/useUpdatePreferredColumns';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import Button from 'src/components/Button';
import AutoPopup from 'src/components/common/AutoPopup';
import Loader from 'src/components/utils/Loader';
import { ItemWithId, reorderById } from 'src/helpers/reorder';
import useClosePopup from 'src/hooks/popups/useClosePopup';
import useEvent from 'src/hooks/useEvent';
import { ColumnBase, ExtendedColumnDef } from 'src/types/table';
import { ORDERS_TABLE_COLUMNS } from '../ServiceTable';
import OrderColumnConfigurationRow from './components/OrderColumnConfigurationRow';

export type OrderColumnsConfigurationPopupProps = {
  //
};

export const ORDER_COLUMNS_CONFIGURATION_DIALOG_ID = 'OrderColumnsConfigurationPopupId';

const OrderColumnsConfigurationPopup: FC<OrderColumnsConfigurationPopupProps> = () => {
  const { data: currentUser, isLoading } = useCurrentUser();
  const { data: currentTenantConfig, isLoading: isLoadingTenant } = useCurrentTenantConfig();
  const baseColumns =
    currentUser?.profile?.preferredColumns ??
    currentTenantConfig?.orderTable?.defaultColumns?.map((i) => ({ id: i, visible: true })) ??
    [];
  const updatePreferredColumns = useUpdatePreferredColumns();
  const closePopup = useClosePopup(ORDER_COLUMNS_CONFIGURATION_DIALOG_ID);
  const defaultColsValue = useMemo(
    () =>
      [
        ...baseColumns,
        ...ORDERS_TABLE_COLUMNS.filter((c) => !baseColumns.find((i) => i.id === c.id)).map((c) => ({
          id: c.id as string,
          visible: false,
        })),
      ]
        .map((columnData) => ({
          ...columnData,
          column: ORDERS_TABLE_COLUMNS.find((i) => i.id === columnData.id),
        }))
        .filter((i) => i.column) as Array<UserPreferredColumResponse & { column: ExtendedColumnDef<ColumnBase> }>,
    [],
  );
  const [tmpCols, setTmpCols] =
    useState<Array<UserPreferredColumResponse & { column: ExtendedColumnDef<ColumnBase> }>>(defaultColsValue);

  const handleClose = useCallback(() => {
    closePopup();
    setTmpCols(defaultColsValue);
  }, []);

  const handleToggleColumnVisibility = useCallback(
    (id: string) => setTmpCols((prev) => prev.map((c) => (c.id === id ? { ...c, visible: !c.visible } : c))),
    [],
  );
  const handleColumnDrop = useCallback(
    (fromId: string, toId: string) => setTmpCols((prev) => reorderById(fromId, toId, prev as ItemWithId[])),
    [],
  );
  const handleSave = useEvent(() => {
    updatePreferredColumns.mutate(tmpCols.map(pick(['id', 'visible'])));
    handleClose();
  });

  if (isLoading || isLoadingTenant) return <Loader />;

  return (
    <AutoPopup
      id={ORDER_COLUMNS_CONFIGURATION_DIALOG_ID}
      title={<FormattedMessage id='app.columns_configuration_popup.title' />}
      extraOnClose={handleClose}
    >
      {tmpCols.map(({ id, visible, column }) => (
        <OrderColumnConfigurationRow
          key={column.id}
          id={id}
          visible={visible}
          column={column}
          onDrop={handleColumnDrop}
          onClick={() => handleToggleColumnVisibility(column.id as string)}
        />
      ))}
      <div className='mt-[2.875rem] flex justify-end gap-x-6 sm:mt-4'>
        <Button size='normal' type='button' variant='secondary' onClick={handleClose}>
          <XMarkIcon className='h-8 w-8 mr-1 text-gray' aria-hidden='true' />
          <FormattedMessage id='app.buttons.close' defaultMessage='Zavřít' />
        </Button>
        <Button size='normal' type='submit' onClick={handleSave}>
          <FormattedMessage id='app.buttons.save' defaultMessage='Uložit' />
        </Button>
      </div>
    </AutoPopup>
  );
};

OrderColumnsConfigurationPopup.displayName = 'OrderColumnsConfigurationPopup';

export default OrderColumnsConfigurationPopup;
