import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { endOfWeek, format, isEqual, startOfMonth, startOfWeek } from 'date-fns';
import { not } from 'ramda';
import { FC } from 'react';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { UseCalendarReturn } from 'src/components/Calendar/hook';
import { TechnicianCalendarTab } from '../../TechnicianCalendar';

export type TechnicianCalendarHeaderProps = {
  hideButtons?: boolean;
  tab: TechnicianCalendarTab;
  calendar: UseCalendarReturn;
  className?: string;
};

const getNextHandler = (
  tab: TechnicianCalendarTab,
  calendar: UseCalendarReturn,
): ((updateSelectedDate?: boolean) => void) => {
  switch (tab) {
    case 'month':
      return calendar.onNextMonth;
    case 'week':
      return calendar.onNextWeek;
    case 'day':
      return calendar.onNextDay;
  }
};

const getPrevHandler = (
  tab: TechnicianCalendarTab,
  calendar: UseCalendarReturn,
): ((updateSelectedDate?: boolean) => void) => {
  switch (tab) {
    case 'month':
      return calendar.onPrevMonth;
    case 'week':
      return calendar.onPrevWeek;
    case 'day':
      return calendar.onPrevDay;
  }
};

const TechnicianCalendarHeader: FC<TechnicianCalendarHeaderProps> = ({ tab, hideButtons, calendar, className }) => {
  const { data: locale } = useUserFnsLocale();
  const { focusedDate, selectedDate } = calendar;

  const handleNext = getNextHandler(tab, calendar);
  const handlePrev = getPrevHandler(tab, calendar);

  const isWeekIn2Month = not(
    isEqual(
      startOfMonth(startOfWeek(focusedDate, { weekStartsOn: 1 })),
      startOfMonth(endOfWeek(focusedDate, { weekStartsOn: 1 })),
    ),
  );

  return (
    <div className={clsx(className, 'w-full  overflow-hidden border border-b-0 border-gray-300')}>
      <div className={clsx('relative z-50 flex items-center text-gray-900 bg-gray-70 px-6 h-16')}>
        {tab === 'month' && (
          <div className='flex-auto text-md font-semibold'>
            <div className='flex flex-col'>{format(focusedDate, 'LLLL yyyy', { locale })}</div>
          </div>
        )}
        {tab === 'week' && (
          <div className='flex-auto text-md font-semibold'>
            {isWeekIn2Month
              ? `${format(startOfWeek(focusedDate, { weekStartsOn: 1 }), 'LLLL')} - ${format(
                  endOfWeek(focusedDate, { weekStartsOn: 1 }),
                  'LLLL yyyy',
                )}`
              : format(focusedDate, 'LLLL yyyy')}
          </div>
        )}
        {tab === 'day' && (
          <div className='flex-auto text-md font-semibold'>
            <div className='flex flex-col'>
              <span>{format(selectedDate, 'MMM d, yyyy')}</span>
              <span className='text-gray-500 text-sm font-medium'>{format(selectedDate, 'EEEE')}</span>
            </div>
          </div>
        )}
        {!hideButtons && (
          <div className='flex bg-white border border-gray-300 rounded-md overflow-hidden divide-x'>
            <button
              type='button'
              onClick={handlePrev as () => void}
              className='flex items-center justify-center py-2 pl-5 pr-6 text-gray-600 hover:text-gray-900 focus:relative md:w-9 md:px-2 hover:bg-gray-50'
            >
              <span className='sr-only'>Previous month</span>
              <ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
            </button>
            <button
              type='button'
              onClick={handleNext as () => void}
              className='flex items-center justify-center py-2 pl-6 pr-5 text-gray-600 hover:text-gray-900 focus:relative md:w-9 md:px-2 hover:bg-gray-50'
            >
              <span className='sr-only'>Next month</span>
              <ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

TechnicianCalendarHeader.displayName = 'TechnicianCalendarHeader';

export default TechnicianCalendarHeader;
