import clsx from 'clsx';
import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns';
import { FC, HTMLAttributes } from 'react';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { DateInfo, MonthInfo } from '../../types';

export type RenderDayOptions = {
  dateInfo: DateInfo;
  onClick?: () => void;
};

export type MonthProps = {
  month: MonthInfo;
  onDateClick?: (date: Date) => void;
  focusedDate: Date;
  showWeekDays?: boolean;
  noBorderX?: boolean;
  noBorderBottom?: boolean;
  renderDay: (options: RenderDayOptions) => JSX.Element;
} & HTMLAttributes<HTMLDivElement>;

const Month: FC<MonthProps> = ({
  noBorderX,
  noBorderBottom,
  focusedDate,
  showWeekDays,
  className,
  month,
  onDateClick,
  renderDay,
}) => {
  const { data: locale } = useUserFnsLocale();
  const start = startOfWeek(focusedDate, { locale, weekStartsOn: 1 });
  const end = endOfWeek(focusedDate, { locale, weekStartsOn: 1 });
  const daysInWeek = eachDayOfInterval({ start, end });

  return (
    <div
      className={clsx(
        'flex flex-col items-center w-full border-gray-300',
        !noBorderBottom && 'border-b',
        !noBorderX && 'border-x',
      )}
    >
      {showWeekDays && (
        <div className='w-full grid grid-cols-7 gap-px text-xs leading-6 text-gray-500 bg-gray-200 border-b border-gray-300 '>
          {daysInWeek.map((day) => (
            <div key={day.getTime()} className={clsx('border-t border-gray-200')}>
              <span
                className={clsx(
                  'flex justify-center items-center text-gray-900  py-2 font-bold text-md w-full h-full bg-white',
                )}
              >
                {format(day, 'EEE', { locale })[0]}
              </span>
            </div>
          ))}
        </div>
      )}

      <div className='w-full flex items-center justify-center'>
        <div
          className={clsx(
            className,
            'isolate w-full grid grid-cols-7 justify-center items-center gap-px bg-gray-200 text-sm',
          )}
        >
          {month.datesInfo.map((dateInfo) => renderDay({ dateInfo, onClick: () => onDateClick?.(dateInfo.date) }))}
        </div>
      </div>
    </div>
  );
};

Month.displayName = 'Month';

export default Month;
