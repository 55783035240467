import { isSameDay, parseISO } from 'date-fns';
import { flatten } from 'ramda';
import { useMemo } from 'react';
import { GridEvent } from 'src/components/EventsGrid/types';
import orderListItemToGridEvent from 'src/helpers/orderListItemToGridEvents';
import useTechnicianMonthOrders from '../queries/useTechnicianMonthOrders';

const useTechnicianGridEventsFromDay = (technicianId: number, date: Date): GridEvent[] => {
  const { data: orders } = useTechnicianMonthOrders(technicianId, date.getMonth(), date.getFullYear());

  return useMemo(
    () =>
      flatten(
        (orders ?? [])
          .filter((order) => order.datetime && isSameDay(parseISO(order.datetime), date))
          .map(orderListItemToGridEvent),
      ).filter((event) => event.technicianId === technicianId),
    [orders, date],
  );
};

export default useTechnicianGridEventsFromDay;
