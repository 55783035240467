import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { Outlet } from 'react-router-dom';

export type SingleTechnicianTabProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const SingleTechnicianTab: FC<SingleTechnicianTabProps> = ({ className }) => {
  return (
    <div className={clsx(className, 'mt-8')}>
      <Outlet />
    </div>
  );
};

SingleTechnicianTab.displayName = 'SingleTechnicianTab';

export default SingleTechnicianTab;
