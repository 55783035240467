import { FC, useCallback } from 'react';
import ReactPhoneInput, { CountryData, PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import useLanguage from 'src/hooks/useLanguage';
import useCountriesLocalization from 'src/api/hooks/queries/useCountriesLocalization';
import clsx from 'clsx';
import HelperText from '../../common/HelperText';
import withController from 'src/hoc/withController';
import { FieldProps } from 'src/types/forms';

export type PhoneInputLocalizedProps = Omit<PhoneInputProps, 'localization'> & FieldProps<string | null>;

const defaultProps: Partial<PhoneInputLocalizedProps> = {
  preferredCountries: ['cz', 'sk'],
  country: 'cz',
};

const withPrefix = (value: string, prefix = '+'): string => {
  if (!value.startsWith(prefix)) return `${prefix}${value}`;
  return value;
};

const getClassnamesProps = (disabled?: boolean, error?: boolean): Partial<PhoneInputLocalizedProps> => ({
  inputClass: clsx('!w-full !h-10', disabled && 'cursor-not-allowed !bg-gray-200', error && '!border-error'),
  buttonClass: clsx(disabled && 'cursor-not-allowed !bg-gray-200', error && '!border-error'),
});

export type ChangeHandler = (
  value: string | null,
  data: CountryData,
  event: React.ChangeEvent<HTMLInputElement>,
  formattedValue: string,
) => void;

const PhoneInputLocalized: FC<PhoneInputLocalizedProps> = ({
  name,
  inputProps,
  disabled,
  error,
  onChange,
  ...rest
}) => {
  const [lang] = useLanguage();
  const { data: localization } = useCountriesLocalization(lang);
  const handleChange = useCallback<ChangeHandler>(
    (v: string | null, ...args) => {
      onChange?.(withPrefix(v ?? ''), ...args);
    },
    [onChange],
  );

  const { inputClass, buttonClass } = getClassnamesProps(disabled, !!error);

  const normalizedValue = rest.value || '+420';

  return (
    <div className='flex flex-col w-full'>
      <ReactPhoneInput
        placeholder=''
        copyNumbersOnly={false}
        {...rest}
        countryCodeEditable={false}
        value={normalizedValue}
        inputProps={{ ...inputProps, name }}
        inputClass={clsx(rest.inputClass, inputClass)}
        buttonClass={clsx(rest.dropdownClass, buttonClass)}
        disabled={disabled}
        onChange={handleChange}
        {...(localization && { localization })}
      />
      {error && typeof error === 'string' && <HelperText variant='error'>{error}</HelperText>}
    </div>
  );
};

PhoneInputLocalized.defaultProps = defaultProps;
PhoneInputLocalized.displayName = 'PhoneInputLocalized';

export const PhoneInputLocalizedRhfc = withController(PhoneInputLocalized);

export default PhoneInputLocalized;
