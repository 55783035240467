import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { OrderSchema } from '../schema';
import { FormattedMessage } from 'react-intl';
import FormDataRow from 'src/components/FormDataRow';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import { PhoneInputLocalizedRhfc } from 'src/components/fields/PhoneInputLocalized';

const ServiceContactInfoForm: FC = () => {
  const { control } = useFormContext<OrderSchema>();

  return (
    <div className='flex flex-col mb-16'>
      <div className='font-bold text-gray-800 text-2xl mb-8'>
        <FormattedMessage id='app.order.section_title.contact_info' />
      </div>
      <FormDataRow label={<FormattedMessage id='app.order.contact_name' />}>
        <TextFieldRhfc control={control} name='contactName' />
      </FormDataRow>
      <FormDataRow label={<FormattedMessage id='app.order.contact_email' />}>
        <TextFieldRhfc control={control} name='contactEmail' />
      </FormDataRow>
      <FormDataRow label={<FormattedMessage id='app.order.contact_phone' />}>
        <PhoneInputLocalizedRhfc control={control} name='contactPhone' />
      </FormDataRow>
    </div>
  );
};

export default ServiceContactInfoForm;
