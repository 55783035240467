import { z } from 'zod';

export const HeadquartersSchema = z.object({
  companyName: z.string().min(1),
  companyStreet: z.string().min(1),
  companyCity: z.string().min(1),
  companyZIP: z.string().min(1),
  companyVatNumber: z.string().nullable().default(null),
  companyIn: z.string().min(1),
  serviceDurationRate: z.number(),
  serviceMileageRate: z.number(),
  serviceTimeOnRoadRate: z.number(),
  defaultVatTariffId: z.number(),
  accountingEmail: z.string().nullable().default(null),
  stampFile: z.any().nullable().default(null),
});

export type HeadquartersSchema = z.infer<typeof HeadquartersSchema>;
