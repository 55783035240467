import clsx from 'clsx';
import React from 'react';
import HelperText from 'src/components/common/HelperText';
import { FieldProps } from 'src/types/commonTypes';

export type DescriptionFieldProps = FieldProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement,
  string | number | null
> & {
  hideErrorMessage?: boolean;
  error?: string;
};

const DescriptionField: React.ForwardRefRenderFunction<HTMLTextAreaElement, DescriptionFieldProps> = (props, ref) => {
  const { value, hideErrorMessage, error, ...rest } = props;
  return (
    <div className='flex flex-col w-full'>
      <textarea
        className={clsx(
          'w-full h-20 rounded-md border font-openSans font-normal text-base px-2',
          error ? 'border-error' : 'border-gray-300',
        )}
        value={value ?? ''}
        {...rest}
        ref={ref}
      ></textarea>
      {!hideErrorMessage && error && <HelperText variant='error'>{error}</HelperText>}
    </div>
  );
};

export default React.forwardRef(DescriptionField);
