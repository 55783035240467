import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate } from 'react-router-dom';
import useAllProductTemplates from 'src/api/hooks/queries/useAllProductTemplates';
import PageContentHeader from 'src/components/PageContentHeader';
import Loader from 'src/components/utils/Loader';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import stub from 'src/utils/stub';
import AddProductTemplatePopup, { ADD_PRODUCT_TEMPLATE_POPUP_ID } from './AddProductTemplatePopup';
import ProductTemplatesTable from './ProductTemplatesTable';

export type DevicesPageProps = {
  //
};

const DevicesPage: FC<DevicesPageProps> = () => {
  const { data: templates, isLoading } = useAllProductTemplates();
  const openPopup = useOpenPopup(ADD_PRODUCT_TEMPLATE_POPUP_ID);

  if (isLoading) return <Loader />;
  if (!templates) return <Navigate to='/500' />;

  return (
    <>
      <PageContentHeader
        title={<FormattedMessage id='app.devices.page_title' />}
        primaryButtonText={<FormattedMessage id='app.devices.new_device_button' />}
        onPrimaryButtonClick={openPopup}
        mobilePrimaryButtonType='new'
        hideSecondaryButton
      />
      <ProductTemplatesTable
        data={templates ?? []}
        sorting={[]}
        columnFilters={[]}
        pagination={DEFAULT_TABLE_PAGINATION}
        handleFilter={stub}
        handleSort={stub}
        handlePaginationChange={stub}
      />
      <AddProductTemplatePopup />
    </>
  );
};

DevicesPage.displayName = 'DevicesPage';

export default DevicesPage;
