import { PlusCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useAllHeadquaters from 'src/api/hooks/queries/useAllHeadquarters';
import useAllVatTariffs from 'src/api/hooks/queries/useAllVatTariffs';
import Button from 'src/components/Button';
import PageContentHeader from 'src/components/PageContentHeader';
import Title from 'src/components/Title';
import Loader from 'src/components/utils/Loader';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import AddHeadquatersPopup, { ADD_HEADQUATERS_POPUP_ID } from './components/AddHeadquatersPopup';
import AddVatTariffPopup, { ADD_VAT_TARIFF_POPUP_ID } from './components/AddVatTariffPopup';
import HeadquatersTable from './components/HeadquatersTable';
import VatTariffsTable from './components/VatTariffsTable';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import { UserResponse } from 'src/api';

const ParametersPage: React.FC = () => {
  const { data: headquaters, isFetching: isFetchingHeadquaters } = useAllHeadquaters();
  const { data: vatTariffs, isFetching: isFetchingVatTariffs } = useAllVatTariffs({ withDeleted: true });
  const { data: tenantConfig, isFetching: isFetchingTenantConfig } = useCurrentTenantConfig();
  const { data: currentUser, isFetching: isFetchingCurrentUser } = useCurrentUser();

  const openAddVatTariffPopup = useOpenPopup(ADD_VAT_TARIFF_POPUP_ID);
  const openAddHeadquatersPopup = useOpenPopup(ADD_HEADQUATERS_POPUP_ID);

  if (isFetchingHeadquaters || isFetchingVatTariffs || isFetchingTenantConfig || isFetchingCurrentUser)
    return <Loader />;

  const shouldShowVat = !tenantConfig?.disableVat || currentUser?.role === UserResponse.role.SYSTEM_ADMINISTRATOR;

  return (
    <>
      <PageContentHeader
        title={<FormattedMessage id='app.parameter_page.title' />}
        hidePrimaryButton
        hideSecondaryButton
      />
      <span>
        <FormattedMessage id='app.parameters_page.description' />
      </span>
      <Title className='mt-16 mb-8 flex items-center text-2xl'>
        <FormattedMessage id='app.parameter_page.headquaters' />
        <Button variant='cta' size='small' onClick={openAddHeadquatersPopup}>
          <PlusCircleIcon className='h-6 ml-1' />
        </Button>
      </Title>
      <HeadquatersTable data={headquaters ?? []} noMinHeight overrideHeadingFontClassname='text-xl' />
      {shouldShowVat && (
        <>
          <Title className='mt-16 mb-8 flex items-center text-2xl'>
            <FormattedMessage id='app.vat_tariffs.page_title' />
            <Button variant='cta' size='small' onClick={openAddVatTariffPopup}>
              <PlusCircleIcon className='h-6 ml-1' />
            </Button>
          </Title>
          <VatTariffsTable data={vatTariffs ?? []} noMinHeight overrideHeadingFontClassname='text-xl' />
        </>
      )}

      <AddVatTariffPopup />
      <AddHeadquatersPopup />
    </>
  );
};

export default ParametersPage;
