import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { format } from 'date-fns';
import { FC } from 'react';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';

export type MiniCalendarHeaderProps = {
  className?: string;
  focusedDate: Date;
  onNext: () => void;
  onPrev: () => void;
};

const MiniCalendarHeader: FC<MiniCalendarHeaderProps> = ({ className, focusedDate, onNext, onPrev }) => {
  const { data: locale } = useUserFnsLocale();

  return (
    <div className={clsx(className, 'w-full flex justify-between items-center')}>
      <button
        type='button'
        onClick={onPrev}
        className='flex items-center justify-center py-2 pl-5 pr-6 text-gray-600 hover:text-gray-900 focus:relative md:w-9 md:px-2 hover:bg-gray-50'
      >
        <ChevronLeftIcon className='h-5 w-5' />
      </button>
      <span className='font-bold'>{format(focusedDate, 'MMMM', { locale })}</span>
      <button
        type='button'
        onClick={onNext}
        className='flex items-center justify-center py-2 pl-5 pr-6 text-gray-600 hover:text-gray-900 focus:relative md:w-9 md:px-2 hover:bg-gray-50'
      >
        <ChevronRightIcon className='h-5 w-5' />
      </button>
    </div>
  );
};

MiniCalendarHeader.displayName = 'MiniCalendarHeader';

export default MiniCalendarHeader;
