/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContactAresDataResponse } from '../models/ContactAresDataResponse';
import type { ContactListResponse } from '../models/ContactListResponse';
import type { ContactResponse } from '../models/ContactResponse';
import type { ContactServiceListsResponse } from '../models/ContactServiceListsResponse';
import type { ContactsPageResponse } from '../models/ContactsPageResponse';
import type { CreateContactRequest } from '../models/CreateContactRequest';
import type { UpdateContactRequest } from '../models/UpdateContactRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ContactService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns ContactResponse
   * @throws ApiError
   */
  public createContact({ requestBody }: { requestBody: CreateContactRequest }): CancelablePromise<ContactResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/contact',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ContactsPageResponse
   * @throws ApiError
   */
  public getContactList({
    filterString,
    pageSize,
    pageIndex,
    category,
    withDeleted,
    direction,
    orderBy,
  }: {
    filterString: string;
    pageSize: number;
    pageIndex: number;
    category?: any;
    /**
     * With deleted
     */
    withDeleted?: boolean;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<ContactsPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact',
      query: {
        filterString: filterString,
        category: category,
        withDeleted: withDeleted,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public createContactList({ requestBody }: { requestBody: Array<CreateContactRequest> }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/contact/bulk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ContactListResponse
   * @throws ApiError
   */
  public getContactsSearchHistory(): CancelablePromise<Array<ContactListResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/searchHistory',
    });
  }
  /**
   * @returns ContactResponse
   * @throws ApiError
   */
  public getContact({ id }: { id: number }): CancelablePromise<ContactResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns ContactResponse
   * @throws ApiError
   */
  public updateContact({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateContactRequest;
  }): CancelablePromise<ContactResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/contact/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns ContactResponse
   * @throws ApiError
   */
  public deleteContact({ id }: { id: number }): CancelablePromise<ContactResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/contact/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns ContactResponse
   * @throws ApiError
   */
  public restoreContact({ id }: { id: number }): CancelablePromise<ContactResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/contact/{id}/restore',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns ContactServiceListsResponse
   * @throws ApiError
   */
  public getContactServiceLists({
    contactId,
    datetime,
    branchName,
    employeeName,
    productName,
    withDeleted,
  }: {
    contactId: number;
    /**
     * Order datetime
     */
    datetime?: string;
    /**
     * Branch name
     */
    branchName?: string;
    /**
     * Employee name
     */
    employeeName?: string;
    /**
     * Product name
     */
    productName?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
  }): CancelablePromise<ContactServiceListsResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/{contactId}/service-lists',
      path: {
        contactId: contactId,
      },
      query: {
        datetime: datetime,
        branchName: branchName,
        employeeName: employeeName,
        productName: productName,
        withDeleted: withDeleted,
      },
    });
  }
  /**
   * @returns ContactAresDataResponse
   * @throws ApiError
   */
  public getContractAresData({ ico }: { ico: string }): CancelablePromise<ContactAresDataResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/contact/ares/{ico}',
      path: {
        ico: ico,
      },
    });
  }
}
