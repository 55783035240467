import { curry, findIndex, move } from 'ramda';
import { idEquals } from 'src/components/EventsGrid/helpers/state';

export type ItemWithId = {
  id: string;
};

const _calculateNextIndex = (movedIndex: number, targetIndex: number): number => {
  if (targetIndex === 0) return 0;
  if (targetIndex < movedIndex) return targetIndex + 1;
  return targetIndex;
};

export const findIndexById = <T extends ItemWithId>(id: string, list: T[]): number => findIndex(idEquals(id), list);

export const reorderById = curry(<T extends ItemWithId>(fromId: string, toId: string, list: T[]) => {
  const fromIndex = findIndexById(fromId, list);
  const toIndex = findIndexById(toId, list);
  const nextIndex = _calculateNextIndex(fromIndex, toIndex);

  if (fromIndex < 0 || toIndex < 0) return list;

  return move(fromIndex, nextIndex, list);
});
