/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BranchResponse } from './BranchResponse';
import type { ContactResponse } from './ContactResponse';
import type { DeliveryTariffResponse } from './DeliveryTariffResponse';
import type { EmployeeResponse } from './EmployeeResponse';
import type { HeadquartersResponse } from './HeadquartersResponse';
import type { OrderStatusEmbeddedResponse } from './OrderStatusEmbeddedResponse';
import type { OrderTypeRepsonse } from './OrderTypeRepsonse';
import type { UserResponse } from './UserResponse';
import type { VatTariffResponse } from './VatTariffResponse';
import type { WorkTariffResponse } from './WorkTariffResponse';
export type OrderListItemResponse = {
  id: number;
  category: OrderListItemResponse.category;
  technicians: Array<UserResponse>;
  datetime?: string;
  endDatetime?: string;
  status: OrderStatusEmbeddedResponse | null;
  serviceInvoice: string | null;
  companyName: string;
  companyAddress: string;
  companyId: number;
  realStart: string | null;
  realEnd: string | null;
  orderNumber: string;
  productName: string | null;
  headquarters: HeadquartersResponse | null;
  vatTariff: VatTariffResponse | null;
  deliveryTariff: DeliveryTariffResponse | null;
  workTariff: WorkTariffResponse | null;
  company: ContactResponse | null;
  branch: BranchResponse | null;
  employee: EmployeeResponse | null;
  serviceTotalPrice: number | null;
  materialPrice: number | null;
  defectDescription: string | null;
  createdAt: string;
  orderType: OrderTypeRepsonse | null;
  orderTypeId: number | null;
};
export namespace OrderListItemResponse {
  export enum category {
    B2B = 'B2B',
    B2C = 'B2C',
  }
}
