import { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useApiClient } from 'src/api';
import useContactEmployees from 'src/api/hooks/queries/useContactEmployees';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';
import { PhoneInputLocalizedRhfc } from 'src/components/fields/PhoneInputLocalized';
import { SelectRhfc } from 'src/components/fields/Select';
import { TextFieldRhfc } from 'src/components/fields/Textfield/TextField';
import FormDataRow from 'src/components/FormDataRow';
import { EMPLOYEE_FIELDS } from '../constants';
import { OrderSchema } from '../schema';

const ServiceEmployeeForm: FC<{ disableCreateNew?: boolean }> = ({ disableCreateNew }) => {
  const apiClient = useApiClient();
  const { control, watch, setValue } = useFormContext<OrderSchema>();
  const contactId = watch('company.companyId');
  const createNewCompany = watch('company.createNewCompany');
  const createNewEmployee = watch('employee.createNewEmployee');

  const { data: employees } = useContactEmployees(contactId ?? 0, { enabled: !!contactId });

  const handleEmployeeChange = useCallback(
    async (employeeId: number | null): Promise<void> => {
      if (!contactId || !employeeId) return;

      const data = await apiClient.employee.getEmployee({ contactId, id: employeeId });
      EMPLOYEE_FIELDS.forEach((field) => setValue(`employee.employee.${field}`, data[field]));
    },
    [contactId, setValue],
  );

  const handleCreateNewEmployeeChange = useCallback(
    (value: boolean | null) => {
      if (!value) return;

      setValue('employee.employeeId', null);
      EMPLOYEE_FIELDS.forEach((field) => setValue(`employee.employee.${field}`, ''));
    },
    [setValue],
  );

  return (
    <div className='flex flex-col  mb-16'>
      <div className='font-bold text-gray-800 text-2xl mb-8'>
        <FormattedMessage id='app.order.section_title.employee' />
      </div>

      {!disableCreateNew && (
        <FormDataRow label={<FormattedMessage id='app.order.new_employee' />}>
          <CheckboxRhfc
            control={control}
            name='employee.createNewEmployee'
            disabled={!!createNewCompany}
            extraOnChange={handleCreateNewEmployeeChange}
          />
        </FormDataRow>
      )}

      {!createNewEmployee && (
        <FormDataRow label={<FormattedMessage id='app.order.employee' />}>
          <SelectRhfc
            control={control}
            name='employee.employeeId'
            options={employees ?? []}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            extraOnChange={handleEmployeeChange}
          />
        </FormDataRow>
      )}

      <FormDataRow asterisk={createNewEmployee} label={<FormattedMessage id='app.employee.name' />}>
        <TextFieldRhfc control={control} name='employee.employee.name' disabled={!createNewEmployee} />
      </FormDataRow>

      <FormDataRow label={<FormattedMessage id='app.common.phone' />}>
        <PhoneInputLocalizedRhfc control={control} name='employee.employee.phoneNumber' disabled={!createNewEmployee} />
      </FormDataRow>

      <FormDataRow label={<FormattedMessage id='app.common.email' />}>
        <TextFieldRhfc control={control} name='employee.employee.email' disabled={!createNewEmployee} />
      </FormDataRow>
    </div>
  );
};

export default ServiceEmployeeForm;
