import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useAllProductTemplates from 'src/api/hooks/queries/useAllProductTemplates';
import { SelectRhfc } from 'src/components/fields/Select';
import FormDataRow from 'src/components/FormDataRow';
import { OrderSchema } from '../schema';
import ServiceNewProductForm from './ServiceNewProductForm';

export type ServiceNewProductFormWithTemplatesProps = {
  index: number;
};

const ServiceNewProductFormWithTemplates: FC<ServiceNewProductFormWithTemplatesProps> = ({ index }) => {
  const { data: templates } = useAllProductTemplates();
  const { control, setValue } = useFormContext<OrderSchema>();

  return (
    <>
      <FormDataRow asterisk label={<FormattedMessage id='app.order.product' />}>
        <SelectRhfc
          options={templates ?? []}
          getOptionLabel={(template) => `${template.productName} (${template.productProducer})`}
          getOptionValue={(tempalte) => tempalte.id}
          extraOnChange={(value) => {
            const template = templates?.find((template) => template.id === value);
            if (!template) return;

            setValue(`orderProducts.${index}.product.productName`, template.productName);
            setValue(`orderProducts.${index}.product.productProducer`, template.productName);
          }}
          control={control}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          name={`orderProducts.${index}.product._template` as any}
        />
      </FormDataRow>
      <ServiceNewProductForm index={index} />
    </>
  );
};

export default ServiceNewProductFormWithTemplates;
