import { PencilIcon } from '@heroicons/react/24/solid';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { FC, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { TenantWithLicencesResponse } from 'src/api';
import useUpdateCurrentTenantColorTheme from 'src/api/hooks/mutations/tenant/useUpdateCurrentTenantColorTheme';
import useCurrentTenant from 'src/api/hooks/queries/useCurrentTenant';
import useUploadFile from 'src/api/hooks/useUploadFile';
import Button from 'src/components/Button';
import Logo from 'src/components/common/Logo';
import ParametersRow from 'src/components/common/ParametersRow';
import Select from 'src/components/fields/Select';
import Loader from 'src/components/utils/Loader';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type TenantInfoProps = {
  className?: string;
};

const options = Object.values(TenantWithLicencesResponse.colorTheme);

const TenantInfo: FC<TenantInfoProps> = ({ className }) => {
  const queryClient = useQueryClient();
  const uploadLogo = useUploadFile('tenant/logo');
  const { data, isLoading } = useCurrentTenant();
  const updateThemeMutation = useUpdateCurrentTenantColorTheme();
  const logoInputRef = useRef<HTMLInputElement>(null);
  const handleLogoChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0];
    if (!file) return;

    await uploadLogo(file);
    await queryClient.invalidateQueries(queryKeysFactory.tenant.current);
  };

  if (isLoading) return <Loader />;

  return (
    <div className={clsx('flex flex-col', className)}>
      <ParametersRow.Title>
        <FormattedMessage id='app.settings.tenant_info.title' />
      </ParametersRow.Title>

      <ParametersRow className='h-18'>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.tenant_name' />
        </ParametersRow.Label>
        <ParametersRow.Value>{data?.tenantName}</ParametersRow.Value>
      </ParametersRow>

      <ParametersRow className='h-18'>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.max_user_count' />
        </ParametersRow.Label>
        <span>{data?.maxUserCount}</span>
      </ParametersRow>

      <ParametersRow className='h-18'>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.current_user_count' />
        </ParametersRow.Label>
        <span>{data?.currentUserCount}</span>
      </ParametersRow>

      <ParametersRow className='h-18'>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.color_theme' />
        </ParametersRow.Label>
        <Select
          className='max-w-48'
          name='colorTheme'
          options={options}
          getOptionValue={(v) => v}
          getOptionLabel={(v) => v}
          value={data?.colorTheme}
          onChange={(v) => {
            if (!v) return;

            updateThemeMutation.mutate(v);
          }}
        />
      </ParametersRow>

      <ParametersRow className='h-18'>
        <ParametersRow.Label>
          <FormattedMessage id='app.tenant.logo' />
        </ParametersRow.Label>
        <div className='relative pr-1 py-1'>
          <Logo className='h-16' />
          <Button variant='cta' size='small' onClick={() => logoInputRef.current?.click()}>
            <PencilIcon className='absolute rounded-full bg-primary text-white p-1 h-6 bottom-1 right-0 hover:bg-primaryFocus' />
            <input ref={logoInputRef} type='file' accept='image/*' className='hidden' onChange={handleLogoChange} />
          </Button>
        </div>
      </ParametersRow>
    </div>
  );
};

export default TenantInfo;
