import { FC } from 'react';
import { UserResponse } from 'src/api';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import TechnicianCalendar from 'src/components/TechnicianCalendar';
import Loader from 'src/components/utils/Loader';

export type TechnicianAccountCalendarProps = {
  className?: string;
};

const TechnicianAccountCalendar: FC<TechnicianAccountCalendarProps> = ({ className }) => {
  const { data: currentUser } = useCurrentUser();

  if (!currentUser) return <Loader />;
  return (
    <TechnicianCalendar
      className={className}
      disableDrag={currentUser.role === UserResponse.role.READ_ONLY_TECHNICIAN}
      technicianId={currentUser.id}
    />
  );
};

TechnicianAccountCalendar.displayName = 'TechnicianAccountCalendar';

export default TechnicianAccountCalendar;
