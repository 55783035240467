import clsx from 'clsx';
import { format } from 'date-fns';
import { FC, useMemo } from 'react';
import useEventsGridSettings from 'src/components/EventsGrid/hooks/useEventsGridSettings';
import { SEPARATORS_PER_HOUR_MTD } from '../../constants';
import { splitIntoColBorders } from '../../helpers/gridMtd';

const HoursBarMTD: FC = () => {
  const { START_HOUR, END_HOUR } = useEventsGridSettings();
  const cols = useMemo(
    () => splitIntoColBorders(START_HOUR, END_HOUR, 1 / SEPARATORS_PER_HOUR_MTD),
    [START_HOUR, END_HOUR],
  );

  return (
    <div
      className={clsx('col-start-1 col-end-2 row-start-1 grid-rows-2 divide-x divide-gray-100 grid border-y')}
      style={{
        gridTemplateColumns: `repeat(${cols.length}, minmax(0, 1fr))`,
      }}
    >
      {cols.map((hourCols, i) => (
        <div
          key={i}
          className='py-1 row-span-full bg-white flex flex-col justify-center items-center text-gray-600 text-md'
          style={{ gridColumnStart: i + 1 }}
        >
          <span>{format(new Date(0, 0, 0, hourCols[0]), 'HH:mm')}</span>
          <span>{format(new Date(0, 0, 0, hourCols[1]), 'HH:mm')}</span>
        </div>
      ))}
    </div>
  );
};

export default HoursBarMTD;
