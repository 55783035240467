import { CellContext } from '@tanstack/react-table';
import { FC } from 'react';
import { OrderListItemResponse } from 'src/api';
import TechnicianAvatar from 'src/components/TechnicianAvatar/TechnicianAvatar';

export type TechniciansCellProps = {
  info: CellContext<OrderListItemResponse, unknown>;
};

const TechniciansCell: FC<TechniciansCellProps> = ({ info }) => {
  const names = (info.row.original.technicians?.map((i) => i.profile?.name).filter(Boolean) ?? []) as string[];

  return (
    <div className='flex flex-col gap-2'>
      {names.map((name) => (
        <TechnicianAvatar key={name} name={name} />
      ))}
    </div>
  );
};

export default TechniciansCell;
