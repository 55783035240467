import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';
import AllTechniciansCalendar from '../AllTechniciansCalendar';
// import Tabs from 'src/components/common/Tabs';

export type AllTechniciansTabProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const AllTechniciansTab: FC<AllTechniciansTabProps> = ({ className }) => {
  return (
    <div className={clsx(className, 'flex flex-col')}>
      <AllTechniciansCalendar />
    </div>
  );
};

AllTechniciansTab.displayName = 'AllTechniciansTab';

export default AllTechniciansTab;
