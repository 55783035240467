import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { TenantConfigDto } from 'src/api';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';

const TenantConfigurationContactForm: FC = () => {
  const { control } = useFormContext<TenantConfigDto>();

  return (
    <>
      <span className='text-2xl mb-4'>
        <FormattedMessage id='app.tenants_page.configuration.contact_form' />
      </span>
      <div className='grid grid-cols-2 lg:grid-cols-1 gap-x-20 gap-y-8 border-b broder-gray-300 w-auto '>
        <div className='flex flex-col gap-y-6'>
          <div className='flex w-full gap-3'>
            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <FormattedMessage id='app.tenants_page.configuration.contact_form.show_internal_category' />
                <CheckboxRhfc control={control} name='contactForm.showInternalCategory' />
              </summary>
              <span className='mt-2'>
                <FormattedMessage id='app.tenants_page.configuration.contact_form.show_internal_category.hint' />
              </span>
            </details>
          </div>
          <div className='flex w-full gap-3 mb-12'>
            <details className='flex w-full gap-3'>
              <summary className='flex w-full gap-3'>
                <InformationCircleIcon className='w-6 h-6 text-primary' />
                <FormattedMessage id='app.tenants_page.configuration.contact_form.show_contract_number' />
                <CheckboxRhfc control={control} name='contactForm.showContractNumber' />
              </summary>
              <span className='mt-2'>
                <FormattedMessage id='app.tenants_page.configuration.contact_form.show_contract_number.hint' />
              </span>
            </details>
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantConfigurationContactForm;
