import { TrashIcon } from '@heroicons/react/24/outline';
import { FC, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { MaterialResponse, useApiClient } from 'src/api';
import Button from 'src/components/Button';
import InputWithSuggestionsBe from 'src/components/fields/InputWithSuggestionsBe';
import { NumberFieldRhfc } from 'src/components/fields/NumberField';
import { SelectRhfc } from 'src/components/fields/Select';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import FormDataRow from 'src/components/FormDataRow';
import { isMdOrSmaller } from 'src/helpers/screenSize';
import useUnits from 'src/hooks/useUnits';
import useWindowWidth from 'src/hooks/useWindowWidth';
import { FinishOrderFormValues } from '../FinishOrderForm/schema';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import clsx from 'clsx';

type MaterialProps = {
  onDelete: () => void;
  orderProductIndex: number;
  index: number;
  shouldHidePrice?: boolean;
};

const Material: FC<MaterialProps> = ({ onDelete, shouldHidePrice, orderProductIndex, index }) => {
  const apiClient = useApiClient();
  const windowWidth = useWindowWidth();
  const { data: tenantConfig } = useCurrentTenantConfig();
  const form = useFormContext<FinishOrderFormValues>();
  const { control, setValue, watch } = form;
  const isEurPrice = watch('preferredCurrency') === 'EUR';

  const getMaterialOptions = useCallback(
    async (query: string) => {
      const data = await apiClient.material.searchMaterials({ query, limit: 100 });

      function createOptionLabel(material: MaterialResponse): string {
        let label = material.materialName;

        if (tenantConfig?.materialTable?.informativeMaterialSuggetions && material.supplierCode) {
          label += ` (${material.supplierCode})`;
        }

        return label;
      }

      return data.map((material) => ({ label: createOptionLabel(material), value: material.id }));
    },
    [apiClient, tenantConfig],
  );

  const handleOptionClick = useCallback(
    async (id: number) => {
      const material = await apiClient.material.getMaterial({ id });

      setValue(`products.${orderProductIndex}.materials.${index}.material`, material.materialName);
      setValue(
        `products.${orderProductIndex}.materials.${index}.price`,
        isEurPrice ? material.materialValueEur : material.materialValue,
      );
      setValue(`products.${orderProductIndex}.materials.${index}.code`, material.materialCode);
    },
    [apiClient],
  );

  const units = useUnits();

  if (isMdOrSmaller(windowWidth)) {
    return (
      <div className='flex flex-col gap-y-4'>
        <div className='flex items-center gap-x-4'>
          <span className='font-semibold text-xl'>
            <FormattedMessage id='app.finish_order.material' values={{ value: index + 1 }} />
          </span>
          <Button className='px-0 min-w-fit' variant='cta' onClick={onDelete}>
            <TrashIcon className='h-6' />
          </Button>
        </div>
        <FormDataRow noMb label={<FormattedMessage id='app.material.name' />}>
          <Controller
            control={control}
            name={`products.${orderProductIndex}.materials.${index}.material`}
            render={({ field: { value, ...rest } }) => (
              <InputWithSuggestionsBe
                {...rest}
                value={value ?? ''}
                getOptions={getMaterialOptions}
                onOptionClick={handleOptionClick}
              />
            )}
          />
        </FormDataRow>
        <FormDataRow noMb label={<FormattedMessage id='app.material.code' defaultMessage='code' />}>
          <TextFieldRhfc control={control} name={`products.${orderProductIndex}.materials.${index}.code`} />
        </FormDataRow>
        <FormDataRow noMb label={<FormattedMessage id='app.common.quantity' defaultMessage='quantity' />}>
          <NumberFieldRhfc control={control} name={`products.${orderProductIndex}.materials.${index}.quantity`} />
        </FormDataRow>
        <FormDataRow noMb label={<FormattedMessage id='app.material.unit' defaultMessage='unit' />}>
          <SelectRhfc
            control={control}
            name={`products.${orderProductIndex}.materials.${index}.unit`}
            options={units}
            getOptionValue={(v) => v.label}
            getOptionLabel={(v) => v.label}
          />
        </FormDataRow>
        <FormDataRow noMb label={<FormattedMessage id='app.material.value' defaultMessage='value' />}>
          <NumberFieldRhfc control={control} name={`products.${orderProductIndex}.materials.${index}.price`} />
        </FormDataRow>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        'grid gap-x-6 items-center',
        tenantConfig?.materialTable?.informativeMaterialSuggetions
          ? 'grid-cols-[8fr,2fr,2fr,2fr,2fr,1fr]'
          : 'grid-cols-[5fr,2fr,2fr,2fr,2fr,1fr]',
      )}
    >
      <Controller
        control={control}
        name={`products.${orderProductIndex}.materials.${index}.material`}
        render={({ field: { value, ...rest } }) => (
          <InputWithSuggestionsBe
            {...rest}
            value={value ?? ''}
            getOptions={getMaterialOptions}
            onOptionClick={handleOptionClick}
          />
        )}
      />
      <TextFieldRhfc control={control} name={`products.${orderProductIndex}.materials.${index}.code`} />
      <NumberFieldRhfc control={control} name={`products.${orderProductIndex}.materials.${index}.quantity`} />
      <SelectRhfc
        control={control}
        name={`products.${orderProductIndex}.materials.${index}.unit`}
        options={units}
        getOptionValue={(v) => v.value}
        getOptionLabel={(v) => v.label}
      />
      {!shouldHidePrice && (
        <NumberFieldRhfc control={control} name={`products.${orderProductIndex}.materials.${index}.price`} />
      )}

      <Button className='px-0 min-w-fit' variant='cta' onClick={onDelete}>
        <TrashIcon className='h-6' />
        <FormattedMessage id='app.butons.delete' />
      </Button>
    </div>
  );
};

export default Material;
