/* eslint-disable @typescript-eslint/no-explicit-any */
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import {
  ChangeEventHandler,
  ForwardedRef,
  Fragment,
  ReactNode,
  forwardRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import HelperText from 'src/components/common/HelperText';
import useEvent from 'src/hooks/useEvent';

export type AutocompleteBaseProps<T, TValue extends string | number | null> = {
  disabled?: boolean;
  externalFilter?: boolean;
  hideErrorMessage?: boolean;
  error?: string;
  value: TValue;
  onChange: (value: TValue) => void;
  options: T[];
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => TValue;
  extraOnChange?: (value: TValue, option: T | null) => void;
  className?: string;
  placeholder?: string;
  query: string;
  onQueryChange: (query: string) => void;
  notFoundMessage?: ReactNode;
  hideButton?: boolean;
};

export type AutocompleteProps<T, TValue extends string | number | null> = {
  disabled?: boolean;
  value: TValue;
  onChange: (value: TValue) => void;
  options: T[];
  getOptionLabel: (option: T) => string;
  getOptionValue: (option: T) => TValue;
  className?: string;
  placeholder?: string;
};

const AutocompleteBaseInner = <T, TValue extends string | number | null>(
  {
    className,
    disabled,
    value,
    onChange,
    options,
    getOptionLabel,
    getOptionValue,
    placeholder,
    query,
    onQueryChange,
    notFoundMessage,
    extraOnChange,
    hideButton,
    hideErrorMessage,
    externalFilter,
    error,
  }: AutocompleteBaseProps<T, TValue>,
  ref: ForwardedRef<HTMLInputElement>,
): JSX.Element => {
  const handleAfterLeave = useCallback(() => onQueryChange(''), [onQueryChange]);
  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => onQueryChange(event.target.value),
    [onQueryChange],
  );

  const handleChange = (value: TValue): void => {
    onChange(value);

    const option = options.find((option) => getOptionValue(option) === value) ?? null;
    extraOnChange?.(value, option);
  };

  const displayValue = useEvent((value: TValue) => {
    const option = options.find((option) => getOptionValue(option) === value);
    return option ? getOptionLabel(option) : '';
  });

  const getOptionLabelRef = useRef(getOptionLabel);
  const filteredOptions = useMemo(() => {
    if (externalFilter) return options;
    const filtered = options?.filter((i) => getOptionLabelRef.current(i).toLowerCase().includes(query.toLowerCase()));
    return filtered ?? [];
  }, [options, query, externalFilter]);

  return (
    <div className={clsx(className, 'w-full flex-col w-full')}>
      <Combobox value={value} onChange={handleChange} disabled={disabled}>
        <div className='relative'>
          <div className='relative w-full cursor-default overflow-hidden rounded bg-white text-left border border-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300'>
            <Combobox.Input
              ref={ref}
              className='h-10 w-full border-none py-2 pl-3 pr-10 leading-5 text-gray-900 focus:ring-0'
              displayValue={displayValue}
              onChange={handleInputChange}
              placeholder={placeholder}
            />
            {!hideButton && (
              <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2'>
                <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
              </Combobox.Button>
            )}
          </div>
          <Transition
            as={Fragment}
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
            afterLeave={handleAfterLeave}
          >
            <Combobox.Options className='absolute z-[3000] mt-1 max-h-60 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-gray-1000 ring-opacity-5 focus:outline-none'>
              {filteredOptions.length === 0 && query !== '' ? (
                <div className='relative cursor-default select-none py-2 px-4 text-gray-700'>
                  {notFoundMessage ?? <FormattedMessage id='app.autocomplete.nothing_found' />}
                </div>
              ) : (
                filteredOptions.map((option) => (
                  <Combobox.Option
                    key={getOptionValue(option)}
                    className={({ active }) =>
                      `relative select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-gray-100 cursor-pointer text-gray-900' : 'text-gray-900'
                      }`
                    }
                    value={getOptionValue(option)}
                  >
                    {({ selected }) => (
                      <>
                        <span className={clsx(`block truncate`, selected ? 'font-medium' : 'font-normal')}>
                          {getOptionLabel(option)}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600'
                            }`}
                          >
                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
      {!disabled && !hideErrorMessage && error && typeof error === 'string' && (
        <HelperText variant='error'>{error}</HelperText>
      )}
    </div>
  );
};

export const AutocompleteBase = forwardRef(AutocompleteBaseInner) as <T, TValue extends string | number | null>(
  props: AutocompleteBaseProps<T, TValue> & {
    ref?: ForwardedRef<HTMLInputElement>;
  },
) => JSX.Element;

const AutocompleteInner = <T, TValue extends string | number | null>(
  { options, getOptionLabel, ...rest }: AutocompleteProps<T, TValue>,
  ref?: ForwardedRef<HTMLInputElement>,
): JSX.Element => {
  const [query, setQuery] = useState('');

  return (
    <AutocompleteBase
      {...rest}
      ref={ref}
      getOptionLabel={getOptionLabel}
      options={options}
      query={query}
      onQueryChange={setQuery}
    />
  );
};

const Autocomplete = forwardRef(AutocompleteInner) as typeof AutocompleteInner;

export default Autocomplete;
