import {
  ColumnFiltersState,
  getCoreRowModel,
  PaginationState,
  SortingState,
  Updater,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { PageMetaDto } from 'src/api';
import { ExtendedColumnDef } from 'src/types/table';
import Pagination from '../Pagination';
import Loader from '../utils/Loader';
import TableCell from './components/TableCell';
import TableHeading from './components/TableHeading';
import { useVisibleColumns } from './hooks/useVisibleColumns';

export type TableProps<T extends { id: number }> = {
  data: T[];
  paginationMeta?: PageMetaDto;
  isFilterOpen?: boolean;
  isLoading?: boolean;
  sorting?: SortingState;
  pagination?: PaginationState;
  columns: ExtendedColumnDef<T>[];
  handleFilter?: (filters: Updater<ColumnFiltersState>) => void;
  handleSort?: (sorts: Updater<SortingState>) => void;
  handlePaginationChange?: (pagination: Updater<PaginationState>) => void;
  handleRowClick?: (row: T) => void;
  columnFilters?: ColumnFiltersState;
  className?: string;
  disableSorting?: boolean;
  overrideHeadingFontClassname?: string;
  noMinHeight?: boolean;
};

const Table = <T extends { id: number }>(props: PropsWithChildren<TableProps<T>>): JSX.Element => {
  const {
    data,
    columns,
    className,
    handleFilter,
    handleSort,
    handlePaginationChange,
    handleRowClick,
    isFilterOpen,
    isLoading,
    paginationMeta,
    disableSorting,
    overrideHeadingFontClassname,
    columnFilters,
    sorting,
    pagination,
  } = props;

  const visibleColumns = useVisibleColumns(columns);

  const table = useReactTable({
    data,
    columns: visibleColumns,
    state: {
      columnFilters,
      sorting,
      pagination,
    },
    onPaginationChange: handlePaginationChange,
    onSortingChange: handleSort,
    onColumnFiltersChange: handleFilter,
    getCoreRowModel: getCoreRowModel(),
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
  });

  return (
    <div className={clsx('flex flex-col w-full', className)}>
      <div className={'flex flex-col w-full grow  relative overflow-auto'}>
        <table className='w-full relative whitespace-nowrap'>
          <thead className='sticky top-0'>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHeading
                    key={header.id}
                    header={header}
                    isFilterOpen={isFilterOpen}
                    disableSorting={disableSorting}
                    overrideHeadingFontClassname={overrideHeadingFontClassname}
                  />
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className={clsx(
                  'border-t border-gray-100 min-h-[85px]',
                  'hover:border-t-primary hover:border-b hover:border-b-primary',
                  handleRowClick && 'cursor-pointer',
                )}
              >
                {row.getAllCells().map((cell) => (
                  <TableCell
                    key={cell.id}
                    cell={cell}
                    {...(handleRowClick &&
                      !(cell.column.columnDef as ExtendedColumnDef<T>)?.disableRowClick && {
                        onClick: () => handleRowClick(row.original),
                      })}
                  />
                ))}
              </tr>
            ))}
          </tbody>
          {isLoading && <Loader />}
        </table>
      </div>
      {paginationMeta && paginationMeta.itemsPerPage < (paginationMeta.totalItems ?? 0) && (
        <Pagination onPageChange={table.setPageIndex} {...paginationMeta} />
      )}
    </div>
  );
};

export default Table;
