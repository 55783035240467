// VIEW 2 only
export const ROWS_PER_HOUR = 12;
export const MINUTES_PER_ROW = 60 / ROWS_PER_HOUR;

export const SEPARATORS_PER_HOUR = 2;

export const ITEM_TYPES = {
  EVENT: Symbol('event'),
};

export const COLS_PER_HOUR_MTD = 2;
export const MINUTES_PER_COL_MTD = 60 / COLS_PER_HOUR_MTD;
export const SEPARATORS_PER_HOUR_MTD = 1 / 2;
