import { FC, useState } from 'react';
import clsx from 'clsx';
import SearchBlock from 'src/components/SearchBlock';
import useEvent from 'src/hooks/useEvent';
import useTableState from 'src/components/Table/hooks/useTableState';
import BranchesTable from './BranchesTable';
import useBranchesFromSearch from 'src/api/hooks/queries/useBranchesFromSearch';
import { FormattedMessage } from 'react-intl';
import useBranches from 'src/api/hooks/queries/useBranches';
import { BranchFilterQuery } from 'src/api';
import PageContentHeader from 'src/components/PageContentHeader';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';

export type BranchesPageProps = {
  className?: string;
};

const BranchesPage: FC<BranchesPageProps> = ({ className }) => {
  const { data: tenantConfig } = useCurrentTenantConfig();
  const [searchValue, setSearchValue] = useState('');
  const [queryString, setQueryString] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const handleSearch = useEvent(() => setQueryString(searchValue));
  const {
    columnFilters,
    debouncedServerFriendlyFilters,
    sortingQuery,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
  } = useTableState<BranchFilterQuery>();

  const shouldUseFilters = tenantConfig?.enableTableSearchForBranches ?? false;

  const { data: searchData } = useBranchesFromSearch(queryString, pagination, sortingQuery, {
    enabled: !shouldUseFilters,
  });
  const { data: filterData } = useBranches(debouncedServerFriendlyFilters, sortingQuery, pagination, {
    enabled: shouldUseFilters,
  });

  const data = shouldUseFilters ? filterData : searchData;

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <PageContentHeader
        title={<FormattedMessage id='app.branches_page.title' />}
        onSecondaryButtonClick={() => setIsFilterOpen((p) => !p)}
        hidePrimaryButton={true}
        hideSecondaryButton={!shouldUseFilters}
      />

      {!shouldUseFilters && (
        <SearchBlock
          className='mb-16 mt-4'
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={handleSearch}
          description={<FormattedMessage id='app.branches_page.search.helper_text' />}
        />
      )}
      <BranchesTable
        className={clsx(shouldUseFilters && 'mt-16')}
        isFilterOpen={isFilterOpen}
        columnFilters={columnFilters}
        handleFilter={setColumnFilters}
        pagination={pagination}
        handlePaginationChange={setPagination}
        sorting={sorting}
        handleSort={setSorting}
        data={data?.items ?? []}
        paginationMeta={data?.meta}
      />
    </div>
  );
};

BranchesPage.displayName = 'BranchesPage';

export default BranchesPage;
