import { FC } from 'react';
import clsx from 'clsx';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { eachDayOfInterval, endOfWeek, format, getDate, isEqual, startOfDay, startOfWeek } from 'date-fns';

export type WeekDaysHeaderProps = {
  focusedDate: Date;
  selectedDate: Date;
  className?: string;
  onDateClick?: (date: Date) => void;
};

const WeekDaysHeader: FC<WeekDaysHeaderProps> = ({ className, focusedDate, selectedDate, onDateClick }) => {
  const { data: locale } = useUserFnsLocale();

  const start = startOfWeek(focusedDate, { locale, weekStartsOn: 1 });
  const end = endOfWeek(focusedDate, { locale, weekStartsOn: 1 });
  const daysInWeek = eachDayOfInterval({ start, end });

  return (
    <div
      className={clsx(className, 'sticky top-0 z-50 bg-gray-50 w-full grid grid-cols-7 border-b border-gray-200 py-1')}
    >
      {daysInWeek.map((day) => (
        <div
          key={day.getTime()}
          className={clsx('flex flex-col items-center gap-y-1', !!onDateClick && 'cursor-pointer')}
          onClick={() => onDateClick?.(day)}
        >
          <span className={clsx('flex justify-center items-center text-xs text-gray-500')}>
            {format(day, 'EEE', { locale })[0]}
          </span>
          <span
            className={clsx(
              'flex justify-center items-center text-xs font-bold  p-1 ',
              isEqual(startOfDay(selectedDate), startOfDay(day)) && ' w-6 h-6 text-white bg-primary rounded-full',
            )}
          >
            {getDate(day)}
          </span>
        </div>
      ))}
    </div>
  );
};

WeekDaysHeader.displayName = 'WeekDaysHeader';

export default WeekDaysHeader;
