import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useCallback } from 'react';
import { FC, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isLgOrBigger } from 'src/helpers/screenSize';
import useWindowWidth from 'src/hooks/useWindowWidth';
import queryKeysFactory from 'src/store/queryKeysFactory';
import Button from '../Button';
import useCalendar from '../Calendar/hook';
import Tabs from '../common/Tabs';
import TechnicianCalendarHeader from './components/TechnicianCalendarHeader';

export type TechnicianCalendarProps = {
  className?: string;
  technicianId: number;
  disableDrag?: boolean;
};

export type TechnicianCalendarTab = 'month' | 'week' | 'day';

const TABS: Array<{ renderLabel: () => ReactNode; value: TechnicianCalendarTab }> = [
  {
    renderLabel: () => <FormattedMessage id='app.technician_account.calendar.tab.month' />,
    value: 'month',
  },
  {
    renderLabel: () => <FormattedMessage id='app.technician_account.calendar.tab.week' />,
    value: 'week',
  },
  {
    renderLabel: () => <FormattedMessage id='app.technician_account.calendar.tab.day' />,
    value: 'day',
  },
];

const TechnicianCalendar: FC<TechnicianCalendarProps> = ({ className, technicianId, disableDrag }) => {
  const navigate = useNavigate();
  const tab = useLocation().pathname.split('/').at(-1) as TechnicianCalendarTab;
  const handleTabChange = (tab: TechnicianCalendarTab): void => navigate(`${tab}`);

  const queryClient = useQueryClient();
  const handleRefresh = useCallback(() => {
    queryClient.invalidateQueries(queryKeysFactory.orders._def);
    queryClient.invalidateQueries(queryKeysFactory.technicianOrder._def);
    queryClient.invalidateQueries(queryKeysFactory.allTechniciansWeekOrders._def);
    queryClient.invalidateQueries(queryKeysFactory.technicianMonthOrders._def);
  }, [queryClient]);

  const windowWidth = useWindowWidth();
  const calendar = useCalendar();

  const outletContextValue = useMemo(
    () => ({ calendar, technicianId, disableDrag }),
    [calendar, disableDrag, technicianId],
  );

  const isDesktop = isLgOrBigger(windowWidth);

  return (
    <div className={clsx('flex flex-col', className)}>
      <div className='flex items-center gap-x-3 mb-4'>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          tabs={isDesktop ? TABS : TABS.filter((tab) => tab.value !== 'week')}
          variant='bubble'
        />
        <Button onClick={handleRefresh} variant='cta' size='small' className='h-10 w-10 rounded-full hover:bg-gray-100'>
          <ArrowPathIcon className='w-6' />
        </Button>
      </div>
      <TechnicianCalendarHeader calendar={calendar} tab={tab} hideButtons={tab === 'day'} className='rounded-t-lg' />
      <Outlet context={outletContextValue} />
    </div>
  );
};

TechnicianCalendar.displayName = 'TechnicianCalendar';

export default TechnicianCalendar;
