import { FC } from 'react';
import usePageBaseState from 'src/hooks/usePageBaseState';
import { FormattedMessage } from 'react-intl';
import PageContentHeader from 'src/components/PageContentHeader';
import WorkTariffsTable from './WorkTariffsTable';
import useWorkTariffs from 'src/api/hooks/queries/useWorkTariffs';
import useLastDefined from 'src/hooks/useLastDefined';
import Popup from 'src/components/Popup';
import AddWorkTariffForm from './AddWorkTariffForm';
import useTableState from 'src/components/Table/hooks/useTableState';
import { WorkTariffFilter } from 'src/types/filters';

export type WorkTariffsPageProps = {
  className?: string;
};

const defaultSortingState = [{ id: 'name', desc: false }];

const WorkTariffsPage: FC<WorkTariffsPageProps> = () => {
  const { isFilterOpen, isPopupOpen, handleClosePopup, handleOpenPopup, handleToggleFilter } = usePageBaseState();

  const {
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
    debouncedServerFriendlyFilters,
    sortingQuery,
  } = useTableState<WorkTariffFilter>({
    defaultSortingState,
  });

  const { data, isLoading } = useWorkTariffs(
    { ...debouncedServerFriendlyFilters, withDeleted: true },
    sortingQuery,
    pagination,
  );
  const definedData = useLastDefined(data);

  const isEmpty = !isLoading && definedData?.items.length === 0;

  return (
    <>
      <PageContentHeader
        mobilePrimaryButtonType='new'
        title={<FormattedMessage id='app.work_tariff_page.title' />}
        onSecondaryButtonClick={handleToggleFilter}
        onPrimaryButtonClick={handleOpenPopup}
        primaryButtonText={<FormattedMessage id='app.work_tariff_page.new_tariff' />}
      />
      {isEmpty ? (
        <FormattedMessage id='app.common.no_items' />
      ) : (
        <WorkTariffsTable
          className='mt-16'
          isLoading={isLoading}
          data={definedData?.items ?? []}
          isFilterOpen={isFilterOpen}
          columnFilters={columnFilters}
          sorting={sorting}
          pagination={pagination}
          handleFilter={setColumnFilters}
          handleSort={setSorting}
          handlePaginationChange={setPagination}
          paginationMeta={definedData?.meta}
        />
      )}
      <Popup
        title={<FormattedMessage id='app.work_tariff_page.new_tariff' />}
        onClose={handleClosePopup}
        open={isPopupOpen}
      >
        <AddWorkTariffForm />
      </Popup>
    </>
  );
};

WorkTariffsPage.displayName = 'WorkTariffsPage';

export default WorkTariffsPage;
