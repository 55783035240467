import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { FinishOrderRequest } from 'src/api';
import { useApiClient } from 'src/api/useApiClient';
import { FinishOrderFormValues } from 'src/pages/TechnicianOrderFinishPage/components/FinishOrderForm/schema';
import queryKeysFactory from 'src/store/queryKeysFactory';

const addOrderProductIdToFileName = (file: File, orderProductId: number): File => {
  const fileName = `${orderProductId}-${file.name}`;
  return new File([file], fileName, { type: file.type });
};

const useFinishTechnicianOrder = (): UseMutationResult<void, unknown, FinishOrderFormValues> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<void, unknown, FinishOrderFormValues>(
    async (data: FinishOrderFormValues) => {
      const images = data.products
        .flatMap((product) => product.images?.map((file) => addOrderProductIdToFileName(file, product.orderProductId)))
        .filter(Boolean);

      await client.technicianOrder.finishOrder({
        formData: {
          ...data,
          images,
          orderSignatureTechnician: data.orderSignatureTechnician.image,
          orderSignatureCustomer: data.orderSignatureCustomer.image,
        } as FinishOrderRequest,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeysFactory.technicianOrder._def);
        queryClient.invalidateQueries(queryKeysFactory.orders._def);
        toast(<FormattedMessage id='app.toasts.add_success' defaultMessage='Záznam úspěšně přidán' />, {
          type: 'success',
        });
      },
      onError: () => {
        toast(<FormattedMessage id='app.toasts.add_fail' defaultMessage='Chyba přidáni záznamu' />, {
          type: 'error',
        });
      },
    },
  );
};

export default useFinishTechnicianOrder;
