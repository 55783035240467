import { formatISO, getHours, getMinutes, parseISO, set } from 'date-fns';
import { FC } from 'react';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'src/components/fields/DatePicker';
import { SelectRhfc } from 'src/components/fields/Select';
import FormDataRow from 'src/components/FormDataRow';
import { TIME_SELECT_VALUES } from '../constants';
import { OrderSchema } from '../schema';

export type ServiceOrderDurationFormProps = {
  onDateChange?: (date: Date) => void;
};

const ServiceOrderDurationForm: FC<ServiceOrderDurationFormProps> = ({ onDateChange }) => {
  const form = useFormContext<OrderSchema>();
  const { control, watch, setValue } = form;

  const watchStartDatetime = watch('datetime');
  const watchEndDatetime = watch('endDatetime');
  const startDatetime = watchStartDatetime ? parseISO(watchStartDatetime) : null;
  const endDatetime = watchEndDatetime ? parseISO(watchEndDatetime) : null;

  const handleStartDatetimeChange = (date: Date | null): void => setValue('datetime', date ? formatISO(date) : null);
  const handleEndDatetimeChange = (date: Date | null): void => setValue('endDatetime', date ? formatISO(date) : null);

  const start = watch('_start');
  const end = watch('_end');

  const startIndex = TIME_SELECT_VALUES.findIndex((i) => i.value === start);
  const availableEndTimes = TIME_SELECT_VALUES.filter((_, index) => index > startIndex);

  const handleDateChange = (date: Date | null): void => {
    if (!date) return;

    setValue('_date', date);
    onDateChange?.(date);

    const newStartDatetime = startDatetime
      ? set(date, {
          hours: getHours(startDatetime),
          minutes: getMinutes(startDatetime),
          seconds: 0,
          milliseconds: 0,
        })
      : date;

    const newEndDatetime = endDatetime
      ? set(date, {
          hours: getHours(endDatetime),
          minutes: getMinutes(endDatetime),
          seconds: 0,
          milliseconds: 0,
        })
      : null;

    handleStartDatetimeChange(newStartDatetime);
    handleEndDatetimeChange(newEndDatetime);
  };

  const handleStartTimeChange = (timeValue: string | null): void => {
    const optionIndex = TIME_SELECT_VALUES.findIndex((i) => i.value === timeValue);

    if (!timeValue || !startDatetime || !optionIndex) return;

    const splitedValue = timeValue.split(':');

    const startHours = Number.parseInt(splitedValue[0]);
    const startMinutes = Number.parseInt(splitedValue[1]);

    const newStartDatetime = set(startDatetime, {
      hours: startHours,
      minutes: startMinutes,
    });

    const shouldUpdateEndDatetime = !end || !endDatetime || newStartDatetime > endDatetime;

    if (shouldUpdateEndDatetime) {
      const newEndDatetime = set(startDatetime, {
        hours: startHours + 1,
        minutes: startMinutes,
      });

      setValue('_end', TIME_SELECT_VALUES[optionIndex + 2]?.value ?? null);
      handleEndDatetimeChange(newEndDatetime);
    }

    handleStartDatetimeChange(newStartDatetime);
  };

  const handleEndTimeChange = (timeValue: string | null): void => {
    if (!timeValue || !endDatetime) return;

    const splitedValue = timeValue.split(':');

    const hours = Number.parseInt(splitedValue[0]);
    const minutes = Number.parseInt(splitedValue[1]);

    const newEndDatetime = set(endDatetime, {
      hours,
      minutes,
    });

    handleEndDatetimeChange(newEndDatetime);
  };

  return (
    <FormProvider {...form}>
      <Controller
        control={control}
        name='_date'
        render={({ field: { value, ...rest }, fieldState }) => (
          <FormDataRow label={<FormattedMessage id='app.order.datetime' />}>
            <DatePicker {...rest} onChange={handleDateChange} value={value} error={fieldState.error?.message} />
          </FormDataRow>
        )}
      />
      <FormDataRow label={<FormattedMessage id='app.order.start_time' />}>
        <SelectRhfc
          name='_start'
          control={control}
          options={TIME_SELECT_VALUES.slice(0, -2)}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          extraOnChange={handleStartTimeChange}
        />
      </FormDataRow>
      <FormDataRow label={<FormattedMessage id='app.order.end_time' />}>
        <SelectRhfc
          name='_end'
          control={control}
          options={availableEndTimes}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.value}
          extraOnChange={handleEndTimeChange}
        />
      </FormDataRow>
    </FormProvider>
  );
};

export default ServiceOrderDurationForm;
