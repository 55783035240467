import { formatISO, parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ContactResponse } from 'src/api';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import DatePicker from 'src/components/fields/DatePicker';
import { SelectRhfc } from 'src/components/fields/Select';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import FormDataRow from 'src/components/FormDataRow';
import useGetIsHiddenProductFormField from 'src/hooks/formsConfig/useGetIsHiddenProductFormField';
import ProductFormTemplateSelect from './ProductFormTemplateSelect';
import { ProductSchemaB2B, ProductSchemaB2C } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';

interface ProductsFormProps {
  defaultValues?: Partial<ProductSchemaB2B | ProductSchemaB2C>;
  onSubmit: SubmitHandler<ProductSchemaB2B | ProductSchemaB2C>;
  branchOptions: {
    label: string;
    value: number;
    disabled?: boolean;
  }[];
  className?: string;
  hideBranch?: boolean;
  category: ContactResponse.category;
}

const ProductForm: React.FC<ProductsFormProps> = ({ defaultValues, onSubmit, category, branchOptions, hideBranch }) => {
  const form = useForm<ProductSchemaB2B | ProductSchemaB2C>({
    resolver: zodResolver(category === ContactResponse.category.B2B ? ProductSchemaB2B : ProductSchemaB2C),
    defaultValues: defaultValues,
  });
  const { handleSubmit, control, setValue } = form;

  const { data: config } = useCurrentTenantConfig();
  const getIsHiddenField = useGetIsHiddenProductFormField();

  // I really don't like the solutuion, but all alternatives I see are too wordy
  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof (ProductSchemaB2B | ProductSchemaB2C), v));
  }, [defaultValues]);

  return (
    <div className='flex-col w-full'>
      <form className='flex flex-col w-full' onSubmit={handleSubmit(onSubmit, console.error)}>
        <FormProvider {...form}>
          {config?.orderForm?.useProductTemplates && <ProductFormTemplateSelect />}
          <FormDataRow asterisk label={<FormattedMessage id='app.product.name' defaultMessage='Název' />}>
            <TextFieldRhfc control={control} name='productName' />
          </FormDataRow>

          {!hideBranch && (
            <FormDataRow asterisk label={<FormattedMessage id='app.product.branch' defaultMessage='Pobočka' />}>
              <SelectRhfc
                control={control}
                name='branchId'
                options={branchOptions}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
              />
            </FormDataRow>
          )}

          {!getIsHiddenField('productProducer') && (
            <FormDataRow label={<FormattedMessage id='app.product.producer' />}>
              <TextFieldRhfc control={control} name='productProducer' />
            </FormDataRow>
          )}

          {!getIsHiddenField('productSerialNumber') && (
            <FormDataRow label={<FormattedMessage id='app.product.serial_number' />}>
              <TextFieldRhfc control={control} name='productSerialNumber' />
            </FormDataRow>
          )}

          {config?.productForm?.showProductSerialNumber2 && (
            <FormDataRow label={<FormattedMessage id='app.product.serial_number2' />}>
              <TextFieldRhfc control={control} name='productSerialNumber2' />
            </FormDataRow>
          )}

          {config?.productForm?.showTechnology && (
            <FormDataRow label={<FormattedMessage id='app.product.technology' />}>
              <TextFieldRhfc control={control} name='technology' />
            </FormDataRow>
          )}

          {!getIsHiddenField('productNote') && (
            <FormDataRow label={<FormattedMessage id='app.product.documentation_note' />}>
              <TextFieldRhfc control={control} name='productNote' />
            </FormDataRow>
          )}

          {!getIsHiddenField('inUseFrom') && (
            <Controller
              control={control}
              name='inUseFrom'
              render={({ field: { value, ...rest }, fieldState }) => (
                <FormDataRow label={<FormattedMessage id='app.product.in_use_from' />}>
                  <DatePicker
                    {...rest}
                    onChange={(v) => rest.onChange(v ? formatISO(v) : null)}
                    value={value ? parseISO(value) : null}
                    error={fieldState.error?.message}
                  />
                </FormDataRow>
              )}
            />
          )}

          {!getIsHiddenField('warranty') && (
            <Controller
              control={control}
              name='warranty'
              render={({ field: { value, ...rest }, fieldState }) => (
                <FormDataRow label={<FormattedMessage id='app.product.warranty' />}>
                  <DatePicker
                    {...rest}
                    onChange={(v) => rest.onChange(v ? formatISO(v) : null)}
                    value={value ? parseISO(value) : null}
                    error={fieldState.error?.message}
                  />
                </FormDataRow>
              )}
            />
          )}
          <NewItemPopupFooter />
        </FormProvider>
      </form>
    </div>
  );
};

export default ProductForm;
