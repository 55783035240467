import clsx from 'clsx';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useOrderType from 'src/api/hooks/queries/useOrderType';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import Loader from 'src/components/utils/Loader';
import OrderTypeConfigurationWizzard from './components/OrderTypeConfigurationWizzard';
import OrderTypePreview from './components/OrderTypePreview';
import UpdateOrderTypeForm from './components/UpdateOrderTypeForm';
import { OrderTypeTranslationsTable } from './components/OrderTypeTranslationsTable/OrderTypeTranslationsTable';

export type OrderTypeDetailPageProps = {
  className?: string;
};

const OrderTypeDetailPage: FC<OrderTypeDetailPageProps> = ({ className }) => {
  const orderTypeId = Number(useParams().orderTypeId);
  const navigate = useNavigate();
  const { isLoading, data } = useOrderType(orderTypeId);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (isLoading) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <PageContentHeader
        mobilePrimaryButtonType='edit'
        className='mb-20'
        title={<FormattedMessage id='app.order_type_detail_page.title' values={{ value: data.id }} />}
        primaryButtonText={<FormattedMessage id='app.buttons.edit' defaultMessage='Upravit' />}
        secondaryButtonText={
          <>
            <BackIcon />
            <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
          </>
        }
        onPrimaryButtonClick={() => setIsPopupOpen(true)}
        onSecondaryButtonClick={() => navigate(-1)}
      />
      <OrderTypePreview data={data} />
      <OrderTypeTranslationsTable orderTypeId={orderTypeId} className='mt-20' />
      <OrderTypeConfigurationWizzard orderType={data} className='mt-20' />
      <Popup
        title={<FormattedMessage id='app.order_type_page.update_tarriff_popup_title' />}
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      >
        <UpdateOrderTypeForm orderTypeId={orderTypeId} />
      </Popup>
    </div>
  );
};

OrderTypeDetailPage.displayName = 'OrderTypeDetailPage';

export default OrderTypeDetailPage;
