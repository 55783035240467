import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useDeleteTenant from 'src/api/hooks/mutations/tenant/useDeleteTenant';
import useRestoreTenant from 'src/api/hooks/mutations/tenant/useRestoreTenant';
import useTenant from 'src/api/hooks/queries/useTenant';
import Button from 'src/components/Button';
import PageContentHeader from 'src/components/PageContentHeader';
import Loader from 'src/components/utils/Loader';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import useWithConfirmationPopup from 'src/hooks/popups/useWithConfirmationPopup';
import UpdateTenantPopup from '../TenantsPage/components/UpdateTenantPopup';
import TenantConfigurationWizzard from './components/TenantConfigurationWizzard';
import TenantPreview from './components/TenantPreview';

export type TenantDetailPageProps = {
  className?: string;
};

const TenantDetailPage: FC<TenantDetailPageProps> = ({ className }) => {
  const tenantId = Number(useParams().tenantId);
  const navigate = useNavigate();
  const { isLoading, data } = useTenant(tenantId);
  const deleteTenant = useDeleteTenant();
  const restoreTenant = useRestoreTenant();
  const openPopup = useOpenPopup('update_tenant');
  const withConfirmationPopup = useWithConfirmationPopup();

  const handleRestoreTenant = withConfirmationPopup({
    onOk: () => restoreTenant.mutate(tenantId),
    title: <FormattedMessage id='app.tenant_detail_page.restore_tenant.popup_title' />,
    text: <FormattedMessage id='app.tenant_detail_page.restore_tenant.popup_text' />,
  });

  const handleDeleteTenant = withConfirmationPopup({
    onOk: () => deleteTenant.mutate(tenantId),
    title: <FormattedMessage id='app.tenant_detail_page.delete_tenant.popup_title' />,
    text: <FormattedMessage id='app.tenant_detail_page.delete_tenant.popup_text' />,
  });

  if (isLoading) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <>
      <div className={clsx(className, 'flex flex-col w-full gap-y-20')}>
        <PageContentHeader
          mobilePrimaryButtonType='edit'
          title={
            <div className='flex items-center'>
              <FormattedMessage
                id='app.tenant_detail_page.title'
                values={{
                  value: data.id,
                }}
              />
              <Button variant='cta' size='small' onClick={data.deletedAt ? handleRestoreTenant : handleDeleteTenant}>
                {data.deletedAt ? <EyeIcon className='h-6 mr-1' /> : <EyeSlashIcon className='h-6 mr-1' />}
              </Button>
            </div>
          }
          primaryButtonText={<FormattedMessage id='app.buttons.edit' />}
          secondaryButtonText={<FormattedMessage id='app.buttons.back' />}
          onPrimaryButtonClick={openPopup}
          onSecondaryButtonClick={() => navigate(-1)}
        />
        <TenantPreview data={data} />
        <TenantConfigurationWizzard tenantId={tenantId} />
      </div>
      <UpdateTenantPopup />
    </>
  );
};

TenantDetailPage.displayName = 'TenantDetailPage';

export default TenantDetailPage;
