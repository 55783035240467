import { FC, HTMLAttributes, useCallback } from 'react';
import ConfirmationPopup from 'src/components/common/ConfirmationPopup';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useDeleteRoutineService from 'src/api/hooks/mutations/routineServices/useDeleteRoutineService';

export type DeleteRoutineServicePopupProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const DeleteRoutineServicePopup: FC<DeleteRoutineServicePopupProps> = ({ className }) => {
  const routineServiceId = Number(useParams().routineServiceId);
  const navigate = useNavigate();

  const deleteRoutineService = useDeleteRoutineService();

  const onOk = useCallback(() => {
    deleteRoutineService.mutate({ id: routineServiceId });
    navigate(`/routine-services`);
  }, [routineServiceId]);

  if (!routineServiceId) return <Navigate to='/404' />;

  return (
    <ConfirmationPopup
      className={className}
      id='delete_routine_service'
      title={<FormattedMessage id='app.routine_services_page.delete_popup_title' />}
      onOk={onOk}
    ></ConfirmationPopup>
  );
};

DeleteRoutineServicePopup.displayName = 'DeleteRoutineServicePopup';

export default DeleteRoutineServicePopup;
