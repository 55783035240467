import clsx from 'clsx';
import { format } from 'date-fns';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/Button';
import HyperLink from 'src/components/common/HyperLink';
import { GridEvent } from 'src/components/EventsGrid/types';
import { DateInfo } from '../../types';

export type DayProps = {
  dateInfo: DateInfo;
  onClick?: () => void;
  className?: string;
  events: GridEvent[];
  onShowMoreEvents?: () => void;
};

const Day: FC<DayProps> = ({ dateInfo, onClick, events, onShowMoreEvents, className }) => {
  const { day, date, isSelected, isSelectedMonth, isToday } = dateInfo;
  const shouldShowMoreEventsButton = events.length > 2;

  return (
    <button
      onClick={onClick}
      type='button'
      className={clsx(
        'relative focus:z-10 h-12 xl:h-[6.5rem]',
        isSelectedMonth ? 'bg-white hover:bg-gray-50' : 'bg-gray-50 hover:bg-gray-100',
        !isSelected && isSelectedMonth && !day && 'text-gray-900',
        !isSelected && !isSelectedMonth && !isToday && 'text-gray-500',
        className,
      )}
    >
      <time
        dateTime={date.toISOString()}
        className={clsx(
          'absolute text-xs font-semibold',
          !isToday && 'top-2 right-2 h-4 w-4',
          isToday && 'top-1 right-1 h-6 w-6 p-1 text-white bg-primary rounded-full',
        )}
      >
        {day}
      </time>
      {!!events.length && (
        <>
          <div className='xl:hidden absolute left-2 bottom-2 flex gap-x-[2px]'>
            {events.map((event) => (
              <span
                key={`${event.id}-${event.start.toDateString()}-${event.end.toDateString()}`}
                className='block w-[6px] h-[6px] rounded-full bg-gray-500'
              ></span>
            ))}
          </div>
          <div className='hidden relative z-20 xl:flex flex-col justify-end w-full h-full pt-8 px-2 pb-2'>
            {events.slice(0, shouldShowMoreEventsButton ? 2 : undefined).map((event) => (
              <HyperLink key={event.id} href={`/order/${event.id}`} target='_blank'>
                <div className='flex px-1 w-full justify-between'>
                  <span className='text-xs text-gray-600 max-w-[65%] whitespace-nowrap overflow-hidden'>
                    {event.data.orderNumber} {event.data.companyName}
                  </span>
                  <span className='text-xs text-gray-500'>{format(event.start, 'HH:mm')}</span>
                </div>
              </HyperLink>
            ))}
            {shouldShowMoreEventsButton && (
              <Button variant='cta' size='small' onClick={onShowMoreEvents}>
                <span className='w-full text-left text-xs text-primary hover:text-primaryFocus'>
                  <FormattedMessage id='app.common.n_more' values={{ value: events.length - 2 }} />
                </span>
              </Button>
            )}
          </div>
        </>
      )}
      {isSelected && <div className='absolute z-0 inset-0 border border-primary'></div>}
    </button>
  );
};

Day.displayName = 'Day';

export default Day;
