import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { OrderListItemResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useAllTechniciansWeekOrders = (year: number, weekIndex: number): UseQueryResult<OrderListItemResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.allTechniciansWeekOrders.list(year, weekIndex);

  return useQuery(queryKey, async () => {
    const data = await client.orders.getTechniciansWeekOrders({ year, weekIndex });
    return data;
  });
};

export default useAllTechniciansWeekOrders;
