import clsx from 'clsx';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useDeliveryRate from 'src/api/hooks/queries/useDeliveryRate';
import BackIcon from 'src/components/Icons/BackIcon';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import Loader from 'src/components/utils/Loader';
import useGetIsModuleValid from 'src/hooks/useGetIsModuleValid';
import { Modules } from 'src/types/commonTypes';
import DeliveryRatePreview from './DeliveryRatePreview';
import UpdateDeliveryRateFrom from './UpdateDeliveryRateFrom';

export type DeliveryRatesDetailPageProps = {
  className?: string;
};

const DeliveryRatesDetailPage: FC<DeliveryRatesDetailPageProps> = ({ className }) => {
  const deliveryRateId = Number(useParams().deliveryRateId);
  const navigate = useNavigate();
  const getIsModuleValid = useGetIsModuleValid();
  const { isLoading, data } = useDeliveryRate(deliveryRateId);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  if (!getIsModuleValid(Modules.ADMINISTRATION)) return <Navigate to='/' />;
  if (isLoading) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <PageContentHeader
        mobilePrimaryButtonType='edit'
        className='mb-20'
        title={<FormattedMessage id='app.delivery_rate_detail_page.title' values={{ value: data.id }} />}
        primaryButtonText={<FormattedMessage id='app.buttons.edit' />}
        secondaryButtonText={
          <>
            <BackIcon />
            <FormattedMessage id='app.buttons.back' />
          </>
        }
        onPrimaryButtonClick={() => setIsPopupOpen(true)}
        onSecondaryButtonClick={() => navigate(-1)}
      />
      <DeliveryRatePreview data={data} />
      <Popup
        title={<FormattedMessage id='app.delivery_tariff_page.update_rate_popup_title' />}
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      >
        <UpdateDeliveryRateFrom deliveryRateId={deliveryRateId} />
      </Popup>
    </div>
  );
};

DeliveryRatesDetailPage.displayName = 'DeliveryRatesDetailPage';

export default DeliveryRatesDetailPage;
