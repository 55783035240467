import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import NumberField from 'src/components/fields/NumberField';
import FieldWrapper from 'src/pages/TechnicianOrderFinishPage/components/FieldWrapper';
import { FinishOrderFormValues } from '../../../schema';
import { SelectRhfc } from 'src/components/fields/Select';
import useAllDeliveryRates from 'src/api/hooks/queries/useAllDeliveryRates';
import { isNil } from 'ramda';

export type CalculatedDeliveryFormProps = {
  hiddenFields?: string[];
};

// NOTE:  temporary remove time on road from non default rate delivery
// https://gitlab.sprinx.com/service-app/service-app/-/work_items/150
const CalculatedDeliveryForm: FC<CalculatedDeliveryFormProps> = ({ hiddenFields }) => {
  const intl = useIntl();
  const { control, setValue, watch } = useFormContext<FinishOrderFormValues>();
  const { data: deliveryRates } = useAllDeliveryRates();
  const deliveryRateId = watch('deliveryRateId');

  return (
    <>
      {!hiddenFields?.includes('deliveryRateId') && (
        <FieldWrapper label={<FormattedMessage id='app.finish_order.delivery_rate' />}>
          <SelectRhfc
            control={control}
            name='deliveryRateId'
            options={[
              { id: null, name: intl.formatMessage({ id: 'app.tenant_default_delivery_rate' }) },
              ...(deliveryRates ?? []),
            ]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            extraOnChange={(option) => {
              if (option) return;

              setValue('serviceTimeOnRoad', 0);
            }}
          />
        </FieldWrapper>
      )}
      {!hiddenFields?.includes('serviceMileage') && (
        <FieldWrapper label={<FormattedMessage id='app.finish_order.service_mileage' />}>
          <Controller
            control={control}
            name={'serviceMileage'}
            render={({ field, fieldState }) => <NumberField {...field} error={fieldState.error?.message} type='text' />}
          />
        </FieldWrapper>
      )}
      {!hiddenFields?.includes('serviceTimeOnRoad') && isNil(deliveryRateId) && (
        <FieldWrapper label={<FormattedMessage id='app.finish_order.time_on_road' />}>
          <Controller
            control={control}
            name={'serviceTimeOnRoad'}
            render={({ field, fieldState }) => (
              <NumberField {...field} error={fieldState.error?.message} type='number' />
            )}
          />
        </FieldWrapper>
      )}
    </>
  );
};

export default CalculatedDeliveryForm;
