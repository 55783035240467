import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { FileUploadResponse } from '../.gen';
import { useOldApiClient } from '../useApiClient';

export type UseUploadFileReturn = (
  file: File,
  options?: UseUploadFileOptions,
) => Promise<AxiosResponse<FileUploadResponse>>;

export type UseUploadFileOptions = {
  encodeFileName?: boolean;
};

const useUploadFile = (path: string): UseUploadFileReturn => {
  const oldApiClient = useOldApiClient();

  return useCallback(
    (file: File, options?: UseUploadFileOptions) => {
      const { encodeFileName } = options ?? {};
      const encodedName = encodeURIComponent(file.name);

      return oldApiClient.post(
        path,
        {
          file: encodeFileName ? renameFile(file, encodedName) : file,
          fileName: encodeFileName ? encodedName : file.name,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            accept: '*/*',
          },
        },
      );
    },
    [path],
  );
};

function renameFile(file: File, newName: string): File {
  return new File([file], newName, { type: file.type });
}

export default useUploadFile;
