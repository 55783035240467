import { TrashIcon } from '@heroicons/react/24/outline';
import { remove } from 'ramda';
import { FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/Button';
import ImagesUploadField from 'src/components/ImagesUploadField';
import Popup from 'src/components/Popup';
import SectionTitle from '../../SectionTitle';
import { FinishOrderFormValues } from '../schema';

export type ProductPhotosFormProps = {
  orderProductIndex: number;
};

const ProductPhotosForm: FC<ProductPhotosFormProps> = ({ orderProductIndex }) => {
  const { control, watch, setValue } = useFormContext<FinishOrderFormValues>();
  const images = watch(`products.${orderProductIndex}.images`);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleRemoveImage = (index: number): void =>
    setValue(`products.${orderProductIndex}.images`, remove(index, 1, images));

  return (
    <>
      <div className='flex flex-col'>
        <SectionTitle className='mt-16 mb-4'>
          <FormattedMessage id='app.finish_order.photo' />
        </SectionTitle>
        <Controller
          key={orderProductIndex}
          control={control}
          name={`products.${orderProductIndex}.images`}
          render={({ field: { value, name, onChange } }) => (
            <ImagesUploadField
              value={value}
              name={name}
              onChange={onChange}
              multiple
              addImagesOnChange
              buttonClassName='w-16'
              className='mb-4'
            />
          )}
        />
        {images?.length > 0 && (
          <div className='flex flex-wrap gap-16 w-full mb-6'>
            {images?.map((image, index) => (
              <div key={image.name} className='flex'>
                <img
                  onClick={() => setSelectedImage(image)}
                  className='object-cover max-w-[260px] max-h-[260px] cursor-zoom-in'
                  src={window.URL.createObjectURL(image)}
                />
                <Button className='ml-2 max-w-fit' size='small' variant='cta' onClick={() => handleRemoveImage(index)}>
                  <TrashIcon className='h-6' />
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
      <Popup open={!!selectedImage} onClose={() => setSelectedImage(null)}>
        <div className='flex w-full h-full'>
          {selectedImage && (
            <img
              className='object-contain w-full h-full cursor-zoom-in'
              src={window.URL.createObjectURL(selectedImage)}
            />
          )}
        </div>
      </Popup>
    </>
  );
};

export default ProductPhotosForm;
