import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api';
import HyperLink from 'src/components/common/HyperLink';
import Logo from 'src/components/common/Logo';
import { useAuth } from 'src/store/authContext';
import Button from '../../components/Button';
import Card from '../../components/Card';
import Checkbox from '../../components/fields/Checkbox';
import TextField from '../../components/fields/Textfield';

type LoginForm = {
  email: string;
  password: string;
  canSaveToken: boolean;
};

function LoginPage(): React.ReactElement {
  const client = useApiClient();

  const { handleSubmit, control } = useForm<LoginForm>();

  const navigate = useNavigate();

  const { setAuthToken, setAuthEmail } = useAuth();

  const [isLoginInvalid, setIsLoginInvalid] = useState(false);

  const onSubmit: SubmitHandler<LoginForm> = async (data) => {
    try {
      const email = data.email.toLocaleLowerCase().trim();

      const result = await client.auth.login({
        requestBody: {
          userEmail: email,
          userPsw: data.password,
        },
      });
      setAuthToken(result.token, data.canSaveToken);
      setAuthEmail(email);
      navigate('/');
    } catch (error) {
      setIsLoginInvalid(true);
      toast(<FormattedMessage id='app.toasts.login_fail' />, {
        type: 'error',
      });
    }
  };

  return (
    <div className='bg-gray-50 grid place-items-center h-screen'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid place-items-center'>
          <div className='flex justify-center items-center w-52 h-28'>
            <Logo className='App-logo' />
          </div>
          <h1 className='text-4xl font-semibold font-openSans text-gray-800 my-10'>
            <FormattedMessage id='app.login.title' />
          </h1>

          <Card className='sm:shadow-none p-4 md:p-8'>
            <div className='flex flex-col mb-8'>
              <div className='text-gray-800 mb-3'>
                <FormattedMessage id='app.common.email' />
              </div>

              <Controller
                control={control}
                name='email'
                render={({ field }) => (
                  <>
                    <TextField {...field} error={isLoginInvalid} hideErrorMessage />
                  </>
                )}
              />
            </div>

            <div className='flex flex-col mb-2'>
              <div className='text-gray-800  mb-3'>
                <FormattedMessage id='app.login.password' />
              </div>
              <Controller
                control={control}
                name='password'
                render={({ field }) => (
                  <>
                    <TextField {...field} error={isLoginInvalid} hideErrorMessage type='password' />
                  </>
                )}
              />
            </div>
            <span className='text-error text-sm flex mb-8'>{isLoginInvalid}</span>

            <div className='flex justify-between mb-12 gap-x-2'>
              <div className='flex items-center'>
                <Controller
                  control={control}
                  name='canSaveToken'
                  render={({ field }) => (
                    <>
                      <Checkbox {...field} id='canSaveToken' className='mr-2' />
                    </>
                  )}
                />
                <label htmlFor='canSaveToken'>
                  <FormattedMessage id='app.login.remember_me' />
                </label>
              </div>
              <HyperLink href='/forgot-password' className='font-semibold'>
                <FormattedMessage id='app.login.forgot_password' />
              </HyperLink>
            </div>
            <Button type='submit' className='w-full'>
              <FormattedMessage id='app.login.button' />
            </Button>
          </Card>
        </div>
      </form>
    </div>
  );
}

export default LoginPage;
