import { PencilIcon } from '@heroicons/react/24/solid';
import { FC, HTMLAttributes, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Navigate } from 'react-router-dom';
import useOrder from 'src/api/hooks/queries/useOrder';
import Button from 'src/components/Button';
import Logo from 'src/components/common/Logo';
import PageContentHeader from 'src/components/PageContentHeader';
import Loader from 'src/components/utils/Loader';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import { useNavigateWithSearch } from 'src/utils/useNavigateWithSearch';
import UpdateServicePopup from '../HomePage/UpdateServicePopup';
import useOrderIdFromToken from '../TechnicianOrderFinishPage/hooks/useOrderIdFromToken';
import TechnicianOrderPreview from './TechnicianOrderPreview';
import UnsuccessfulOrderPopup from './UnsuccessfulOrderPopup';

export type TechnicianOrderPageProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const TechnicianOrderPage: FC<TechnicianOrderPageProps> = () => {
  const orderId = useOrderIdFromToken();
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const { data, isLoading } = useOrder(orderId);

  const openPopup = useOpenPopup('unseccessful_order');
  const navigateWithSearch = useNavigateWithSearch();

  if (isLoading) return <Loader variant='global' />;
  if (!data) return <Navigate to='404' />;

  return (
    <>
      <PageContentHeader
        className='mb-20'
        title={
          <Link to='/'>
            <Logo className='h-20' />
          </Link>
        }
        noLine
        primaryButtonText={<FormattedMessage id='app.button.next' />}
        onPrimaryButtonClick={() => navigateWithSearch({ pathname: '/technician-order/finish' })}
        secondaryButtonText={<FormattedMessage id='app.technician_order_page.failed_order_button' />}
        onSecondaryButtonClick={openPopup}
      />
      <TechnicianOrderPreview
        data={data}
        extraTitle={
          <Button className='ml-4' size='small' variant='cta' onClick={() => setIsEditPopupOpen(true)}>
            <PencilIcon className='w-5 h-5 mr-2' />
            <FormattedMessage id='app.buttons.edit' />
          </Button>
        }
      />
      <UnsuccessfulOrderPopup />
      <UpdateServicePopup orderId={orderId} open={isEditPopupOpen} onClose={() => setIsEditPopupOpen(false)} />
    </>
  );
};

TechnicianOrderPage.displayName = 'TechnicianOrderPage';

export default TechnicianOrderPage;
