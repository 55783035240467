import { useMemo } from 'react';
import { FC } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import { ITEM_TYPES } from '../../constants';
import { GridEvent } from '../../types';
import EventPreview from '../EventPreview';

export type EventsDragLayerProps = {
  events: GridEvent[];
};

const defaultOffset: XYCoord = { x: 0, y: 0 };

const EventsDragLayer: FC<EventsDragLayerProps> = ({ events }) => {
  const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const event = useMemo(() => events.find((e) => e.id === item?.id), [events, item?.id]);

  if (!isDragging) return null;
  if (!event) return null;

  if (itemType === ITEM_TYPES.EVENT) {
    return (
      <div className='fixed left-0 top-0 z-[99999] pointer-events-none'>
        <EventPreview
          event={event}
          currentOffset={currentOffset ?? defaultOffset}
          initialOffset={initialOffset ?? defaultOffset}
        />
      </div>
    );
  }

  return null;
};

EventsDragLayer.displayName = 'EventsDragLayer';

export default EventsDragLayer;
