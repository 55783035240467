import { FC, useEffect } from 'react';
import clsx from 'clsx';
import { CreateVatTariffRequest } from 'src/api';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { VatTariffSchema } from './schema';
import { FormattedMessage } from 'react-intl';
import NumberField from 'src/components/fields/NumberField';
import FormDataRow from 'src/components/FormDataRow';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import { zodResolver } from '@hookform/resolvers/zod';

export type VatTariffFormProps = {
  className?: string;
  defaultValues?: Partial<VatTariffSchema>;
  onSubmit: SubmitHandler<VatTariffSchema>;
  disableValue?: boolean;
};

const DEFAULT_VALUES: Partial<VatTariffSchema> = {
  name: '',
  tariffValue: 0,
};

const VatTariffForm: FC<VatTariffFormProps> = ({ className, disableValue: hideValue, defaultValues, onSubmit }) => {
  const form = useForm<VatTariffSchema>({
    resolver: zodResolver(VatTariffSchema),
    defaultValues: { ...DEFAULT_VALUES, ...defaultValues },
  });

  const { handleSubmit, control, setValue } = form;

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof CreateVatTariffRequest, v));
  }, [defaultValues]);

  return (
    <div className={clsx(className, 'flex-col w-full')}>
      <form className='flex flex-col w-full' onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          {!hideValue && (
            <FormDataRow asterisk label={<FormattedMessage id='app.vat_tariff.value' />}>
              <Controller
                control={control}
                name='tariffValue'
                render={({ field, fieldState }) => (
                  <NumberField {...field} type='text' error={fieldState.error?.message} />
                )}
              />
            </FormDataRow>
          )}

          <FormDataRow asterisk label={<FormattedMessage id='app.common.name' />}>
            <TextFieldRhfc control={control} name='name' />
          </FormDataRow>
        </FormProvider>
        <NewItemPopupFooter />
      </form>
    </div>
  );
};

VatTariffForm.displayName = 'VatTariffForm';

export default VatTariffForm;
