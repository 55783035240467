import { CalendarIcon, LinkIcon, MapPinIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { format, parseISO } from 'date-fns';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderListItemResponse } from 'src/api';
import HyperLink from 'src/components/common/HyperLink';

export type UpcomingOrderListItemProps = {
  className?: string;
  data: OrderListItemResponse;
};

const UpcomingOrderListItem: FC<UpcomingOrderListItemProps> = ({ className, data }) => {
  const { datetime, orderNumber, companyName, companyAddress, id } = data;

  return (
    <li className={clsx('flex flex-col gap-y-3 py-4', className)}>
      <div className='flex justify-between items-center'>
        <div className='flex flex-col'>
          <span className='text-md font-semibold'>
            <FormattedMessage
              id='app.technician_account.calendar.month.order_title'
              values={{
                orderNumber,
                companyName,
              }}
            />
          </span>
          <div className='flex items-center gap-x-1'>
            <CalendarIcon className='h-4 w-4 text-gray-500' />
            <span className='text-sm text-gray-500'>
              {datetime && format(parseISO(datetime), 'dd/MM/yyyy - hh:mm')}
            </span>
          </div>
        </div>

        <div className='flex'>
          <HyperLink target='_blank' href={`https://www.google.com/maps?q=${companyName} ${companyAddress}`}>
            <MapPinIcon className='text-primary w-6' />
          </HyperLink>
          <HyperLink target='_blank' href={`/order/${id}`}>
            <LinkIcon className='text-primary w-6' />
          </HyperLink>
        </div>
      </div>
    </li>
  );
};

UpcomingOrderListItem.displayName = 'UpcomingOrderListItem';

export default UpcomingOrderListItem;
