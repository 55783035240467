import { getDate, isEqual, setDate } from 'date-fns';
import { useCallback } from 'react';
import { UserResponse } from 'src/api';
import useMoveTechnicianEvent from 'src/hooks/useMoveTechnicianEvent';
import { findById } from '../helpers/state';
import { GridEvent, HandleDropFunc } from '../types';

const useHandleDropMTDFactory = (
  events: GridEvent[],
  technicians: UserResponse[],
): ((date: Date) => HandleDropFunc) => {
  const moveEvent = useMoveTechnicianEvent(events);

  return useCallback<(date: Date) => HandleDropFunc>(
    (date: Date) => (item, monitor, context, result) => {
      const event = findById(item.id, events);
      if (!event) return;

      const { row } = result;

      const { gridToTimeSpan } = context;

      const { start, end } = event;
      const { start: newStart } = gridToTimeSpan({ ...result, start, end });

      const newTechnicianId = technicians[row - 1].id;

      if (isEqual(start, newStart) && event.data.technicians.map((i) => i.id).includes(newTechnicianId)) return;

      const newStartDate = setDate(newStart, getDate(date));

      moveEvent(item.id, newStartDate, item.technicianId, newTechnicianId);
    },
    [events, technicians],
  );
};

export default useHandleDropMTDFactory;
