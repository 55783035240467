import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { ColumnFiltersState } from '@tanstack/react-table';
import { format } from 'date-fns';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { OrderListItemResponse, OrderResponse } from 'src/api';
import useAllHeadquarters from 'src/api/hooks/queries/useAllHeadquarters';
import useAllVatTariffs from 'src/api/hooks/queries/useAllVatTariffs';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import { useOrders } from 'src/api/hooks/queries/useOrders';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import Button from 'src/components/Button';
import PageContentHeader from 'src/components/PageContentHeader';
import useTableState from 'src/components/Table/hooks/useTableState';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import useLastDefined from 'src/hooks/useLastDefined';
import usePageBaseState from 'src/hooks/usePageBaseState';
import { ServiceFilter } from 'src/types/filters';
import AddServicePopup from './AddServicePopup';
import OrderColumnsConfigurationPopup, {
  ORDER_COLUMNS_CONFIGURATION_DIALOG_ID,
} from './OrderColumnsConfigurationPopup';
import ServiceTable from './ServiceTable';

const transformFilters = (filters: ColumnFiltersState): ServiceFilter => {
  const datetimeValue = filters.find((i) => i.id === 'datetime')?.value;

  return {
    statusProp: (filters.find((i) => i.id === 'status.id')?.value as string[]) ?? ([] as string[]),
    technicianProp: (filters.find((i) => i.id === 'technicians.profile.name')?.value as string) ?? '',
    companyProp: (filters.find((i) => i.id === 'company.companyName')?.value as string) ?? '',
    datetime: datetimeValue ? format(datetimeValue as Date, 'yyyy-MM-dd') : '',
    orderNumber: (filters.find((i) => i.id === 'orderNumber')?.value as string) ?? '',
    productName: (filters.find((i) => i.id === 'productName')?.value as string) ?? '',
    orderTypeId: (filters.find((i) => i.id === 'orderType.name')?.value as number) ?? null,
    category: (filters.find((i) => i.id === 'category')?.value as OrderResponse.category) ?? null,
    branchName: (filters.find((i) => i.id === 'branch.name')?.value as string) ?? '',
  };
};

const HomePage: React.FC = () => {
  const { data: config } = useCurrentTenantConfig();
  const { data: currentUser } = useCurrentUser();
  const { isFilterOpen, isPopupOpen, handleClosePopup, handleOpenPopup, handleToggleFilter } = usePageBaseState();

  const {
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
    debouncedServerFriendlyFilters,
    sortingQuery,
  } = useTableState({
    transformFilters,
    defaultColumnFiltersState: config?.orderTable?.defaultColumnFiltersState,
    defaultSortingState: [],
    localStorageKey: 'orders',
    keepFiltersInLocalStorage: config?.orderTable?.keepFiltersInLocalStorage,
  });

  const { data, isFetching } = useOrders(debouncedServerFriendlyFilters, sortingQuery, pagination);
  const definedData = useLastDefined(data);

  const { data: vatTariffs, isLoading: isLoadingVatTariffs } = useAllVatTariffs();
  const { data: headquarters, isLoading: isLoadingHeadquarters } = useAllHeadquarters();
  const navigate = useNavigate();

  const openColumnsConfigurationPopup = useOpenPopup(ORDER_COLUMNS_CONFIGURATION_DIALOG_ID);

  const handlePrimaryButtonClick = useCallback(() => {
    if (vatTariffs?.length === 0 || headquarters?.length === 0) {
      toast(<FormattedMessage id='app.toast.order.hq_or_vat_tariff_missing' />, { type: 'info' });
      return navigate('/parameter');
    }

    handleOpenPopup();
  }, [handleOpenPopup, navigate, vatTariffs, headquarters]);

  return (
    <>
      <PageContentHeader
        mobilePrimaryButtonType='new'
        title={
          <div className='flex items-center'>
            <FormattedMessage id='app.home.title' />
            <Button className='' size='small' onClick={openColumnsConfigurationPopup} variant='cta'>
              <Cog8ToothIcon className='w-8' />
            </Button>
          </div>
        }
        onSecondaryButtonClick={handleToggleFilter}
        onPrimaryButtonClick={handlePrimaryButtonClick}
        primaryButtonText={<FormattedMessage id='app.order.new_order_button' />}
        isPrimaryDisabled={isLoadingHeadquarters || isLoadingVatTariffs}
      />
      <ServiceTable
        className='mt-16'
        isLoading={isFetching}
        columns={
          currentUser?.profile?.preferredColumns?.filter((i) => i.visible).map((i) => i.id) ??
          config?.orderTable?.defaultColumns
        }
        data={definedData?.items ?? ([] as OrderListItemResponse[])}
        sorting={sorting}
        columnFilters={columnFilters}
        paginationMeta={definedData?.meta}
        pagination={pagination}
        isFilterOpen={isFilterOpen}
        handleSort={setSorting}
        handleFilter={setColumnFilters}
        handlePaginationChange={setPagination}
      />

      <AddServicePopup open={isPopupOpen} onClose={handleClosePopup} />
      <OrderColumnsConfigurationPopup />
    </>
  );
};

export default HomePage;
