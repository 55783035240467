/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useRef } from 'react';
import { ColumnBase, ExtendedColumnDef } from 'src/types/table';
import { OrderListItemResponse } from 'src/api';
import { ReactElement } from 'react';
import Icon from '@mdi/react';
import { mdiDrag } from '@mdi/js';
import Checkbox from 'src/components/fields/Checkbox';
import { noop } from 'src/utils/commonUtils';
import { useDrag, useDrop } from 'react-dnd';
import clsx from 'clsx';

export type OrderColumnConfigurationRowProps = {
  className?: string;
  column: ExtendedColumnDef<ColumnBase>;
  onDrop: (fromId: string, toId: string) => void;
  onClick: () => void;
  id: string;
  visible: boolean;
};

export const OrderColumnDndItem = Symbol('OrderColumnDndItem');

const OrderColumnConfigurationRow: FC<OrderColumnConfigurationRowProps> = ({ column, onDrop, onClick, visible }) => {
  const [{ isDragging }, drag] = useDrag({
    type: OrderColumnDndItem,
    item: { type: OrderColumnDndItem, id: column.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [{ isOver }, drop] = useDrop({
    accept: OrderColumnDndItem,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item: ExtendedColumnDef<OrderListItemResponse>) => {
      onDrop(item.id as string, column.id as string);
    },
  });

  const ref = useRef(null);

  drag(drop(ref));

  return (
    <div
      className={clsx(
        'w-full pb-1 bg-gray-50',
        isOver
          ? 'first-of-type:border-t-primary first-of-type:border-b-transparent border-b-primary border-t-transparent border-b-2 border-t-2'
          : 'border-transparent border-t-2 border-b-2',
      )}
    >
      <div
        ref={ref}
        className={clsx(
          'bg-gray-50 w-full flex justify-between items-center rounded-lg border border-primary px-2 py-1',
          isDragging && 'opacity-50',
        )}
      >
        <div className='flex items-center'>
          <Checkbox value={visible} onChange={onClick} name={column.id as string} className='mr-2' />
          <span>{typeof column.header === 'function' ? (column.header as () => ReactElement)?.() : column.header}</span>
        </div>
        <Icon path={mdiDrag} size={1.25} className={'text-primary'} />
      </div>
    </div>
  );
};

OrderColumnConfigurationRow.displayName = 'OrderColumnConfigurationRow';

export default OrderColumnConfigurationRow;
