import { FC } from 'react';
import ColorAvatar from '../ColorAvatar';
import clsx from 'clsx';

export type CustomerAvatarProps = {
  name: string;
  className?: string;
  hideName?: boolean;
};

const CustomerAvatar: FC<CustomerAvatarProps> = ({ name, className, hideName }) => {
  return (
    <div className={clsx('flex items-center gap-2', className)}>
      <ColorAvatar name={name} className='rounded' />
      {!hideName && <span>{name}</span>}
    </div>
  );
};

export default CustomerAvatar;
