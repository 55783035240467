import clsx from 'clsx';
import { any } from 'ramda';
import { ChangeEvent, FC, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Button from '../Button';

export type ImagesUploadFieldProps = {
  value: File[] | null;
  name: string;
  addImagesOnChange?: boolean;
  onChange: (value: File[] | null) => void;
  multiple?: boolean;
  className?: string;
  buttonClassName?: string;
  disabled?: boolean;
};

const ImagesUploadField: FC<ImagesUploadFieldProps> = ({
  disabled,
  multiple,
  buttonClassName,
  addImagesOnChange,
  className,
  name,
  onChange,
  value,
}) => {
  const hiddenFileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = useCallback(() => hiddenFileInputRef?.current?.click(), []);
  const handleFilesChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!e.target.files) return; //TODO;
    if (e.target.files.length > 10) {
      toast(<FormattedMessage id='app.toasts.file_count' values={{ value: 10 }} />, { type: 'error' });
      return;
    }
    const files = Array.from(e.target.files);
    if (any((f) => f.size > 4194304, files)) {
      toast(<FormattedMessage id='app.toasts.file_size' values={{ value: '4mb' }} />, { type: 'error' });
      return;
    }

    const nextValue = addImagesOnChange ? [...(value ?? []), ...files] : files;

    onChange(nextValue);
  };

  return (
    <div className={clsx(className, 'flex flex-col gap-y-4')}>
      <Button type='button' disabled={disabled} className={buttonClassName} onClick={handleButtonClick}>
        {multiple && (value?.length ?? 0 > 0) ? (
          <FormattedMessage id='app.butons.add_photo' />
        ) : (
          <FormattedMessage id='app.butons.upload_photo' />
        )}

        <input
          ref={hiddenFileInputRef}
          name={name}
          accept='.png, .jpg, .jpeg'
          type='file'
          className='hidden'
          onChange={handleFilesChange}
          multiple={multiple}
          disabled={disabled}
        />
      </Button>
    </div>
  );
};

ImagesUploadField.displayName = 'ImagesUploadField';

export default ImagesUploadField;
