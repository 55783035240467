import React, { useEffect } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { CreateBranchRequest } from 'src/api';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import FormDataRow from 'src/components/FormDataRow';
import PhoneInputLocalized from 'src/components/fields/PhoneInputLocalized';
import Textfield from 'src/components/fields/Textfield';
import { BranchSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';
import SeparatedTextField from 'src/components/fields/MultiInput/SeparatedTextField';

interface BranchFormProps {
  defaultValues?: Partial<CreateBranchRequest>;
  onSubmit: SubmitHandler<CreateBranchRequest>;
  className?: string;
}

// const DEFAULT_VALUES: Partial<CreateBranchRequest> = {
//   phoneNumber: null,
// }

const BranchForm: React.FC<BranchFormProps> = ({ defaultValues, onSubmit }) => {
  const form = useForm<CreateBranchRequest>({
    resolver: zodResolver(BranchSchema),
    defaultValues: {
      ...defaultValues,
    },
  });

  const { handleSubmit, control, setValue } = form;

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof CreateBranchRequest, v));
  }, [defaultValues]);

  return (
    <div className='flex-col w-full'>
      <form className='flex flex-col w-full' onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <FormDataRow asterisk label={<FormattedMessage id='app.branch.name' defaultMessage='Název' />}>
            <Controller
              control={control}
              name='name'
              render={({ field, fieldState }) => <Textfield {...field} type='text' error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow label={<FormattedMessage id='app.common.branch.ico' defaultMessage='IČO pobočky' />}>
            <Controller
              control={control}
              name='vat'
              render={({ field, fieldState }) => <Textfield {...field} type='text' error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.common.city' defaultMessage='Město' />}>
            <Controller
              control={control}
              name='city'
              render={({ field, fieldState }) => <Textfield {...field} type='text' error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.common.street' defaultMessage='Ulice' />}>
            <Controller
              control={control}
              name='street'
              render={({ field, fieldState }) => <Textfield {...field} type='text' error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.common.zip' defaultMessage='PSČ' />}>
            <Controller
              control={control}
              name='zip'
              render={({ field, fieldState }) => <Textfield {...field} type='text' error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow label={<FormattedMessage id='app.common.phone' defaultMessage='Telefonní číslo' />}>
            <Controller
              control={control}
              name='phoneNumber'
              render={({ field, fieldState }) => (
                <PhoneInputLocalized {...field} value={field.value ?? ''} error={fieldState.error?.message} />
              )}
            />
          </FormDataRow>

          <Controller
            control={control}
            name='email'
            render={({ field: { value, name, onChange }, fieldState }) => (
              <SeparatedTextField
                name={name}
                label={<FormattedMessage id='app.common.email' defaultMessage='Email' />}
                value={value ?? ''}
                onChange={onChange}
                FormDataRowProps={{ labelWidthClass: 'w-48' }}
                separator=';'
                error={fieldState.error?.message}
              />
            )}
          />

          <FormDataRow label={<FormattedMessage id='app.branch.contact_person' defaultMessage='Kontaktní osoba' />}>
            <Controller
              control={control}
              name='contactPerson'
              render={({ field, fieldState }) => <Textfield {...field} type='text' error={fieldState.error?.message} />}
            />
          </FormDataRow>
          <NewItemPopupFooter />
        </FormProvider>
      </form>
    </div>
  );
};

export default BranchForm;
