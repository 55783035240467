import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useUpdateOrderTypeName from 'src/api/hooks/mutations/orderTypes/useUpdateOrderTypeName';
import Button from 'src/components/Button';
import Popup from 'src/components/Popup';
import { TextFieldControlled } from 'src/components/fields/Textfield';

export type UpdateOrderTypeNamePopupProps = {
  open: boolean;
  onClose: () => void;
  orderTypeId: number;
  name: string;
};

const UpdateOrderTypeNamePopup: FC<UpdateOrderTypeNamePopupProps> = ({ open, onClose, orderTypeId, name }) => {
  const updateMutation = useUpdateOrderTypeName();
  const [value, setValue] = useState(name);
  const handleSave = async (): Promise<void> => {
    try {
      await updateMutation.mutateAsync({ orderTypeId, name: value });
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Popup
      open={open}
      onClose={onClose}
      title={<FormattedMessage id='app.settings.order_type.update_name_popup.title' />}
    >
      <TextFieldControlled name='name' value={value} onChange={setValue} />
      <div className='mt-[2.875rem] flex justify-end gap-x-6 sm:mt-4'>
        <Button size='normal' type='button' variant='secondary' onClick={onClose}>
          <XMarkIcon className='h-8 w-8 mr-1 text-gray' aria-hidden='true' />
          <FormattedMessage id='app.buttons.close' defaultMessage='Zavřít' />
        </Button>
        <Button size='normal' onClick={handleSave}>
          <FormattedMessage id='app.buttons.save' defaultMessage='Uložit' />
        </Button>
      </div>
    </Popup>
  );
};

export default UpdateOrderTypeNamePopup;
