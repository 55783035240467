import clsx from 'clsx';
import { FC } from 'react';
import { DateInfo } from 'src/components/Calendar/types';

export type MiniDayProps = {
  dateInfo: DateInfo;
  onClick?: () => void;
  eventsCount?: number;
};

const MiniDay: FC<MiniDayProps> = ({ dateInfo, onClick }) => {
  const { day, date, isSelected, isSelectedMonth, isToday } = dateInfo;

  return (
    <button
      onClick={onClick}
      type='button'
      className={clsx(
        'relative h-7 w-10 focus:z-10 flex justify-center items-center',
        isSelectedMonth ? 'bg-white hover:bg-gray-70' : 'bg-gray-70 hover:bg-gray-100',
        isToday && 'text-primary',
        !isSelected && isSelectedMonth && !isToday && 'text-gray-700',
        !isSelected && !isSelectedMonth && !isToday && 'text-gray-500',
      )}
    >
      <time dateTime={date.toISOString()} className={clsx('text-[.65rem] font-semibold')}>
        {day}
      </time>
      {isSelected && <div className='absolute inset-0 border border-primary'></div>}
    </button>
  );
};

MiniDay.displayName = 'MiniDay';

export default MiniDay;
