import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, Fragment, useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import withController, { WithControllerProps } from 'src/hoc/withController';
import { FieldProps } from 'src/types/forms';
import HelperText from '../../common/HelperText';

// TODO: add forwardRefs

export type SelectBaseProps<TOption, TValue> = {
  className?: string;
  disabled?: boolean;
  value: TValue;
  onChange: (value: TValue) => void;
  options: readonly TOption[];
  visibleOptions?: readonly TOption[];
  getOptionLabelClassname?: (option: TOption) => string;
  getOptionLabel: (option: TOption) => string;
  getOptionValue: (option: TOption) => TValue;
  hideErrorMessage?: boolean;
  error?: string;
  selected?: TOption;
  name: string;
};

export type SelectProps<TOption, TValue> = SelectBaseProps<TOption, TValue> &
  FieldProps<TValue> & {
    shouldHideOption?: (option: TOption) => boolean;
  };

const SelectBaseInner = (<TOption, TValue>(
  {
    className,
    value,
    selected,
    disabled,
    onChange,
    options,
    getOptionValue,
    getOptionLabel,
    getOptionLabelClassname,
    hideErrorMessage,
    error,
  }: SelectBaseProps<TOption, TValue>,
  ref?: ForwardedRef<HTMLDivElement>,
): JSX.Element => {
  return (
    <div ref={ref} className={clsx(className, 'flex flex-col relative w-full')}>
      <Listbox value={value} onChange={onChange} disabled={disabled}>
        <Listbox.Button
          className={clsx(
            'relative w-full h-10 cursor-pointer rounded border border-gray-300 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm',
            disabled ? 'cursor-not-allowed bg-gray-300' : 'bg-white',
          )}
        >
          <span className='block truncate'>{selected ? getOptionLabel(selected) : ''}</span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
            <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
          </span>
        </Listbox.Button>
        <Transition as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <Listbox.Options className='bg-white absolute z-[3000] mt-1 max-h-60 w-full overflow-auto rounded py-1 text-base shadow-lg ring-1 ring-gray-1000 ring-opacity-5 focus:outline-none sm:text-sm'>
            {options.map((option, i) => (
              <Listbox.Option
                key={i}
                className={({ active }) =>
                  clsx(
                    `relative cursor-default select-none py-2 pl-10 pr-4 w-full${
                      active ? 'bg-gray-100 cursor-pointer text-gray-900' : 'text-gray-900'
                    }`,
                    getOptionLabelClassname?.(option),
                  )
                }
                value={getOptionValue(option)}
              >
                {({ selected }) => (
                  <>
                    <span className={clsx(`block truncate`, selected ? 'font-medium' : 'font-normal')}>
                      {getOptionLabel(option)}
                    </span>
                    {selected ? (
                      <span className='absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600'>
                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
      {!hideErrorMessage && error && <HelperText variant='error'>{error}</HelperText>}
    </div>
  );
}) as <TOption, TValue>(
  props: SelectBaseProps<TOption, TValue> & {
    ref?: ForwardedRef<HTMLElement>;
  },
) => JSX.Element;

export const SelectBase = forwardRef(SelectBaseInner) as typeof SelectBaseInner;

const SelectInner = <TOption, TValue>(
  { options, shouldHideOption, ...rest }: SelectProps<TOption, TValue>,
  ref?: ForwardedRef<HTMLElement>,
): JSX.Element => {
  const { getOptionValue, value } = rest;
  const selected = options.find((i) => getOptionValue(i) === value);
  const visibleOptions = useMemo(
    () => (shouldHideOption ? options.filter((i) => !shouldHideOption(i)) : options),
    [options, shouldHideOption],
  );

  return <SelectBase {...rest} ref={ref} selected={selected} options={visibleOptions} />;
};

const Select = forwardRef(SelectInner) as typeof SelectInner;

export const SelectRhfc = withController(Select) as <
  TOption,
  TValue,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: Omit<SelectProps<TOption, TValue>, 'value' | 'onChange'> & WithControllerProps<TFieldValues, TName>,
) => JSX.Element;

export default Select;
