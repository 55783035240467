import clsx from 'clsx';
import { range } from 'ramda';
import { CSSProperties, FC } from 'react';
import useEventsGridSettings from '../../hooks/useEventsGridSettings';

export type VerticalLinesProps = {
  className?: string;
  style?: CSSProperties;
};

const VerticalLines: FC<VerticalLinesProps> = ({ className, style }) => {
  const { COLS } = useEventsGridSettings();
  const days = range(1, COLS + 1);

  return (
    <div
      className={clsx('col-start-1 col-end-2 row-start-1 grid-rows-1 divide-x divide-gray-100 grid', className)}
      style={{
        gridTemplateColumns: `repeat(${COLS}, minmax(0, 1fr))`,
        ...style,
      }}
    >
      {days.map((i) => (
        <div key={i} className='row-span-full' style={{ gridColumnStart: i }} />
      ))}
      <div
        className='row-span-full w-8'
        style={{
          gridColumnStart: COLS + 1,
        }}
      />
    </div>
  );
};

VerticalLines.displayName = 'VerticalLines';

export default VerticalLines;
