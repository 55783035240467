import { FC, ReactNode, useState } from 'react';

export type TooltipProps = {
  text: ReactNode;
  children: React.ReactNode;
};

const Tooltip: FC<TooltipProps> = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className='relative'>
      <div onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
        {children}
      </div>
      {showTooltip && (
        <div className='absolute z-[99998] max-w-[40rem] w-fit py-1 px-2 bg-black text-white text-sm rounded-md bottom-full left-1/2 -translate-x-1/2'>
          {text}
        </div>
      )}
    </div>
  );
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
