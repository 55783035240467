import clsx from 'clsx';
import { useEffect } from 'react';
import { ChangeEvent, FocusEvent, forwardRef, InputHTMLAttributes, useCallback, useState } from 'react';
import HelperText from 'src/components/common/HelperText';
import withController from 'src/hoc/withController';
import { FieldProps } from 'src/types/forms';

export type NumberFieldBaseProps = InputHTMLAttributes<HTMLInputElement> & {
  hideErrorMessage?: boolean;
  inputAdornment?: React.ReactNode;
  error?: string;
};

export type NumberFieldProps = Omit<NumberFieldBaseProps, 'value'> & FieldProps<number | null>;

const NUMBER_PATTERN = '^-?[0-9]\\d*\\.?\\d*$';
const NUMBER_REGEX = new RegExp(NUMBER_PATTERN);

export const NumberFieldBase = forwardRef<HTMLInputElement, NumberFieldBaseProps>(
  ({ className, inputAdornment, disabled, error, onChange, hideErrorMessage, value, ...rest }, ref) => {
    return (
      <div className={clsx('flex-col w-full relative', className)}>
        <input
          className={clsx(
            'w-full bg-white border border-gray-300 rounded font-openSans font-normal text-base px-2 h-10',
            inputAdornment && 'pl-8',
            !disabled && error && 'border-error',
            disabled && 'cursor-not-allowed bg-gray-100',
          )}
          {...rest}
          onChange={onChange}
          type={'tel'}
          value={value ?? ''}
          pattern='^-?[0-9]\d*\.?\d*$'
          ref={ref}
        />
        {inputAdornment && (
          <div className='absolute top-5 left-2 translate-y-[-50%] max-w-5 max-h-5'>{inputAdornment}</div>
        )}
        {!disabled && !hideErrorMessage && error && typeof error === 'string' && (
          <HelperText variant='error'>{error}</HelperText>
        )}
      </div>
    );
  },
);

const valueToVisible = (value: number | null): string => {
  if (value === null || value === undefined) return '';
  return value.toString();
};

export const NumberField = forwardRef<HTMLInputElement, NumberFieldProps>((props, ref) => {
  const { onChange, onBlur, ...rest } = props;
  const [visibleValue, setVisibleValue] = useState<string | undefined>(props.value?.toString() ?? '0');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const targetValue = e.target.value.replace(',', '.');

      if (targetValue === '') {
        setVisibleValue('');
        onChange(0);
      }

      if (!NUMBER_REGEX.test(targetValue)) {
        e.preventDefault();
        return;
      }

      if (targetValue.endsWith('.')) {
        setVisibleValue(targetValue);
        return;
      }

      const numberValue = parseFloat(targetValue);
      const visible = targetValue.endsWith('.') || targetValue.endsWith('0') ? targetValue : numberValue.toString();
      setVisibleValue(visible);
      onChange?.(numberValue);
    },
    [onChange],
  );

  // ugly solution to handle external value change
  useEffect(() => {
    setVisibleValue(valueToVisible(props.value));
  }, [props.value]);

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const targetValue = e.target.value;

      if (targetValue.endsWith('.') || targetValue.endsWith('0')) {
        setVisibleValue(parseFloat(targetValue).toString());
      }

      if (targetValue === '') {
        setVisibleValue('0');
      }

      onBlur?.(e);
    },
    [onBlur],
  );

  return <NumberFieldBase {...rest} onBlur={handleBlur} value={visibleValue} onChange={handleChange} ref={ref} />;
});

NumberFieldBase.displayName = 'NumberFieldBase';

export const NumberFieldRhfc = withController<NumberFieldProps>(NumberField);

export default NumberField;
