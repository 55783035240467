import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';
import Button from 'src/components/Button';
import PageContentHeader from 'src/components/PageContentHeader';
import queryKeysFactory from 'src/store/queryKeysFactory';
import CalendarTabs from './components/CalendarTabs';

export type CalendarPageProps = {
  //
};

const CalendarPage: FC<CalendarPageProps> = () => {
  const queryClient = useQueryClient();
  const handleRefresh = useCallback(() => {
    queryClient.invalidateQueries(queryKeysFactory.orders._def);
    queryClient.invalidateQueries(queryKeysFactory.technicianOrder._def);
    queryClient.invalidateQueries(queryKeysFactory.allTechniciansWeekOrders._def);
    queryClient.invalidateQueries(queryKeysFactory.technicianMonthOrders._def);
  }, [queryClient]);

  return (
    <div className={clsx()}>
      <PageContentHeader
        className='mb-5'
        title={<FormattedMessage id='app.calendar_page.title' />}
        hidePrimaryButton
        hideSecondaryButton
      />
      <div className='flex items-center gap-x-3'>
        <CalendarTabs />
        <Button onClick={handleRefresh} variant='cta' size='small' className='h-10 w-10 rounded-full hover:bg-gray-100'>
          <ArrowPathIcon className='w-6' />
        </Button>
      </div>

      <Outlet />
    </div>
  );
};

CalendarPage.displayName = 'CalendarPage';

export default CalendarPage;
