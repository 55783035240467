import { CheckIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContactListResponse, OrderStatusResponse, PaymentMethodResponse, useApiClient, UserResponse } from 'src/api';
import useContactBranches from 'src/api/hooks/queries/useContactBranches';
import Button from 'src/components/Button';
import Tooltip from 'src/components/common/Tooltip';
import AutocompleteBe from 'src/components/fields/AutocompleteBe';
import { CheckboxGroupRhfc } from 'src/components/fields/CheckboxGroup';
import { NumberFieldRhfc } from 'src/components/fields/NumberField';
import { SelectRhfc } from 'src/components/fields/Select';
import { MessageId } from 'src/types/commonTypes';
import { ServiceFilter } from 'src/types/filters';
import { OrderToExcelFiltersSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';

export type OrderToExcelFiltersFormProps = {
  className?: string;
  statuses: OrderStatusResponse[];
  technicians: UserResponse[];
  paymentMethods: PaymentMethodResponse[];
  onSubmit?: (data: OrderToExcelFiltersSchema) => void;
};

const defaultValues: ServiceFilter = {
  statusProp: [] as string[],
  paymentMethods: [] as string[],
  companyProp: '',
  datetime: '',
  orderNumber: '',
  technicianIds: [] as number[],
  technicianProp: '',
  companyId: null,
  branchId: null,
  productId: null,
  minPrice: null,
  maxPrice: null,
  minRealStart: null,
  minRealEnd: null,
};

const OrderToExcelFiltersForm: FC<OrderToExcelFiltersFormProps> = ({
  className,
  paymentMethods,
  statuses,
  technicians,
  onSubmit,
}) => {
  const intl = useIntl();
  const apiClient = useApiClient();

  const form = useForm<OrderToExcelFiltersSchema>({
    defaultValues: defaultValues as unknown as OrderToExcelFiltersSchema,
    resolver: zodResolver(OrderToExcelFiltersSchema),
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    trigger,
    formState: { isValid },
  } = form;

  const companyId = watch('companyId');

  const { data: branches } = useContactBranches(companyId ?? 0, {
    enabled: !!companyId,
  });

  const getContacts = async (query: string): Promise<ContactListResponse[]> => {
    const data = await apiClient.contact.getContactList({
      filterString: query,
      category: null,
      pageSize: 20,
      pageIndex: 0,
    });
    return data.items;
  };

  const handleToggleCheckAllStatuses = useCallback(() => {
    const statusProp = getValues('statusProp') as string[];
    setValue('statusProp', statusProp.length > 0 ? [] : statuses.map((status) => status.name));
    trigger('statusProp');
  }, []);

  return (
    <FormProvider {...form}>
      <form
        className={clsx(className, 'flex flex-col')}
        onSubmit={handleSubmit((data) =>
          onSubmit?.({
            ...data,
          }),
        )}
      >
        <div className='flex flex-col mb-4'>
          <div className='flex items-center gap-x-2 mb-4'>
            <span className='text-xl font-semibold'>
              <FormattedMessage id='app.order.status' />
            </span>
            <CheckIcon className='w-6 h-6 text-primary cursor-pointer' onClick={handleToggleCheckAllStatuses} />
          </div>

          <CheckboxGroupRhfc
            className='mb-8'
            asRow
            control={control}
            name='statusProp'
            options={(statuses ?? []).map((status) => ({
              label: intl.formatMessage({
                id: `app.order_status.${status.name?.toLowerCase() as Lowercase<OrderStatusResponse.name>}`,
              }),
              value: status.name,
              name: status.name,
            }))}
          />
        </div>

        <div className='flex flex-col mb-4'>
          <div className='flex items-center gap-x-2 mb-4'>
            <span className='text-xl font-semibold'>
              <FormattedMessage id='app.finish_order.payment_method' />
            </span>
          </div>

          <CheckboxGroupRhfc
            className='mb-8'
            asRow
            control={control}
            name='paymentMethods'
            options={(paymentMethods ?? []).map((paymentMethods) => ({
              label: intl.formatMessage({
                id: `app.payment_method.${paymentMethods.name?.toLowerCase()}` as MessageId,
              }),
              value: paymentMethods.name,
              name: paymentMethods.name,
            }))}
          />
        </div>

        <div className='flex flex-col mb-4'>
          <div className='flex items-center gap-x-2 mb-4'>
            <span className='text-xl font-semibold'>
              <FormattedMessage id='app.order.technicians' />
            </span>
            <Tooltip
              text={
                <span className='inline-block max-w-sm w-max'>
                  <FormattedMessage id='app.export.orders.technicians.tooltip' />
                </span>
              }
            >
              <InformationCircleIcon className='w-6' />
            </Tooltip>
          </div>

          <CheckboxGroupRhfc
            className='mb-8'
            asRow
            control={control}
            name='technicianIds'
            options={(technicians ?? []).map((technician) => ({
              label: technician.profile?.name ?? technician.id.toString(),
              value: technician.id,
              name: technician.id.toString(),
            }))}
          />
        </div>

        <div className='flex flex-col mb-4'>
          <span className='text-xl font-semibold mb-4'>
            <FormattedMessage id='app.order.company_name' />
          </span>
          <Controller
            control={control}
            name='companyId'
            render={({ field: { onChange, value, ...rest } }) => (
              <AutocompleteBe
                {...rest}
                value={value as number | null}
                onChange={(v: number | null) => {
                  onChange(v);
                  setValue('branchId', null);
                }}
                getOptions={getContacts}
                getOptionLabel={(opt) => `${opt.companyName}${opt.vat ? ` - ${opt.vat}` : ''}`}
                getOptionValue={(opt) => opt.id}
              />
            )}
          />
        </div>

        <div className='flex flex-col mb-4'>
          <span className='text-xl font-semibold mb-4'>
            <FormattedMessage id='app.order.real_start_time' />
          </span>
          <div className='flex flex-wrap gap-x-6 gap-y-4'>
            <div className='flex items-center gap-x-1'>
              <FormattedMessage id='app.common.from' />

              <Controller
                control={control}
                name='minRealStart'
                render={({ field }) => (
                  <input
                    type='date'
                    className='w-full h-10 rounded border border-gray-300 px-3'
                    {...field}
                    value={field.value ?? ''}
                  />
                )}
              />
            </div>
            <div className='flex  items-center gap-x-1'>
              <FormattedMessage id='app.common.to' />

              <Controller
                control={control}
                name='minRealEnd'
                render={({ field }) => (
                  <input
                    type='date'
                    className='w-full h-10 rounded border border-gray-300 px-3'
                    {...field}
                    value={field.value ?? ''}
                  />
                )}
              />
            </div>
          </div>
        </div>

        <div className='flex flex-col mb-4'>
          <span className='text-xl font-semibold mb-4'>
            <FormattedMessage id='app.order.branch' />
          </span>
          <SelectRhfc
            control={control}
            disabled={!companyId}
            name='branchId'
            options={branches ?? []}
            getOptionLabel={(option) => option.name ?? ''}
            getOptionValue={(option) => option.id}
            extraOnChange={() => {
              //
            }}
          />
        </div>

        <div className='flex flex-col mb-4'>
          <span className='text-xl font-semibold mb-4'>
            <FormattedMessage id='app.export.orders.min_price' />
          </span>
          <NumberFieldRhfc control={control} name='minPrice' className='max-w-xs' />
        </div>

        <div className='flex flex-col mb-4'>
          <span className='text-xl font-semibold mb-4'>
            <FormattedMessage id='app.export.orders.max_price' />
          </span>
          <NumberFieldRhfc control={control} name='maxPrice' className='max-w-xs' />
        </div>

        <Button type='submit' className='mt-8 max-w-xs' variant='primary' disabled={!isValid}>
          <FormattedMessage id='app.export.orders.custom_export.download_button' />
        </Button>
      </form>
    </FormProvider>
  );
};

OrderToExcelFiltersForm.displayName = 'OrderToExcelFiltersForm';

export default OrderToExcelFiltersForm;
