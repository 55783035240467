import { FC, HTMLAttributes } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { UserResponse } from 'src/api';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';
import useCurrentUserUpcomingOrders from 'src/api/hooks/queries/useCurrentUserUpcomingOrders';
import PageContentHeader from 'src/components/PageContentHeader';
import UpcomingOrdersList from './components/UpcomingOrdersList';

export type TechnicianAccountPageProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const TechnicianAccountPage: FC<TechnicianAccountPageProps> = () => {
  const { data: orders } = useCurrentUserUpcomingOrders();
  const { data: currentUser } = useCurrentUser();

  return (
    <>
      <PageContentHeader
        className='mb-5'
        title={<FormattedMessage id='app.technician_account.upcoming_orders.title' />}
        hidePrimaryButton
        hideSecondaryButton
      />
      {(orders?.length ?? 0) > 0 ? (
        <UpcomingOrdersList orders={orders ?? []} className='mb-5' />
      ) : (
        <h2 className='mb-4 text-md'>
          <FormattedMessage id='app.technician_account.no_upcoming orders' />
        </h2>
      )}
      {![
        UserResponse.role.TECHNICIAN,
        UserResponse.role.READ_ONLY_TECHNICIAN,
        UserResponse.role.ADMINISTRATOR_TECHNICIAN,
        UserResponse.role.DISPATCHER_TECHNICIAN,
      ].includes(currentUser?.role as UserResponse.role) && (
        <h2 className='text-error mb-8'>
          <FormattedMessage id='app.technician_account.you_are_not_technician' />
        </h2>
      )}
      <Outlet />
    </>
  );
};

TechnicianAccountPage.displayName = 'TechnicianAccountPage';

export default TechnicianAccountPage;
