import { FC, useEffect, useState } from 'react';
import Popup from 'src/components/Popup';
import AddServiceForm from '../AddServiceForm';
import {
  VatTariffResponse,
  HeadquartersResponse,
  OrderTypeListItemRepsonse,
  TenantConfigDto,
  NotificationResponse,
} from 'src/api';
import { OrderSchema } from '../ServiceForm/schema';
import clsx from 'clsx';
import AllTechniciansCalendar from 'src/pages/CalendarPage/components/AllTechniciansCalendar';
import MobileServicePopupTitle, { MobileServicePopupMode } from '../MobileServicePopupTitle/MobileServicePopupTitle';
import { FormattedMessage } from 'react-intl';
import { GridEvent } from 'src/components/EventsGrid/types';
import { getDefaultPotentialEvents } from './helpers';

export type MobileAddServicePopupProps = {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  defaultValues?: Partial<OrderSchema>;
  vatTariffs: VatTariffResponse[];
  headquarters: HeadquartersResponse[];
  orderTypes: OrderTypeListItemRepsonse[];
  config?: TenantConfigDto;
  notificationSettings?: NotificationResponse[];
};

const MobileAddServicePopup: FC<MobileAddServicePopupProps> = ({
  open,
  onClose,
  isLoading,
  config,
  orderTypes,
  defaultValues,
  vatTariffs,
  headquarters,
  notificationSettings,
}) => {
  const [potentialEvents, setPotentialEvents] = useState<GridEvent[]>([]);
  const [mode, setMode] = useState<MobileServicePopupMode>(MobileServicePopupMode.form);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const handleClose = (): void => {
    setSelectedDate(undefined);
    setPotentialEvents([]);
    setMode(MobileServicePopupMode.form);
    onClose();
  };

  // TODO: recompose to have state inside of unmountable comp
  useEffect(() => {
    setPotentialEvents(getDefaultPotentialEvents(defaultValues));
    setSelectedDate(defaultValues?._date ?? undefined);
  }, [defaultValues]);

  return (
    <Popup
      stickTop
      replaceTitleContainer
      title={
        <MobileServicePopupTitle
          title={<FormattedMessage id='app.order.add_order.popup_title' />}
          mode={mode}
          onModeChange={setMode}
        />
      }
      open={open && !isLoading}
      onClose={handleClose}
    >
      <AddServiceForm
        className={clsx(mode === MobileServicePopupMode.calendar && 'hidden')}
        onPotentialEventsChange={setPotentialEvents}
        onDateChange={setSelectedDate}
        defaultValues={defaultValues}
        config={config}
        orderTypes={orderTypes}
        vatTariffs={vatTariffs}
        headquarters={headquarters}
        notificationSettings={notificationSettings}
      />

      <AllTechniciansCalendar
        className={clsx(mode === MobileServicePopupMode.form && 'hidden')}
        calendarOptions={{
          defaultFocusedDate: selectedDate,
        }}
        potentialEvents={potentialEvents}
        disableTechnicianPreviewOnClick
        disableEventPreviewOnClick
        disableDnd
        disableGridClick
      />
    </Popup>
  );
};

export default MobileAddServicePopup;
