/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMaterialRequest } from '../models/CreateMaterialRequest';
import type { MaterialResponse } from '../models/MaterialResponse';
import type { MaterialsPageResponse } from '../models/MaterialsPageResponse';
import type { UpdateMaterialRequest } from '../models/UpdateMaterialRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MaterialService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns MaterialsPageResponse
   * @throws ApiError
   */
  public getMaterialList({
    pageSize,
    pageIndex,
    name,
    price,
    code,
    supplierCode,
    withDeleted,
    direction,
    orderBy,
  }: {
    pageSize: number;
    pageIndex: number;
    /**
     * Material name
     */
    name?: string;
    /**
     * Material price
     */
    price?: string;
    /**
     * Material code
     */
    code?: string;
    /**
     * Material code
     */
    supplierCode?: string;
    /**
     * With deleted
     */
    withDeleted?: boolean;
    direction?: 'ASC' | 'DESC';
    orderBy?: string;
  }): CancelablePromise<MaterialsPageResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/material',
      query: {
        name: name,
        price: price,
        code: code,
        supplier_code: supplierCode,
        withDeleted: withDeleted,
        direction: direction,
        orderBy: orderBy,
        pageSize: pageSize,
        pageIndex: pageIndex,
      },
    });
  }
  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public createMaterial({ requestBody }: { requestBody: CreateMaterialRequest }): CancelablePromise<MaterialResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/material',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public searchMaterials({
    query,
    limit,
  }: {
    query: string;
    limit?: number | null;
  }): CancelablePromise<Array<MaterialResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/material/search',
      query: {
        query: query,
        limit: limit,
      },
    });
  }
  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public getAllMaterials(): CancelablePromise<Array<MaterialResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/material/all',
    });
  }
  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public getMaterial({ id }: { id: number }): CancelablePromise<MaterialResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/material/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public updateMaterial({
    id,
    requestBody,
  }: {
    id: number;
    requestBody: UpdateMaterialRequest;
  }): CancelablePromise<MaterialResponse> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/material/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public deleteMaterial({ id }: { id: number }): CancelablePromise<MaterialResponse> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/material/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public createMaterialList({ requestBody }: { requestBody: Array<CreateMaterialRequest> }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/material/bulk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns MaterialResponse
   * @throws ApiError
   */
  public restoreMaterial({ id }: { id: number }): CancelablePromise<MaterialResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/material/restore/{id}',
      path: {
        id: id,
      },
    });
  }
}
