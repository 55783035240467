import { FunnelIcon } from '@heroicons/react/24/outline';
import { flexRender, Header } from '@tanstack/react-table';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { ExtendedColumnDef } from 'src/types/table';
import ColumnSort from '../ColumnSort';

export type TableHeadingProps<T> = {
  header: Header<T, unknown>;
  isFilterOpen?: boolean;
  disableSorting?: boolean;
  overrideHeadingFontClassname?: string;
};

const TableHeading = <T extends { id: number }>({
  header,
  isFilterOpen,
  disableSorting,
  overrideHeadingFontClassname,
}: PropsWithChildren<TableHeadingProps<T>>): JSX.Element => {
  const hasRenderFilter = Boolean((header.column.columnDef as ExtendedColumnDef<T>).renderFilter);
  const shouldShowFilter = Boolean(isFilterOpen && hasRenderFilter);
  const canSort = !disableSorting && header.column.getCanSort();
  const filterValue = header.column.getFilterValue();

  return (
    <th
      key={header.id}
      className={clsx(
        'text-left py-3 px-3',
        overrideHeadingFontClassname ?? 'text-gray-600',
        isFilterOpen && 'align-top',
      )}
      style={{ minWidth: header.getSize(), maxWidth: header.column.columnDef.maxSize }}
    >
      {header.isPlaceholder ? null : (
        <div className={clsx('flex flex-col h-full', isFilterOpen && !hasRenderFilter && 'pb-[40px]')}>
          <div
            className={clsx(
              'group flex items-center gap-x-2 select-none pb-3 font-medium',
              canSort && 'cursor-pointer',
            )}
            onClick={canSort ? header.column.getToggleSortingHandler() : () => undefined}
          >
            <>{filterValue && <FunnelIcon className='h-5 w-5 text-gray-400' />}</>
            <>{flexRender(header.column.columnDef.header, header.getContext())}</>
            <>{canSort && <ColumnSort column={header.column} />}</>
          </div>
          {shouldShowFilter && (header.column.columnDef as ExtendedColumnDef<T>).renderFilter?.(header.column)}
        </div>
      )}
    </th>
  );
};

export default TableHeading;
