import { useOutletContext } from 'react-router-dom';
import { UseCalendarReturn } from 'src/components/Calendar/hook';

export type UseCalendarOutletContextReturn = {
  calendar: UseCalendarReturn;
  technicianId: number;
  disableDrag?: boolean;
};

const useTechnicianCalendarOutletContext = (): UseCalendarOutletContextReturn =>
  useOutletContext<UseCalendarOutletContextReturn>();

export default useTechnicianCalendarOutletContext;
