import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FinishOrderRequest } from 'src/api';
import { NumberFieldRhfc } from 'src/components/fields/NumberField';
import { SelectRhfc } from 'src/components/fields/Select';
import FieldWrapper from '../../FieldWrapper';
import Section from '../../Section';
import SectionTitle from '../../SectionTitle';
import { useFormContext } from 'react-hook-form';
import { FinishOrderFormValues } from '../schema';

export type MaterialsSaleFormProps = {
  //
};

const MaterialsSaleForm: FC<MaterialsSaleFormProps> = () => {
  const intl = useIntl();
  const { control } = useFormContext<FinishOrderFormValues>();

  return (
    <>
      <SectionTitle className='mb-4'>
        <FormattedMessage id='app.finish_order.section_title.material_sale' />
      </SectionTitle>
      <Section>
        <FieldWrapper label={<FormattedMessage id='app.finish_order.sale_type' />}>
          <SelectRhfc
            control={control}
            name='materialSaleType'
            options={Object.values(FinishOrderRequest.deliverySaleType)}
            getOptionLabel={(option) =>
              intl.formatMessage({
                id: `app.finish_order.sale_type.${option.toLowerCase() as Lowercase<typeof option>}`,
              })
            }
            getOptionValue={(option) => option}
          />
        </FieldWrapper>

        <FieldWrapper label={<FormattedMessage id='app.finish_order.sale_value' />}>
          <NumberFieldRhfc control={control} name='materialSaleValue' />
        </FieldWrapper>
      </Section>
    </>
  );
};

export default MaterialsSaleForm;
