import { ChangeEvent, FC, KeyboardEventHandler, ReactNode, useCallback } from 'react';
import clsx from 'clsx';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../Button';
import Textfield from '../fields/Textfield';
import { OnChangeFn } from '@tanstack/react-table';

export type SearchBlockProps = {
  className?: string;
  searchValue: string;
  setSearchValue: OnChangeFn<string>;
  handleSearch: () => void;
  helperText?: ReactNode;
  title?: ReactNode;
  description?: ReactNode;
};

const SearchBlock: FC<SearchBlockProps> = ({
  className,
  searchValue,
  setSearchValue,
  handleSearch,
  helperText,
  title,
  description,
}) => {
  const intl = useIntl();

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === 'Enter') handleSearch();
    },
    [handleSearch],
  );
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value),
    [setSearchValue],
  );

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <h2 className='text-2xl font-openSans font-semibold mt-16 mb-4'>
        {title ?? <FormattedMessage id='app.contact_page.search.title' />}
      </h2>
      {description && <span className='block mb-2'>{description}</span>}
      {helperText}
      <div className='flex max-w-sm items-center gap-x-6 mt-3 sm:min-w-full'>
        <Textfield
          name='search'
          value={searchValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={intl.formatMessage({
            id: 'app.contact_page.search.placeholder',
            defaultMessage: 'společnost',
          })}
          inputAdornment={<MagnifyingGlassIcon className='w-5 h-5' />}
        />
        <Button onClick={handleSearch}>
          <FormattedMessage id='app.contact_page.search.button' defaultMessage='Vyhledat' />
        </Button>
      </div>
    </div>
  );
};

SearchBlock.displayName = 'SearchBlock';

export default SearchBlock;
