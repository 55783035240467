import { FC } from 'react';
import { CreateOrderTypeRequest } from 'src/api';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderTypeSchema } from './schema';
import { FormattedMessage } from 'react-intl';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import FormDataRow from 'src/components/FormDataRow';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Tooltip from 'src/components/common/Tooltip';
import { zodResolver } from '@hookform/resolvers/zod';

export type OrderTypeFormProps = {
  defaultValues?: Partial<CreateOrderTypeRequest>;
  onSubmit: (data: CreateOrderTypeRequest) => void;
  className?: string;
};

const OrderTypeForm: FC<OrderTypeFormProps> = ({ defaultValues, onSubmit }) => {
  const form = useForm<CreateOrderTypeRequest>({
    defaultValues,
    resolver: zodResolver(OrderTypeSchema),
  });

  const { handleSubmit, control } = form;

  return (
    <FormProvider {...form}>
      <div className='flex-col w-full'>
        <form className='flex flex-col w-full' onSubmit={handleSubmit(onSubmit)}>
          <FormDataRow label={<FormattedMessage id='app.order_type.name' />}>
            <TextFieldRhfc control={control} name='name' />
          </FormDataRow>
          <FormDataRow
            labelWidthClass='w-48'
            label={
              <div className='flex'>
                <FormattedMessage id='app.tenant.order_number_format' />
                <Tooltip
                  text={
                    <span className='inline-block max-w-sm w-max'>
                      <FormattedMessage id='app.tenants_page.tooltip.order_number_format' />
                    </span>
                  }
                >
                  <InformationCircleIcon className='w-6' />
                </Tooltip>
              </div>
            }
          >
            <TextFieldRhfc control={control} name='orderNumberFormat' />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.order_type.slConfig' />}>
            <TextFieldRhfc control={control} name='slConfig' />
          </FormDataRow>
          <NewItemPopupFooter />
        </form>
      </div>
    </FormProvider>
  );
};

OrderTypeForm.displayName = 'OrderTypeForm';

export default OrderTypeForm;
