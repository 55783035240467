import clsx from 'clsx';
import { FC, HTMLAttributes, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { useApiClient } from 'src/api';
import PageContentHeader from 'src/components/PageContentHeader';
import WithLoader from 'src/components/utils/WithLoader';
import OrderTargetForm from './components/OrderTargetForm';

export type SystemAdministrationFeaturesPageProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const SystemAdministrationFeaturesPage: FC<SystemAdministrationFeaturesPageProps> = ({ className }) => {
  const apiClient = useApiClient();
  const [isLoading, setIsLoading] = useState(false);

  const handleResendSLMail = async (orderId: number): Promise<void> => {
    setIsLoading(true);
    await apiClient.orders.resendOrderServiceListMails({ orderId });
    setIsLoading(false);

    toast(<FormattedMessage id='app.toasts.service_list_sent' />, {
      type: 'success',
    });
  };

  const handleResendInvintatinoMail = async (orderId: number): Promise<void> => {
    setIsLoading(true);
    await apiClient.orders.resendOrderInvitation({ orderId });
    setIsLoading(false);

    toast(<FormattedMessage id='app.toasts.invitation_sent' />, {
      type: 'success',
    });
  };

  const handleRecreatePDF = async (orderId: number): Promise<void> => {
    setIsLoading(true);
    await apiClient.orders.recreateServiceList({ orderId });
    setIsLoading(false);

    toast(<FormattedMessage id='app.toasts.update_success' />, {
      type: 'success',
    });
  };

  return (
    <WithLoader isLoading={isLoading}>
      <PageContentHeader
        className='mb-12'
        hidePrimaryButton
        hideSecondaryButton
        title={<FormattedMessage id='app.features_page.title' />}
      />
      <div className={clsx(className, 'flex flex-col')}>
        <OrderTargetForm
          className='max-w-lg mb-20'
          title={<FormattedMessage id='app.features_page.resend_invintation' />}
          buttonText={<FormattedMessage id='app.buttons.send' />}
          onSubmit={handleResendInvintatinoMail}
        />
        <OrderTargetForm
          className='max-w-lg'
          title={<FormattedMessage id='app.features_page.resend_service_list' />}
          buttonText={<FormattedMessage id='app.buttons.send' />}
          onSubmit={handleResendSLMail}
        />
        <OrderTargetForm
          className='max-w-lg'
          title={<FormattedMessage id='app.features_page.recreate_service_list' />}
          buttonText={<FormattedMessage id='app.buttons.send' />}
          onSubmit={handleRecreatePDF}
        />
      </div>
    </WithLoader>
  );
};

SystemAdministrationFeaturesPage.displayName = 'SystemAdministrationFeaturesPage';

export default SystemAdministrationFeaturesPage;
