import clsx from 'clsx';
import { FC, memo } from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export type InputSkeletonProps = SkeletonProps & {
  //
};

const InputSkeleton: FC<InputSkeletonProps> = ({ className, containerClassName, ...rest }) => (
  <Skeleton
    containerClassName={clsx(containerClassName, 'h-10 w-full rounded')}
    className={clsx(className, 'h-full')}
    {...rest}
  />
);

export default memo(InputSkeleton);
