import { FC, HTMLAttributes, useState } from 'react';
import clsx from 'clsx';
import PageContentHeader from 'src/components/PageContentHeader';
import { FormattedMessage } from 'react-intl';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import useMaterial from 'src/api/hooks/queries/useMaterial';
import Loader from 'src/components/utils/Loader';
import BackIcon from 'src/components/Icons/BackIcon';
import MaterialPreview from '../MaterialPreview';
import Popup from 'src/components/Popup';
import UpdateMaterialForm from '../UpdateMaterialForm';
import Button from 'src/components/Button';
import { HeartIcon, TrashIcon } from '@heroicons/react/24/outline';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import DeleteMaterialPopup from '../DeleteMaterialPopup';
import useGetIsModuleValid from 'src/hooks/useGetIsModuleValid';
import { Modules } from 'src/types/commonTypes';
import RestoreMaterialPopup, { RESTORE_MATERIAL_POPUP_ID } from '../RestoreMaterialPopup';
import useIsCurrentUserAllowedToEdit from 'src/hooks/useIsCurrentUserAllowedToEdit';

export type MaterialDetailPageProps = {
  //
} & HTMLAttributes<HTMLDivElement>;

const MaterialDetailPage: FC<MaterialDetailPageProps> = ({ className }) => {
  const materialId = Number(useParams().materialId);
  const getIsModuleValid = useGetIsModuleValid();
  const isCurrentUserAllowedToEdit = useIsCurrentUserAllowedToEdit();
  const { isLoading, data } = useMaterial(materialId);
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const openDeletePopup = useOpenPopup('delete_material');
  const openRestorePopup = useOpenPopup(RESTORE_MATERIAL_POPUP_ID);

  if (!getIsModuleValid(Modules.ADMINISTRATION)) return <Navigate to='/' />;
  if (isLoading) return <Loader />;
  if (!data) return <Navigate to='/404' />;

  return (
    <>
      <div className={clsx(className, 'flex flex-col')}>
        <PageContentHeader
          className='mb-20'
          title={
            <span className='flex'>
              <FormattedMessage id='app.material_detail_page.title' />
              {!data.deletedAt ? (
                <Button variant='cta' size='small' onClick={openDeletePopup}>
                  <TrashIcon className='h-6' />
                </Button>
              ) : (
                <Button variant='cta' size='small' onClick={openRestorePopup}>
                  <HeartIcon className='h-6' />
                </Button>
              )}
            </span>
          }
          primaryButtonText={<FormattedMessage id='app.buttons.edit' defaultMessage='Upravit' />}
          secondaryButtonText={
            <>
              <BackIcon />
              <FormattedMessage id='app.buttons.back' defaultMessage='Zpět' />
            </>
          }
          onPrimaryButtonClick={() => setIsPopupOpen(true)}
          onSecondaryButtonClick={() => navigate(-1)}
          hidePrimaryButton={!isCurrentUserAllowedToEdit}
          mobilePrimaryButtonType='edit'
        />
        <MaterialPreview data={data} />
      </div>
      <Popup
        title={<FormattedMessage id='app.material_detail_page.update_material.popup_title' />}
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      >
        <UpdateMaterialForm materialId={materialId} />
      </Popup>
      <DeleteMaterialPopup />
      <RestoreMaterialPopup />
    </>
  );
};

MaterialDetailPage.displayName = 'MaterialDetailPage';

export default MaterialDetailPage;
