import { addDays, addMonths, addWeeks, startOfDay, subDays, subMonths, subWeeks } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { MonthInfo } from './types';
import { getMonthDisplayRange } from './utils';

export type UseCalendarOptions = {
  defaultFocusedDate?: Date;
  defaultSelectedDate?: Date;
  onDateClick?: (date: Date) => void;
};

export type UseCalendarReturn = {
  month: MonthInfo;
  selectedDate: Date;
  focusedDate: Date;
  today: Date;

  onDateClick: (date: Date) => void;

  onNextMonth: () => void;
  onPrevMonth: () => void;

  onNextWeek: () => void;
  onPrevWeek: () => void;

  onNextDay: () => void;
  onPrevDay: () => void;
};

const useCalendar = (options: UseCalendarOptions = {}): UseCalendarReturn => {
  const { defaultFocusedDate, defaultSelectedDate, onDateClick } = options;
  const { data: locale } = useUserFnsLocale();

  const today = useMemo(() => startOfDay(new Date()), []);

  const [selectedDate, setSelectedDate] = useState(defaultSelectedDate ? startOfDay(defaultSelectedDate) : today);
  const [focusedDate, setFocusedDate] = useState(defaultFocusedDate ? startOfDay(defaultFocusedDate) : selectedDate);

  useEffect(() => {
    if (!defaultFocusedDate) return;
    setFocusedDate(startOfDay(defaultFocusedDate));
  }, [defaultFocusedDate?.toISOString()]);

  useEffect(() => {
    if (!defaultSelectedDate) return;
    setFocusedDate(startOfDay(defaultSelectedDate));
  }, [defaultSelectedDate?.toISOString()]);

  // TODO: prevent redundant reference changes
  const month = useMemo(
    () => getMonthDisplayRange(focusedDate, false, selectedDate, { weekStartsOn: 1, locale }),
    [focusedDate, selectedDate],
  );

  const handleNextMonth = useCallback((): void => {
    setFocusedDate((prev) => addMonths(prev, 1));
    // setSelectedDate((prev) => addMonths(prev, 1));
  }, []);
  const handlePrevMonth = useCallback((): void => {
    setFocusedDate((prev) => subMonths(prev, 1));
    // setSelectedDate((prev) => subMonths(prev, 1));
  }, []);

  const handleNextWeek = useCallback((): void => {
    setFocusedDate((prev) => addWeeks(prev, 1));
    // setSelectedDate((prev) => addWeeks(prev, 1));
  }, []);
  const handlePrevWeek = useCallback((): void => {
    setFocusedDate((prev) => subWeeks(prev, 1));
    // setSelectedDate((prev) => subWeeks(prev, 1));
  }, []);

  const handleNextDay = useCallback((updateSelectedDate?: boolean): void => {
    setFocusedDate((prev) => addDays(prev, 1));
    if (updateSelectedDate) setSelectedDate((prev) => addDays(prev, 1));
  }, []);
  const handlePrevDay = useCallback((updateSelectedDate?: boolean): void => {
    setFocusedDate((prev) => subDays(prev, 1));
    if (updateSelectedDate) setSelectedDate((prev) => subDays(prev, 1));
  }, []);

  const handleDateClick = useCallback((date: Date) => {
    setSelectedDate(startOfDay(date));
    onDateClick?.(date);
  }, []);

  return {
    today,
    selectedDate,
    focusedDate,
    month,

    onDateClick: handleDateClick,

    onNextMonth: handleNextMonth,
    onPrevMonth: handlePrevMonth,

    onNextWeek: handleNextWeek,
    onPrevWeek: handlePrevWeek,

    onNextDay: handleNextDay,
    onPrevDay: handlePrevDay,
  };
};

export default useCalendar;
