import clsx from 'clsx';
import React, { useEffect } from 'react';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import FormDataRow from 'src/components/FormDataRow';
import PhoneInputLocalized from 'src/components/fields/PhoneInputLocalized';
import Textfield from 'src/components/fields/Textfield';
import { EmployeeSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';

interface EmployeeFormProps {
  defaultValues?: Partial<EmployeeSchema>;
  className?: string;
  onSubmit: SubmitHandler<EmployeeSchema>;
}

const EmployeeForm: React.FC<EmployeeFormProps> = ({ defaultValues, className, onSubmit }) => {
  const form = useForm<EmployeeSchema>({
    resolver: zodResolver(EmployeeSchema),
    defaultValues: defaultValues,
  });
  const { handleSubmit, control, setValue } = form;

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof EmployeeSchema, v));
  }, [defaultValues]);

  return (
    <div className={clsx(className, 'flex-col w-full')}>
      <form className='flex flex-col w-full' onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...form}>
          <FormDataRow asterisk label={<FormattedMessage id='app.employee.name' defaultMessage='Jmeno' />}>
            <Controller
              control={control}
              name='name'
              render={({ field, fieldState }) => <Textfield {...field} type='text' error={fieldState.error?.message} />}
            />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.common.phone' defaultMessage='Telefon' />}>
            <Controller
              control={control}
              name='phoneNumber'
              render={({ field, fieldState }) => (
                <PhoneInputLocalized {...field} value={field.value ?? ''} error={fieldState.error?.message} />
              )}
            />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.common.email' defaultMessage='E-mail' />}>
            <Controller
              control={control}
              name='email'
              render={({ field, fieldState }) => <Textfield {...field} type='text' error={fieldState.error?.message} />}
            />
          </FormDataRow>
          <NewItemPopupFooter />
        </FormProvider>
      </form>
    </div>
  );
};

export default EmployeeForm;
