import clsx from 'clsx';
import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns';
import { FC } from 'react';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { MonthInfo } from 'src/components/Calendar/types';
import MiniDay from '../MiniDay';

export type MiniMonthProps = {
  month: MonthInfo;
  onDateClick?: (date: Date) => void;
  focusedDate: Date;
  showWeekDays?: boolean;
  noBorderX?: boolean;
  noBorderBotton?: boolean;
  className?: string;
};

const MiniMonth: FC<MiniMonthProps> = ({ className, month, onDateClick, focusedDate, showWeekDays }) => {
  const { data: locale } = useUserFnsLocale();
  const start = startOfWeek(focusedDate, { locale, weekStartsOn: 1 });
  const end = endOfWeek(focusedDate, { locale, weekStartsOn: 1 });
  const daysInWeek = eachDayOfInterval({ start, end });

  return (
    <div className={clsx('flex flex-col items-center w-max')}>
      {showWeekDays && (
        <div className='w-full grid grid-cols-7 gap-px text-xs leading-6 text-gray-500'>
          {daysInWeek.map((day) => (
            <div key={day.getTime()} className={clsx('')}>
              <span
                className={clsx('flex justify-center items-center text-gray-600 py-4 text-sm w-full h-full bg-gray-50')}
              >
                {format(day, 'EEE', { locale })[0]}
              </span>
            </div>
          ))}
        </div>
      )}

      <div className='w-max flex items-center justify-center border border-gray-300 rounded-xl overflow-hidden'>
        <div
          className={clsx(
            className,
            'isolate w-full grid grid-cols-7 justify-center items-center gap-px bg-gray-200 text-sm',
          )}
        >
          {month.datesInfo.map((dateInfo) => (
            <MiniDay key={dateInfo.date.getTime()} onClick={() => onDateClick?.(dateInfo.date)} dateInfo={dateInfo} />
          ))}
        </div>
      </div>
    </div>
  );
};

MiniMonth.displayName = 'MiniMonth';

export default MiniMonth;
