import { OrderResponse } from 'src/api';
import { OrderFinishWithRealTimeSchema } from './components/FinishOrderForm/schema';

export const createTechnicianOrderFormDefaultValues = (order: OrderResponse): Partial<OrderFinishWithRealTimeSchema> =>
  ({
    products: order.orderProducts.map((op) => ({
      orderProductId: op.id,
      repairDescription: '',
      problemDescription: '',
      materials: [
        {
          material: '',
          price: null,
          quantity: 1,
          unit: 'piece',
          code: '',
        },
      ],
      images: [],
    })),
    orderSignatureCustomer: {
      dataUrl: null,
      image: null,
    },
    orderSignatureTechnician: {
      dataUrl: null,
      image: null,
    },
    useManualServiceDurationPrice: Boolean(order.orderType?.slConfig?.allowManualPrices),
    preferredCurrency: order.company.preferredCurrency,
    serviceDuration: 0,
    serviceMileage: 0,
    serviceTimeOnRoad: 0,
    humidity: null,
    temperature: null,
    signingTechnicianId: order.technicians.length === 1 ? order.technicians[0].id : undefined,
    deliveryType: order.orderType?.slConfig?.allowManualPrices
      ? OrderResponse.deliveryType.MANUAL
      : OrderResponse.deliveryType.CALCULATED,
    deliveryTariffId: null,
    workTariffId: null,
    deliveryRateId: null,
    servicePaymentMethodId: (order.orderType?.slConfig?.defaultPaymentMethodId ?? null) as number,
    customerSignature: {
      absentCustomer: false,
    },
    realStart: null,
    realEnd: null,
    slConfigOutput: {
      products: order.orderProducts.map(() => ({})),
    },
  } as unknown as Partial<OrderFinishWithRealTimeSchema>);
