import clsx from 'clsx';
import { range } from 'ramda';
import { CSSProperties, FC, Fragment } from 'react';
import useEventsGridSettings from '../../hooks/useEventsGridSettings';

export type HorizontalLinesProps = {
  className?: string;
  style?: CSSProperties;
};

const HorizontalLines: FC<HorizontalLinesProps> = ({ className, style }) => {
  const { START_HOUR, END_HOUR, TOTAL_SEPARATORS } = useEventsGridSettings();
  const hours = range(START_HOUR, END_HOUR);

  return (
    <div
      className={clsx('col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100', className)}
      style={{
        gridTemplateRows: `repeat(${TOTAL_SEPARATORS}, minmax(3.5rem, 1fr))`,
        ...style,
      }}
    >
      {/* before 1st hour sep */}
      <div className='row-end-1 h-7' />
      {hours.map((hour) => (
        <Fragment key={hour}>
          <div>
            <div className='sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400'>
              {/* {hour} */}
            </div>
          </div>
          <div />
        </Fragment>
      ))}
      <div className={`row-start-${TOTAL_SEPARATORS} h-7`}>
        <div className='sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400'></div>
      </div>
      {/* last hour sep */}
    </div>
  );
};

HorizontalLines.displayName = 'HorizontalLines';

export default HorizontalLines;
