import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SectionSchemaResponse, SelectFieldSchemaResponse } from 'src/api';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';
import DatePicker from 'src/components/fields/DatePicker';
import DescriptionField from 'src/components/fields/DescriptionField';
import { NumberFieldRhfc } from 'src/components/fields/NumberField';
import { SelectRhfc } from 'src/components/fields/Select';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import { ArrayElement } from 'src/types/commonTypes';

export type GenFieldProps = {
  field: ArrayElement<SectionSchemaResponse['fields']>;
  baseName?: string;
  className?: string;
};

const GenField: FC<GenFieldProps> = ({ className, baseName, field }) => {
  const { control } = useFormContext();

  const baseFieldProps = {
    className,
    control,
    name: baseName ? `${baseName}.${field.name}` : `slConfigOutput.${field.name}`,
  };

  if (field.type === 'input') return <TextFieldRhfc {...baseFieldProps} />;
  if (field.type === 'number') return <NumberFieldRhfc {...baseFieldProps} />;
  if (field.type === 'checkbox') return <CheckboxRhfc {...baseFieldProps} />;
  if (field.type === 'date')
    return (
      <Controller
        {...baseFieldProps}
        render={({ field: { value, ...rest }, fieldState }) => (
          <DatePicker {...rest} value={value} error={fieldState.error?.message} />
        )}
      />
    );
  if (field.type === 'textarea')
    return (
      <Controller
        {...baseFieldProps}
        render={({ field, fieldState }) => <DescriptionField {...field} error={fieldState.error?.message} />}
      />
    );
  if (field.type === 'select')
    return (
      <SelectRhfc
        {...baseFieldProps}
        options={(field as SelectFieldSchemaResponse).options}
        getOptionLabel={(option) => option}
        getOptionValue={(option) => option}
      />
    );
  return null;
};

GenField.displayName = 'GenField';

export default GenField;
