import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { OrderListItemResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

const useCurrentUserUpcomingOrders = (): UseQueryResult<OrderListItemResponse[]> => {
  const client = useApiClient();
  const { queryKey } = queryKeysFactory.upcomingOrders.currentUser;

  return useQuery(queryKey, () => client.orders.getCurrentUserUpcomingOrders());
};

export default useCurrentUserUpcomingOrders;
