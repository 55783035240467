import clsx from 'clsx';
import { FC, useState } from 'react';
import { UserResponse } from 'src/api';
import useUser from 'src/api/hooks/queries/useUser';
import WithLoader from 'src/components/utils/WithLoader';
import useLastDefined from 'src/hooks/useLastDefined';
import TechnicianInfoPopup from '../TechnicianInfoPopup';

export type TechniciansBarProps = {
  className?: string;
  technicians: UserResponse[];
  disableTechnicianPreviewOnClick?: boolean;
};

const TechniciansBar: FC<TechniciansBarProps> = ({ className, technicians, disableTechnicianPreviewOnClick }) => {
  const [technicianIdToLoad, setTechnicianIdToLoad] = useState<number | null>(null);
  const { data, isFetching } = useUser(technicianIdToLoad ?? 0, {
    enabled: !!technicianIdToLoad,
  });

  const technicianData = useLastDefined(data);

  const handleTechnicianClick = async (technicianId: number): Promise<void> => {
    setTechnicianIdToLoad(technicianId);
  };

  return (
    <WithLoader isLoading={isFetching}>
      <div className={clsx('flex flex-col justify-between', className)}>
        {technicians.map((technician) => (
          <div
            onClick={!disableTechnicianPreviewOnClick ? () => handleTechnicianClick(technician.id) : undefined}
            key={technician.id}
            className={clsx(
              'flex items-center justify-start w-32 h-12 m-2 p-2 bg-primary rounded-md text-white text-sm overflow-hidden whitespace-nowrap',
              !disableTechnicianPreviewOnClick && 'cursor-pointer',
            )}
          >
            {technician.profile?.name ?? technician.id}
          </div>
        ))}
      </div>
      <TechnicianInfoPopup
        data={technicianData ?? ({} as UserResponse)}
        onClose={() => setTechnicianIdToLoad(null)}
        open={!!technicianIdToLoad && !!data}
      />
    </WithLoader>
  );
};

TechniciansBar.displayName = 'TechniciansBar';

export default TechniciansBar;
