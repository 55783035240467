import { QueryKey, useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { formatISO, getWeek } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { MoveOrderRequest, OrderListItemResponse } from 'src/api/.gen';
import { useApiClient } from 'src/api/useApiClient';
import queryKeysFactory from 'src/store/queryKeysFactory';

export type UseMoveOrderPayload = Omit<MoveOrderRequest, 'start'> & {
  id: number;
  start: Date;
  oldStart: Date;
  technicianId: number;
  newTechnicianId?: number;
};

export type UseMoveOrderContext = {
  prev: OrderListItemResponse[] | undefined;
};

export const getTmoKeys = (payload: UseMoveOrderPayload): QueryKey[] => [
  queryKeysFactory.technicianMonthOrders.list(
    payload.technicianId,
    payload.oldStart.getFullYear(),
    payload.oldStart.getMonth(),
  ).queryKey,
  ...(payload.newTechnicianId
    ? [
        queryKeysFactory.technicianMonthOrders.list(
          payload.newTechnicianId,
          payload.oldStart.getFullYear(),
          payload.oldStart.getMonth(),
        ).queryKey,
      ]
    : []),
];

export const getATWOKey = (payload: UseMoveOrderPayload): QueryKey =>
  queryKeysFactory.allTechniciansWeekOrders.list(
    payload.oldStart.getFullYear(),
    getWeek(payload.oldStart, { weekStartsOn: 1 }) - 1,
  ).queryKey;

const useMoveOrder = (): UseMutationResult<void, unknown, UseMoveOrderPayload, UseMoveOrderContext> => {
  const client = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<void, unknown, UseMoveOrderPayload, UseMoveOrderContext>(
    async (data: UseMoveOrderPayload) => {
      await client.orders.moveOrder({
        id: data.id,
        requestBody: {
          start: formatISO(data.start),
          technicianId: data.technicianId,
          newTechnicianId: data.newTechnicianId,
          ignoreCustomerNotification: data.ignoreCustomerNotification,
          ignoreTechnicianNotification: data.ignoreTechnicianNotification,
        },
      });
    },
    {
      onMutate: async (variables) => {
        const [tmoQueryKey] = getTmoKeys(variables);

        const prev = queryClient.getQueryData<OrderListItemResponse[] | undefined>(tmoQueryKey);
        return { prev };
      },
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(queryKeysFactory.orders.list.queryKey);
        queryClient.invalidateQueries(queryKeysFactory.orders.detail(variables.id).queryKey);

        toast(<FormattedMessage id='app.toasts.update_success' />, {
          type: 'success',
        });
      },
      onError: (_err, payload, context) => {
        const [tmoQueryKey] = getTmoKeys(payload);
        queryClient.setQueryData(tmoQueryKey, context?.prev);

        toast(<FormattedMessage id='app.toasts.update_fail' />, {
          type: 'error',
        });
      },
      onSettled: (_data, _err, payload) => {
        const [tmoQueryKey, newTechnicianTmoKeys] = getTmoKeys(payload);
        const atwoQueryKey = getATWOKey(payload);

        queryClient.invalidateQueries(tmoQueryKey);
        queryClient.invalidateQueries(newTechnicianTmoKeys);
        queryClient.invalidateQueries(atwoQueryKey);
      },
    },
  );
};

export default useMoveOrder;
