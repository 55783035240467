import { range } from 'ramda';
import { FC, useCallback, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContactListResponse, ContactResponse, useApiClient } from 'src/api';
import useContact from 'src/api/hooks/queries/useContact';
import useSupportedLanguages from 'src/api/hooks/queries/useSupportedLanguages';
import AutocompleteBe from 'src/components/fields/AutocompleteBe';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';
import { PhoneInputLocalizedRhfc } from 'src/components/fields/PhoneInputLocalized';
import Select, { SelectRhfc } from 'src/components/fields/Select';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import FormDataRow from 'src/components/FormDataRow';
import InputSkeleton from 'src/components/skeletons/InputSkeleton';
import { CURRENCY_OPTIONS } from 'src/constants/currency';
import { MessageId } from 'src/types/commonTypes';
import { BRANCH_FIELDS, COMPANY_FIELDS, EMPLOYEE_FIELDS } from '../constants';
import { OrderSchema } from '../schema';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';
import { INTERNAL_CATEGORY_OPTIONS } from 'src/constants/internalCategories';
import AresIcoTextfield from 'src/pages/ContactPage/ContactForm/AresIcoTextfield';

const ServiceCustomerForm: FC<{ disableCreateNew?: boolean }> = ({ disableCreateNew }) => {
  const intl = useIntl();
  const apiClient = useApiClient();
  const { control, setValue, resetField, watch } = useFormContext<OrderSchema>();
  const { fields: productFields, remove } = useFieldArray({ name: 'orderProducts', control });

  const companyId = watch('company.companyId');
  const createNewCompany = watch('company.createNewCompany');
  const category = watch('category');

  const { data: tenantConfig } = useCurrentTenantConfig();
  const { data: contact, isLoading } = useContact(companyId ?? 0, { enabled: !!companyId });
  const defaultContactOptions = useMemo(() => (contact ? [contact as ContactListResponse] : []), [contact]);

  const { data } = useSupportedLanguages();

  const getContacts = async (query: string): Promise<ContactListResponse[]> => {
    const data = await apiClient.contact.getContactList({
      filterString: query,
      category,
      pageSize: 20,
      pageIndex: 0,
      withDeleted: false,
    });
    return data.items;
  };

  const handleContactChange = async (contactId: number | null): Promise<void> => {
    setValue('branch.branchId', null as unknown as number);
    BRANCH_FIELDS.forEach((field) => resetField(`branch.branch.${field}`));

    setValue('employee.employeeId', null as unknown as number);
    EMPLOYEE_FIELDS.forEach((field) => resetField(`employee.employee.${field}`));

    resetField('orderProducts');

    if (!contactId) return;

    const data = await apiClient.contact.getContact({ id: contactId });
    COMPANY_FIELDS.forEach((field) => setValue(`company.company.${field}`, data[field]));
  };

  const hanldeCreateNewCompanyChange = useCallback(
    (value: boolean | null) => {
      if (!value) {
        setValue('branch.createNewBranch', false);
        setValue('employee.createNewEmployee', false);
        range(1, productFields.length).forEach((index) => remove(index));
        setValue(`orderProducts.0.createNewProduct`, false);
        return;
      }

      resetField('company.companyId');
      COMPANY_FIELDS.forEach((field) => resetField(`company.company.${field}`));

      if (category === ContactResponse.category.B2B) {
        setValue('branch.createNewBranch', true);
        setValue('employee.createNewEmployee', true);
      }

      range(0, productFields.length).forEach((index) => setValue(`orderProducts.${index}.createNewProduct`, true));
    },
    [setValue, category],
  );

  return (
    <div className='flex flex-col mb-16'>
      <div className='font-bold text-gray-800 text-2xl mb-8'>
        <FormattedMessage id='app.order.section_title.contact' />
      </div>

      {!disableCreateNew && (
        <FormDataRow label={<FormattedMessage id='app.order.new_customer' />}>
          <CheckboxRhfc
            control={control}
            name='company.createNewCompany'
            extraOnChange={hanldeCreateNewCompanyChange}
          />
        </FormDataRow>
      )}

      {!createNewCompany && (
        <FormDataRow asterisk label={<FormattedMessage id='app.order.section_title.contact' />}>
          {!!companyId && isLoading ? (
            <InputSkeleton />
          ) : (
            <Controller
              control={control}
              name='company.companyId'
              render={({ field: { onChange, ...rest }, fieldState }) => (
                <AutocompleteBe
                  {...rest}
                  onChange={(v: number | null) => {
                    onChange(v);
                    handleContactChange(v);
                  }}
                  error={fieldState.error?.message}
                  getOptions={getContacts}
                  getOptionLabel={(opt) => `${opt.companyName}${opt.vat ? ` - ${opt.vat}` : ''}`}
                  getOptionValue={(opt) => opt.id}
                  defaultOptions={defaultContactOptions}
                />
              )}
            />
          )}
        </FormDataRow>
      )}
      <FormDataRow asterisk label={<FormattedMessage id='app.order.company_name' />}>
        <TextFieldRhfc control={control} name='company.company.companyName' disabled={!createNewCompany} />
      </FormDataRow>
      {category === ContactResponse.category.B2B && (
        <>
          <FormDataRow label={<FormattedMessage id='app.common.ico' />}>
            {createNewCompany ? (
              <Controller
                name='company.company.in'
                render={({ field, fieldState }) => (
                  <AresIcoTextfield
                    {...field}
                    onIcoSelect={(data) => {
                      setValue('company.company.companyName', data.companyName);
                      setValue('company.company.city', data.city);
                      setValue('company.company.street', data.street);
                      setValue('company.company.zip', data.zip);
                    }}
                    error={fieldState.error?.message}
                  />
                )}
              />
            ) : (
              <TextFieldRhfc control={control} name='company.company.in' disabled />
            )}
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.common.vat' />}>
            <TextFieldRhfc control={control} name='company.company.vat' disabled={!createNewCompany} />
          </FormDataRow>
        </>
      )}
      <FormDataRow asterisk label={<FormattedMessage id='app.common.city' />}>
        <TextFieldRhfc control={control} name='company.company.city' disabled={!createNewCompany} />
      </FormDataRow>
      <FormDataRow asterisk label={<FormattedMessage id='app.common.street' />}>
        <TextFieldRhfc control={control} name='company.company.street' disabled={!createNewCompany} />
      </FormDataRow>
      <FormDataRow asterisk label={<FormattedMessage id='app.common.zip' />}>
        <TextFieldRhfc control={control} name='company.company.zip' disabled={!createNewCompany} />
      </FormDataRow>
      <FormDataRow asterisk label={<FormattedMessage id='app.common.phone' />}>
        <PhoneInputLocalizedRhfc control={control} name='company.company.phoneNumber' disabled={!createNewCompany} />
      </FormDataRow>
      <FormDataRow asterisk label={<FormattedMessage id='app.common.email' />}>
        <TextFieldRhfc control={control} name='company.company.email' disabled={!createNewCompany} />
      </FormDataRow>
      {tenantConfig?.contactForm?.showInternalCategory && (
        <FormDataRow label={<FormattedMessage id='app.contact.internal_category' />}>
          <SelectRhfc
            options={INTERNAL_CATEGORY_OPTIONS}
            getOptionLabel={(option) => option}
            getOptionValue={(option) => option}
            control={control}
            disabled={!createNewCompany}
            name='company.company.internalCategory'
          />
        </FormDataRow>
      )}
      {tenantConfig?.contactForm?.showContractNumber && (
        <FormDataRow label={<FormattedMessage id='app.contact.contract_number' />}>
          <TextFieldRhfc control={control} name='company.company.contractNumber' disabled={!createNewCompany} />
        </FormDataRow>
      )}

      <FormDataRow asterisk label={<FormattedMessage id='app.common.preferred_currency' />}>
        <Controller
          control={control}
          name='company.company.preferredCurrency'
          render={({ field, fieldState }) => (
            <Select
              {...field}
              disabled={!createNewCompany}
              options={CURRENCY_OPTIONS}
              getOptionLabel={(option) => option}
              getOptionValue={(option) => option}
              error={fieldState.error?.message}
            />
          )}
        />
      </FormDataRow>
      <FormDataRow asterisk label={<FormattedMessage id='app.common.preferred_language' />}>
        <Controller
          control={control}
          name='company.company.preferredLanguage'
          render={({ field, fieldState }) => (
            <Select
              {...field}
              disabled={!createNewCompany}
              options={data ?? []}
              getOptionLabel={(option) =>
                intl.formatMessage({ id: `app.language.${option}` as MessageId, defaultMessage: option })
              }
              getOptionValue={(option) => option}
              error={fieldState.error?.message}
            />
          )}
        />
      </FormDataRow>
    </div>
  );
};

export default ServiceCustomerForm;
