import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, ReactNode, useMemo, useRef } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import Title from '../Title';
import clsx from 'clsx';
import { PopupContext } from './context';
import { FormattedMessage } from 'react-intl';

export type PopupProps = Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'title'> & {
  title?: ReactNode;
  replaceTitleContainer?: boolean;
  stickTop?: boolean;
  className?: string;
  open: boolean;
  onClose: () => void;
  hideCloseIcon?: boolean;
  centerTitle?: boolean;
};

const PopupTitle: React.FC<Pick<PopupProps, 'title' | 'replaceTitleContainer'>> = ({
  title,
  replaceTitleContainer,
}) => {
  if (!title) return null;
  if (replaceTitleContainer) return title;

  return (
    <Title component='h2' className='w-full flex'>
      {title}
    </Title>
  );
};

const Popup: React.FC<PopupProps> = ({
  title,
  children,
  className,
  onClose,
  open,
  hideCloseIcon,
  replaceTitleContainer,
  centerTitle,
  stickTop,
}) => {
  const cancelButtonRef = useRef(null);
  const contextValue = useMemo(() => ({ open, onClose }), [open, onClose]);

  return (
    <PopupContext.Provider value={contextValue}>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='relative z-[3000]' initialFocus={cancelButtonRef} onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-900 bg-opacity-70 transition-opacity' />
          </Transition.Child>

          <div className='fixed inset-0 z-50 overflow-y-auto'>
            <div
              className={clsx(
                'flex min-h-full justify-center text-center sm:items-center sm:p-0',
                stickTop ? 'items-start' : 'items-center',
              )}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                enterTo='opacity-100 translate-y-0 sm:scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              >
                <Dialog.Panel
                  className={clsx(
                    className,
                    'relative transform md:rounded-lg max-w-4xl bg-white px-4 md:pl-14 pt-14 md:pr-10 pb-10 text-left shadow-xl transition-all md:w-[65vw] lg:w-[55vw] md:max-h-[calc(100dvh-2rem)] md:h-fit overflow-auto my-0 h-dvh w-full rounded-none max-h-dvh ',
                  )}
                >
                  {!hideCloseIcon && (
                    <div className='absolute top-0 right-0 pt-4 pr-4 z-[12000] sm:block'>
                      <button
                        type='button'
                        className='rounded-md bg-gray-50 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                        onClick={onClose}
                      >
                        <span className='sr-only'>
                          <FormattedMessage id='app.buttons.close' />
                        </span>
                        <XMarkIcon className='h-8 w-8 text-gray' aria-hidden='true' />
                      </button>
                    </div>
                  )}
                  <div className='sm:flex sm:items-start'>
                    <div className='mt-3 text-left sm:mt-0 sm:text-left w-full max-w-full'>
                      {title && (
                        <Dialog.Title
                          as='div'
                          className={clsx('mb-16', centerTitle && 'flex justify-center', !hideCloseIcon && 'pr-4')}
                        >
                          <PopupTitle title={title} replaceTitleContainer={replaceTitleContainer} />
                        </Dialog.Title>
                      )}

                      <div className='mt-2 w-full'>{children}</div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </PopupContext.Provider>
  );
};

export default Popup;
