import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Outlet } from 'react-router-dom';
import Title from 'src/components/Title';
import ChangePasswordPage from './ChangePassword';
import ProfileSettings from './ProfileSettings';

const ProfileSettingPage: React.FC = () => {
  return (
    <>
      <div className='flex w-full'>
        <div className='flex-col w-full'>
          <div className='flex justify-between'>
            <Title className='pb-3 mb-20'>
              <FormattedMessage id='app.profile_settings_page.title' />
            </Title>
          </div>
          <div className='md:w-full w-1/2 mb-20'>
            <ChangePasswordPage />
          </div>
          <div className='md:w-full w-1/2'>
            <ProfileSettings />
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default ProfileSettingPage;
