import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useAllTechnicians from 'src/api/hooks/queries/useAllTechnicians';
import useAllTechniciansAvailability from 'src/api/hooks/queries/useAllTechniciansAvailability';
import Button from 'src/components/Button';
import Select from 'src/components/fields/Select';
import FormDataRow from 'src/components/FormDataRow';
import { OrderSchema } from '../schema';

export type ServiceTechniciansFormProps = {
  //
};

const ServiceTechniciansForm: FC<ServiceTechniciansFormProps> = () => {
  const { control, watch } = useFormContext<OrderSchema>();
  const { data: technicians } = useAllTechnicians();

  const start = watch('datetime');
  const end = watch('endDatetime');

  const { data: availability } = useAllTechniciansAvailability(
    start ? new Date(start) : new Date(),
    end ? new Date(end) : new Date(),
    {
      enabled: !!start && !!end,
    },
  );

  const { fields: technicianIdsFields, append, remove } = useFieldArray({ name: 'technicianIds', control });

  return (
    <div className='flex flex-col mb-16'>
      <div className='flex space-x-44 mb-2 mt-4'>
        <div className='font-bold flex text-gray-800 text-2xl mb-8'>
          <FormattedMessage id='app.order.section_title.technicians' />
          {technicianIdsFields.length < (technicians ?? []).length && (
            <Button
              variant='cta'
              size='small'
              className='ml-2'
              onClick={() => append({ technicianId: undefined } as unknown as OrderSchema['technicianIds'][0])}
            >
              <PlusCircleIcon className='h-6 mr-1' />
              <FormattedMessage id='app.buttons.add' />
            </Button>
          )}
        </div>
      </div>

      {technicianIdsFields.map((field, index) => (
        <FormDataRow
          key={`t${field.technicianId}-i${index}`}
          label={<FormattedMessage id='app.order.technician_n' values={{ value: index + 1 }} />}
        >
          <div className='w-full flex items-center'>
            <Controller
              control={control}
              name={`technicianIds.${index}.technicianId`}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  options={technicians ?? []}
                  shouldHideOption={(option) => technicianIdsFields.map((i) => i.technicianId).includes(option.id)}
                  getOptionLabelClassname={(option) =>
                    availability?.[option.id] ? 'bg-success-light hover:bg-success' : 'bg-error-light hover:bg-error'
                  }
                  getOptionLabel={(option) => option?.profile?.name ?? option.id.toString()}
                  getOptionValue={(option) => option.id}
                  error={fieldState.error?.message}
                />
              )}
            />
            <Button size='small' variant='cta' onClick={() => remove(index)}>
              <TrashIcon className='h-6' />
            </Button>
          </div>
        </FormDataRow>
      ))}
    </div>
  );
};

export default ServiceTechniciansForm;
