import { LinkIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import useTechnicianGridEventsFromDay from 'src/api/hooks/querySelectors/useTechnicianGridEventsFromDay';
import Button from 'src/components/Button';
import HyperLink from 'src/components/common/HyperLink';
import Popup from 'src/components/Popup';

export type DayEventsPopupProps = {
  date: Date | null;
  technicianId: number;
  onClose: () => void;
  open: boolean;
};

export type DayEventsPopupContentProps = {
  date: Date;
  technicianId: number;
};

const DayEventsPopupContent: FC<DayEventsPopupContentProps> = ({ date, technicianId }) => {
  const events = useTechnicianGridEventsFromDay(technicianId, date);

  return (
    <div className='flex flex-col divide-y'>
      {events.map((event) => (
        <div key={event.id} className='flex justify-between py-5'>
          <div className='flex flex-col'>
            <span>
              #{event.data.orderNumber} - {event.data.companyName}
            </span>
            <span className='text-gray-600'>
              {format(event.start, 'HH:mm')} - {format(event.end, 'HH:mm')}
            </span>
          </div>

          <div className='flex'>
            <HyperLink
              className='flex items-center hover:text-primaryFocus'
              target='_blank'
              href={`/order/${event.id}`}
            >
              <LinkIcon className='w-7' />
            </HyperLink>
            <Button className='hover:text-primaryFocus' variant='cta' size='small'>
              <MapPinIcon className='w-7' />
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

const DayEventsPopup: FC<DayEventsPopupProps> = ({ date, open, onClose, technicianId }) => {
  return (
    <Popup
      open={open}
      onClose={onClose}
      title={
        date && (
          <FormattedMessage
            id='app.technician_account.day_events_popup.title'
            values={{
              formattedDate: format(date, 'EEEE, d LLLL'),
            }}
          />
        )
      }
    >
      {date && <DayEventsPopupContent technicianId={technicianId} date={date} />}
    </Popup>
  );
};

DayEventsPopup.displayName = 'DayEventsPopup';

export default DayEventsPopup;
