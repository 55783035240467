import { FC } from 'react';
import { ColumnFiltersState } from '@tanstack/react-table';
import { TenantFilter } from 'src/types/filters';
import usePageBaseState from 'src/hooks/usePageBaseState';
import PageContentHeader from 'src/components/PageContentHeader';
import { FormattedMessage } from 'react-intl';
import TenantsTable from './components/TenantsTable';
import useLastDefined from 'src/hooks/useLastDefined';
import useTenants from 'src/api/hooks/queries/useTenants';
import useOpenPopup from 'src/hooks/popups/useOpenPopup';
import AddTenantPopup from './components/AddTenantPopup';
import useTableState from 'src/components/Table/hooks/useTableState';

export type TenantsPageProps = {
  //
};

const transformFilters = (filters: ColumnFiltersState): TenantFilter => ({
  tenantName: (filters.find((i) => i.id === 'tenant_name')?.value as string) ?? '',
  applicationTitle: (filters.find((i) => i.id === 'application_title')?.value as string) ?? '',
  accountingEmail: (filters.find((i) => i.id === 'accounting_email')?.value as string) ?? '',
});

const defaultSortingState = [{ id: 'tenant.id', desc: false }];

const TenantsPage: FC<TenantsPageProps> = () => {
  const { isFilterOpen, handleToggleFilter } = usePageBaseState();

  const {
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
    debouncedServerFriendlyFilters,
    sortingQuery,
  } = useTableState<TenantFilter>({
    transformFilters,
    defaultSortingState,
  });

  const openPopup = useOpenPopup('add_tenant');

  const { data, isLoading } = useTenants(debouncedServerFriendlyFilters, sortingQuery, pagination);
  const definedData = useLastDefined(data);

  return (
    <>
      <PageContentHeader
        mobilePrimaryButtonType='new'
        title={<FormattedMessage id='app.tenants_page.title' />}
        onSecondaryButtonClick={handleToggleFilter}
        onPrimaryButtonClick={openPopup}
        primaryButtonText={<FormattedMessage id='app.tenants_page.new_tenant_button' />}
      />
      <TenantsTable
        className='mt-16'
        isLoading={isLoading}
        data={definedData?.items ?? []}
        isFilterOpen={isFilterOpen}
        sorting={sorting}
        columnFilters={columnFilters}
        pagination={pagination}
        handleSort={setSorting}
        handleFilter={setColumnFilters}
        handlePaginationChange={setPagination}
        paginationMeta={definedData?.meta}
      />
      <AddTenantPopup />
    </>
  );
};

TenantsPage.displayName = 'TenantsPage';

export default TenantsPage;
