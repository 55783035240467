import { formatISO, parseISO } from 'date-fns';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import DatePicker from 'src/components/fields/DatePicker';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import FormDataRow from 'src/components/FormDataRow';
import useGetIsHiddenProductFormField from 'src/hooks/formsConfig/useGetIsHiddenProductFormField';
import { OrderSchema } from '../schema';
import useCurrentTenantConfig from 'src/api/hooks/queries/useCurrentTenantConfig';

export type ServiceNewProductFormProps = {
  index: number;
};

const ServiceNewProductForm: FC<ServiceNewProductFormProps> = ({ index }) => {
  const { control } = useFormContext<OrderSchema>();

  const { data: config } = useCurrentTenantConfig();
  const getIsHiddenField = useGetIsHiddenProductFormField();

  return (
    <>
      <FormDataRow asterisk label={<FormattedMessage id='app.product.name' />}>
        <TextFieldRhfc control={control} name={`orderProducts.${index}.product.productName`} />
      </FormDataRow>

      {!getIsHiddenField('productProducer') && (
        <FormDataRow label={<FormattedMessage id='app.product.producer' />}>
          <TextFieldRhfc control={control} name={`orderProducts.${index}.product.productProducer`} />
        </FormDataRow>
      )}

      {!getIsHiddenField('productSerialNumber') && (
        <FormDataRow label={<FormattedMessage id='app.product.serial_number' />}>
          <TextFieldRhfc control={control} name={`orderProducts.${index}.product.productSerialNumber`} />
        </FormDataRow>
      )}

      {config?.productForm?.showProductSerialNumber2 && (
        <FormDataRow label={<FormattedMessage id='app.product.serial_number2' />}>
          <TextFieldRhfc control={control} name={`orderProducts.${index}.product.productSerialNumber2`} />
        </FormDataRow>
      )}

      {config?.productForm?.showTechnology && (
        <FormDataRow label={<FormattedMessage id='app.product.technology' />}>
          <TextFieldRhfc control={control} name={`orderProducts.${index}.product.technology`} />
        </FormDataRow>
      )}

      {!getIsHiddenField('productNote') && (
        <FormDataRow label={<FormattedMessage id='app.product.documentation_note' />}>
          <TextFieldRhfc control={control} name={`orderProducts.${index}.product.productNote`} />
        </FormDataRow>
      )}

      {!getIsHiddenField('warranty') && (
        <Controller
          control={control}
          name={`orderProducts.${index}.product.warranty`}
          render={({ field: { value, ...rest }, fieldState }) => (
            <FormDataRow label={<FormattedMessage id='app.product.warranty' />}>
              <DatePicker
                {...rest}
                onChange={(v) => rest.onChange(v ? formatISO(v) : null)}
                value={value ? parseISO(value) : null}
                error={fieldState.error?.message}
              />
            </FormDataRow>
          )}
        />
      )}

      <Controller
        control={control}
        name={`orderProducts.${index}.product.inUseFrom`}
        render={({ field: { value, ...rest }, fieldState }) => (
          <FormDataRow label={<FormattedMessage id='app.product.in_use_from' />}>
            <DatePicker
              {...rest}
              onChange={(v) => rest.onChange(v ? formatISO(v) : null)}
              value={value ? parseISO(value) : null}
              error={fieldState.error?.message}
            />
          </FormDataRow>
        )}
      />
    </>
  );
};

export default ServiceNewProductForm;
