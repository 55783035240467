import { FC } from 'react';
import clsx from 'clsx';
import FormDataRow from 'src/components/FormDataRow';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import { useForm } from 'react-hook-form';
import { FormBuilderSectionSchema } from './schema';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { FormattedMessage } from 'react-intl';
import { UserResponse } from 'src/api';
import useCurrentUser from 'src/api/hooks/queries/useCurrentUser';

export type FormBuilderSectionFormProps = {
  className?: string;
  onSubmit: (data: FormBuilderSectionSchema) => void;
  defaultValues?: FormBuilderSectionSchema;
};

const DEFAULT_VALUES: FormBuilderSectionSchema = {
  name: '',
  label: '',
  noThead: false,
  labelAsTh: false,
};

const FormBuilderSectionForm: FC<FormBuilderSectionFormProps> = ({ className, defaultValues, onSubmit }) => {
  const { data: currentUser } = useCurrentUser();
  const form = useForm<FormBuilderSectionSchema>({
    defaultValues: {
      ...DEFAULT_VALUES,
      ...defaultValues,
    },
  });

  const { control, handleSubmit } = form;
  const isSysmAdmin = currentUser?.role === UserResponse.role.SYSTEM_ADMINISTRATOR;

  return (
    <form className={clsx(className, 'flex flex-col')} onSubmit={handleSubmit(onSubmit)}>
      <FormDataRow label={<FormattedMessage id='app.common.name' />}>
        <TextFieldRhfc control={control} name='name' />
      </FormDataRow>
      <FormDataRow label={<FormattedMessage id='app.common.label' />}>
        <TextFieldRhfc control={control} name='label' />
      </FormDataRow>
      {isSysmAdmin && (
        <>
          <FormDataRow label={<FormattedMessage id='app.order_type.sl_config.sections.no_thead' />}>
            <CheckboxRhfc control={control} name='noThead' />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.order_type.sl_config.sections.label_as_th' />}>
            <CheckboxRhfc control={control} name='labelAsTh' />
          </FormDataRow>
        </>
      )}
      <NewItemPopupFooter />
    </form>
  );
};

FormBuilderSectionForm.displayName = 'FormBuilderSectionForm';

export default FormBuilderSectionForm;
