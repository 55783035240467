import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { OrderResponse } from 'src/api';
import DescriptionField from 'src/components/fields/DescriptionField';
import SectionTitle from '../../SectionTitle';
import { FinishOrderFormValues } from '../schema';

export type ProductInfoFormProps = {
  orderProductIndex: number;
  order: OrderResponse;
};

const ProductInfoForm: FC<ProductInfoFormProps> = ({ order, orderProductIndex }) => {
  const { control } = useFormContext<FinishOrderFormValues>();

  return (
    <div className='flex w-full'>
      {!order?.orderType?.slConfig?.productsHiddenFeilds?.includes('problemDescription') && (
        <div className='flex w-full flex-col'>
          <SectionTitle className='mb-4'>
            <FormattedMessage id='app.finish_order.problem_description' />
          </SectionTitle>
          <Controller
            key={orderProductIndex}
            control={control}
            name={`products.${orderProductIndex}.problemDescription`}
            render={({ field, fieldState }) => (
              <DescriptionField
                {...field}
                className='max-w-sm h-48 border border-gray-300'
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
      )}

      {!order?.orderType?.slConfig?.productsHiddenFeilds?.includes('repairDescription') && (
        <div className='flex w-full flex-col'>
          <SectionTitle className='mb-4'>
            <FormattedMessage id='app.finish_order.repair_description' />
          </SectionTitle>
          <Controller
            key={orderProductIndex}
            control={control}
            name={`products.${orderProductIndex}.repairDescription`}
            render={({ field, fieldState }) => (
              <DescriptionField
                {...field}
                className='max-w-sm h-48 border border-gray-300'
                error={fieldState.error?.message}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default ProductInfoForm;
