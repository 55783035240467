import clsx from 'clsx';
import React, { ReactNode } from 'react';

export interface FormDataRowProps {
  label: ReactNode;
  children?: ReactNode;
  asterisk?: boolean;
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  className?: string;
  labelClassName?: string;
  labelWidthClass?: string;
  noMb?: boolean;
}

const FormDataRow: React.FC<FormDataRowProps> = ({
  children,
  className,
  label,
  labelClassName,
  asterisk,
  alignItems,
  labelWidthClass,
  noMb,
}) => (
  <div className={clsx('flex items-start w-full', !noMb && 'mb-6', alignItems ?? 'items-center', className)}>
    <div className={clsx('text-gray-800 md:mr-10 w-32 mr-6', labelClassName, labelWidthClass ?? 'md:w-36')}>
      <>
        {label}
        {asterisk && '*'}
      </>
    </div>
    {children}
  </div>
);

export default FormDataRow;
