import { useQuery } from '@tanstack/react-query';
import { useId, useMemo, useState } from 'react';
import { AutocompleteBase, AutocompleteBaseProps } from 'src/components/fields/Autocomplete';
import queryKeysFactory from 'src/store/queryKeysFactory';
import useDebounce from 'src/hooks/useDebounce';
import { FormattedMessage } from 'react-intl';
import { forwardRef } from 'react';
import { ForwardedRef } from 'react';
import useLastDefined from 'src/hooks/useLastDefined';

export type AutocompleteBeProps<T, TValue extends string | number | null> = Omit<
  AutocompleteBaseProps<T, TValue>,
  'options' | 'query' | 'onQueryChange'
> & {
  defaultOptions?: T[];
  getOptions: (query: string) => Promise<T[]>;
  initialData?: T[];
};

const emptyArray: unknown[] = [];

const AutocompleteBeInner = <T, TValue extends string | number | null>(
  { getOptions, initialData, defaultOptions, ...rest }: AutocompleteBeProps<T, TValue>,
  ref?: ForwardedRef<HTMLInputElement>,
): JSX.Element => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce<string>(query, 200);

  const id = useId(); // probably should use some uniq id form props to prevent data leaks on remount
  const { queryKey } = queryKeysFactory.autocomplete.detail(id, debouncedQuery);
  const {
    data: options,
    isFetching,
    isPlaceholderData,
  } = useQuery(queryKey, () => getOptions(debouncedQuery) ?? [], {
    enabled: !!debouncedQuery,
    initialData,
  });
  const lastDefinedOptions = useLastDefined(options);

  const notFoundMessage = useMemo(() => {
    if (isPlaceholderData || isFetching) return <FormattedMessage id='app.common.loading' />;
    return <FormattedMessage id='app.autocomplete.nothing_found' />;
  }, [isFetching, isPlaceholderData]);

  const usedOptions =
    lastDefinedOptions && (lastDefinedOptions?.length ?? 0) > 0
      ? lastDefinedOptions
      : defaultOptions ?? (emptyArray as T[]);

  return (
    <AutocompleteBase
      {...rest}
      externalFilter
      ref={ref}
      notFoundMessage={notFoundMessage}
      query={query}
      onQueryChange={setQuery}
      options={usedOptions}
      hideButton
    />
  );
};

const AutocompleteBe = forwardRef(AutocompleteBeInner) as <T, TValue extends string | number | null>(
  props: AutocompleteBeProps<T, TValue> & {
    ref?: ForwardedRef<HTMLInputElement>;
  },
) => JSX.Element;

export default AutocompleteBe;
