import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';
import { OrderListItemResponse } from 'src/api';
import UpcomingOrderListItem from '../UpcomingOrderListItem';

export type UpcomingOrdersListProps = {
  orders: OrderListItemResponse[];
} & HTMLAttributes<HTMLDivElement>;

const UpcomingOrdersList: FC<UpcomingOrdersListProps> = ({ orders, className }) => {
  return (
    <div className={clsx(className)}>
      <div className='overflow-hidden border-y border-gray-300'>
        <ul role='list' className='divide-y divide-gray-300'>
          {orders.map((item) => (
            <UpcomingOrderListItem key={item.orderNumber} data={item} />
          ))}
        </ul>
      </div>
    </div>
  );
};

UpcomingOrdersList.displayName = 'UpcomingOrdersList';

export default UpcomingOrdersList;
