import clsx from 'clsx';
import { addMinutes, format, startOfDay } from 'date-fns';
import { FC, useRef, useState } from 'react';
import useMoveOrder from 'src/api/hooks/mutations/order/useMoveOrder';
import useTechnicianGridEventsFromDay from 'src/api/hooks/querySelectors/useTechnicianGridEventsFromDay';
import EventsGrid from 'src/components/EventsGrid';
import DraggableEvent from 'src/components/EventsGrid/components/DraggableEvent';
import HorizontalLines from 'src/components/EventsGrid/components/HorizontalLines';
import HoursBar from 'src/components/EventsGrid/components/HoursBar';
import VerticalLines from 'src/components/EventsGrid/components/VerticalLines';
import { MINUTES_PER_ROW, ROWS_PER_HOUR } from 'src/components/EventsGrid/constants';
import { eventToGridDay, getGridCellDimensions, gridToTimeSpanDay } from 'src/components/EventsGrid/helpers/grid';
import useHandleDropEvent from 'src/components/EventsGrid/hooks/useHandleDropEvent';
import MiniCalendar from 'src/components/MiniCalendar';
import WithLoader from 'src/components/utils/WithLoader';
import { snapToHalfHour } from 'src/helpers/datetime';
import AddServicePopup from 'src/pages/HomePage/AddServicePopup';
import { OrderSchema } from 'src/pages/HomePage/ServiceForm/schema';
import useTechnicianCalendarOutletContext from 'src/pages/TechnicianAccountPage/hooks/useTechnicianCalendarOutletContext';

const START_HOUR = 8;
const END_HOUR = 20;

const DayTab: FC = () => {
  const { calendar, technicianId, disableDrag } = useTechnicianCalendarOutletContext();
  const { selectedDate } = calendar;
  const containerRef = useRef<HTMLDivElement>(null);
  const events = useTechnicianGridEventsFromDay(technicianId, selectedDate);
  const { isLoading } = useMoveOrder();
  const handleDrop = useHandleDropEvent(events);

  const [isAddServicePopupOpen, setIsAddServicePopupOpen] = useState(false);
  const [addServicePopupDefaultValues, setAddServicePopupDefaultValues] = useState<Partial<OrderSchema>>({});

  const handleGridClick = (_col: number, row: number): void => {
    const date = startOfDay(selectedDate);
    const start = addMinutes(date, snapToHalfHour(START_HOUR * 60 + MINUTES_PER_ROW * row));
    const end = addMinutes(start, 60);

    setAddServicePopupDefaultValues({
      technicianIds: [{ technicianId }],
      _date: start,
      _start: format(start, 'HH:mm'),
      _end: format(end, 'HH:mm'),
    });
    setIsAddServicePopupOpen(true);
  };

  return (
    <>
      <WithLoader isLoading={isLoading}>
        <div className={clsx('flex flex-col border border-gray-200 rounded-b-lg')}>
          <div className='grid sm:grid-cols-2 md:grid-cols-1 grid-cols-[1.5fr,1fr] w-full'>
            <div className='flex flex-col w-full border-r border-gray-200 overflow-y-scroll max-h-[500px]'>
              <EventsGrid
                olClassName='pr-8 my-7'
                gridToTimeSpan={gridToTimeSpanDay}
                eventToGrid={eventToGridDay}
                getGridCellDimensions={getGridCellDimensions}
                events={events}
                cols={1}
                rows={ROWS_PER_HOUR * (END_HOUR - START_HOUR)}
                startHour={START_HOUR}
                endHour={END_HOUR}
                scrollContainerRef={containerRef}
                onDrop={handleDrop}
                onGridClick={handleGridClick}
                renderEvent={(event, props) => (
                  <DraggableEvent key={event.id} disableDrag={disableDrag} {...event} {...props} />
                )}
                renderHoursBar={() => <HoursBar />}
                renderHorizontalLines={() => <HorizontalLines />}
                renderVerticalLines={() => <VerticalLines />}
              />
            </div>
            <div className='w-full h-max flex justify-center px-3 pt-24 sm:hidden md:hidden'>
              <MiniCalendar calendar={calendar} />
            </div>
          </div>
        </div>
      </WithLoader>
      <AddServicePopup
        defaultValues={addServicePopupDefaultValues}
        open={isAddServicePopupOpen}
        onClose={() => setIsAddServicePopupOpen(false)}
      />
    </>
  );
};

DayTab.displayName = 'DayTab';

export default DayTab;
