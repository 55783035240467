import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { UseCalendarReturn } from 'src/components/Calendar/hook';
import MiniMonth from './components/MiniMonth';
import MiniCalendarHeader from './components/MiniCalendarHeader';

export type MiniCalendarProps = {
  calendar: UseCalendarReturn;
} & HTMLAttributes<HTMLDivElement>;

const MiniCalendar: FC<MiniCalendarProps> = ({ className, calendar }) => {
  const { focusedDate, onDateClick, onNextMonth, onPrevMonth, month } = calendar;

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <MiniCalendarHeader focusedDate={focusedDate} onNext={onNextMonth} onPrev={onPrevMonth} />
      <MiniMonth month={month} showWeekDays focusedDate={focusedDate} onDateClick={onDateClick} />
    </div>
  );
};

MiniCalendar.displayName = 'MiniCalendar';

export default MiniCalendar;
