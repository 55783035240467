import { FinishOrderProductRequest } from 'src/api';
import { CustomerSignatureSchema, OrderFinishSchema } from './schema';

export function sanitizeForm(form: OrderFinishSchema): Partial<OrderFinishSchema> {
  const clonedForm = structuredClone(form) as Partial<OrderFinishSchema>;

  delete clonedForm.customerSignature?.orderSignatureCustomer;
  delete clonedForm.orderSignatureTechnician;

  if (clonedForm.products) {
    clonedForm.products = clonedForm.products.map((product) => {
      delete (product as Partial<FinishOrderProductRequest & { images: File[] }>).images;
      return product;
    });
  }

  clonedForm.customerSignature = {
    absentCustomer: clonedForm?.customerSignature?.absentCustomer ?? false,
  } as CustomerSignatureSchema;

  return clonedForm;
}
