import { isSameWeek, parseISO } from 'date-fns';
import { flatten } from 'ramda';
import { useMemo } from 'react';
import { GridEvent } from 'src/components/EventsGrid/types';
import orderListItemToGridEvent from 'src/helpers/orderListItemToGridEvents';
import useTechnicianMonthOrders from '../queries/useTechnicianMonthOrders';

const useTechnicianGridEventsFromWeek = (technicianId: number, startOfWeekDate: Date): GridEvent[] => {
  const year = startOfWeekDate.getFullYear();
  const monthIndex = startOfWeekDate.getMonth();

  const { data: monthOrders } = useTechnicianMonthOrders(technicianId, monthIndex, year);

  return useMemo(
    () =>
      flatten(
        monthOrders
          ?.filter((order) => order.datetime && isSameWeek(parseISO(order.datetime), startOfWeekDate))
          .map(orderListItemToGridEvent) ?? [],
      ).filter((event) => event.technicianId === technicianId),
    [monthOrders, startOfWeekDate],
  );
};

export default useTechnicianGridEventsFromWeek;
