import { FC } from 'react';
import { useParams } from 'react-router-dom';
import TechnicianCalendar from 'src/components/TechnicianCalendar';

export type SingleTechnicianCalendarProps = {
  className?: string;
};

const SingleTechnicianCalendar: FC<SingleTechnicianCalendarProps> = ({ className }) => {
  const technicianId = Number(useParams().technicianId);

  return <TechnicianCalendar className={className} technicianId={technicianId} />;
};

SingleTechnicianCalendar.displayName = 'SingleTechnicianCalendar';

export default SingleTechnicianCalendar;
