import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import Title from 'src/components/Title';
import ImportMaterialsCard from './components/ImportMaterialsCard';
import ImportCustomersCard from './components/ImportCustomersCard';

const ImportsPage: FC = () => {
  return (
    <>
      <Title className='mb-20 sm:mb-12'>
        <FormattedMessage id='app.imports_page.title' />
      </Title>
      <div className='flex flex-col md:flex-row w-full gap-4'>
        <ImportMaterialsCard />
        <ImportCustomersCard />
      </div>
    </>
  );
};

export default ImportsPage;
