import clsx from 'clsx';
import { range } from 'ramda';
import { CSSProperties, FC } from 'react';
import useEventsGridSettings from 'src/components/EventsGrid/hooks/useEventsGridSettings';

export type HorizontalLinesMTDProps = {
  className?: string;
  style?: CSSProperties;
};

const HorizontalLinesMTD: FC<HorizontalLinesMTDProps> = ({ className, style }) => {
  const { ROWS } = useEventsGridSettings();
  const rowsRange = range(1, ROWS + 1);

  return (
    <div
      className={clsx('col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100', className)}
      style={{
        gridTemplateRows: `repeat(${ROWS}, minmax(4rem, 1fr))`,
        ...style,
      }}
    >
      {rowsRange.map((i) => (
        <div key={i} />
      ))}
    </div>
  );
};

HorizontalLinesMTD.displayName = 'HorizontalLinesMTD';

export default HorizontalLinesMTD;
