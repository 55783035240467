import { useCallback } from 'react';

const useSaveBlob = (): ((blob: Blob, fileName: string) => void) =>
  useCallback((blob: Blob, fileName: string) => {
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.download = fileName;
    a.target = '_blank';
    a.href = url;
    a.click();
  }, []);

export default useSaveBlob;
