import clsx from 'clsx';
import { format } from 'date-fns';
import { FC } from 'react';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';

export type MtdHeaderProps = {
  className?: string;
  date: Date;
};

const MtdHeader: FC<MtdHeaderProps> = ({ className, date }) => {
  const { data: locale } = useUserFnsLocale();

  return (
    <div className={clsx('flex flex-col overflow-hidden', className)}>
      <div className='w-full flex items-center h-16 px-6 bg-gray-70 text-md font-semibold text-gray-900'>
        <div className='flex flex-col'>
          <span>{format(date, 'MMM d, yyyy', { locale, weekStartsOn: 1 })}</span>
          <span className='text-gray-500 text-sm font-medium'>{format(date, 'EEEE', { locale, weekStartsOn: 1 })}</span>
        </div>
      </div>
    </div>
  );
};

MtdHeader.displayName = 'MtdHeader';

export default MtdHeader;
