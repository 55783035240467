import cronTime from 'cron-time-generator';
import { format, setHours, setMinutes, subMinutes } from 'date-fns';
import { range } from 'ramda';
import { FC } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { NotificationResponse } from 'src/api';
import useUpdateNotificationSetting from 'src/api/hooks/mutations/notificationSettings/useUpdateNotificationSetting';
import Button from 'src/components/Button';
import Checkbox from 'src/components/fields/Checkbox';
import DescriptionField from 'src/components/fields/DescriptionField';
import Select from 'src/components/fields/Select';
import FormDataRow from 'src/components/FormDataRow';
import Title from 'src/components/Title';
import WithLoader from 'src/components/utils/WithLoader';
import { TIME_SELECT_VALUES } from 'src/pages/HomePage/ServiceForm/constants';
import { NotificationSettingsSchema } from './schema';
import clsx from 'clsx';
import { zodResolver } from '@hookform/resolvers/zod';
import SeparatedTextField from 'src/components/fields/MultiInput/SeparatedTextField';

export type DispatcherBeforeRoutineServiceEmailFormProps = {
  data: NotificationResponse;
  className?: string;
};

const DAYS_SELECT_OPTIONS = range(1, 14);

const timeOptionToCron = (time: string): string => {
  const [hours, minutes] = time.split(':').map(Number);
  const date = setMinutes(setHours(new Date(), hours), minutes);

  return cronTime.everyDayAt(date.getUTCHours(), date.getUTCMinutes());
};

const cronToTimeOption = (cron: string): string => {
  const [munutes, hours] = cron.split(' ');
  const dateUtc = setMinutes(setHours(new Date(), Number(hours)), Number(munutes));
  const date = subMinutes(dateUtc, dateUtc.getTimezoneOffset());

  return format(date, 'HH:mm');
};

const notificationSettingsToFormValues = (data: NotificationResponse): NotificationSettingsSchema => ({
  text: data.text,
  title: data.title,
  targets: data.targets,
  daysBeforeEvent: data.daysBeforeEvent,
  time: cronToTimeOption(data.cron),
  defaultEnabled: !!data.defaultEnabled,
});

const DispatcherBeforeRoutineServiceEmailForm: FC<DispatcherBeforeRoutineServiceEmailFormProps> = ({
  data,
  className,
}) => {
  const { control, handleSubmit } = useForm<NotificationSettingsSchema>({
    resolver: zodResolver(NotificationSettingsSchema),
    defaultValues: notificationSettingsToFormValues(data),
  });

  const updateNotificationSettings = useUpdateNotificationSetting();

  const onSubmit: SubmitHandler<NotificationSettingsSchema> = (formData) => {
    const { time, daysBeforeEvent, text, defaultEnabled, targets } = formData;
    const cron = timeOptionToCron(time);

    updateNotificationSettings.mutate({ id: data.id, daysBeforeEvent, text, cron, targets, defaultEnabled });
  };

  return (
    <WithLoader isLoading={updateNotificationSettings.isLoading}>
      <div className={clsx('flex flex-col max-w-xl my-10', className)}>
        <Title className='text-2xl mb-8' data-joyride='notifications.employee_email'>
          <FormattedMessage id='app.notification.dispatcher_before_routine_service' />
        </Title>
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
          <FormDataRow label={<FormattedMessage id='app.notification.days_before_event' />}>
            <Controller
              name='daysBeforeEvent'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={DAYS_SELECT_OPTIONS}
                  getOptionLabel={(option) => option.toString()}
                  getOptionValue={(option) => option}
                />
              )}
            />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.common.time' />}>
            <Controller
              name='time'
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={TIME_SELECT_VALUES}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                />
              )}
            />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.notification.text' />}>
            <Controller name='text' control={control} render={({ field }) => <DescriptionField {...field} />} />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.notification.title' />}>
            <Controller name='title' control={control} render={({ field }) => <DescriptionField {...field} />} />
          </FormDataRow>

          <Controller
            name='targets'
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <SeparatedTextField
                name={name}
                label={<FormattedMessage id='app.notification.targets' />}
                value={value ?? ''}
                separator=';'
                onChange={onChange}
                FormDataRowProps={{
                  labelWidthClass: 'w-48',
                  asterisk: true,
                }}
              />
            )}
          />

          <FormDataRow label={<FormattedMessage id='app.notification.default_enabled' />}>
            <Controller name='defaultEnabled' control={control} render={({ field }) => <Checkbox {...field} />} />
          </FormDataRow>
          <Button type='submit'>
            <FormattedMessage id='app.buttons.save' />
          </Button>
        </form>
      </div>
    </WithLoader>
  );
};

export default DispatcherBeforeRoutineServiceEmailForm;
