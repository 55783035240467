import { FC, useEffect } from 'react';
import clsx from 'clsx';
import { ProductTemplateSchema } from './schema';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import FormDataRow from 'src/components/FormDataRow';
import { zodResolver } from '@hookform/resolvers/zod';

export type ProductTemplateFormProps = {
  defaultValues?: Partial<ProductTemplateSchema>;
  onSubmit: SubmitHandler<ProductTemplateSchema>;
  className?: string;
};

const ProductTemplateForm: FC<ProductTemplateFormProps> = ({ className, defaultValues, onSubmit }) => {
  const form = useForm<ProductTemplateSchema>({
    resolver: zodResolver(ProductTemplateSchema),
    defaultValues: defaultValues,
  });

  const { handleSubmit, control, setValue } = form;

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof ProductTemplateSchema, v));
  }, [defaultValues]);

  return (
    <form className={clsx('flex flex-col w-full', className)} onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...form}>
        <FormDataRow asterisk label={<FormattedMessage id='app.product.name' />}>
          <TextFieldRhfc control={control} name='productName' />
        </FormDataRow>

        <FormDataRow asterisk label={<FormattedMessage id='app.product.producer' />}>
          <TextFieldRhfc control={control} name='productProducer' />
        </FormDataRow>

        <NewItemPopupFooter />
      </FormProvider>
    </form>
  );
};

ProductTemplateForm.displayName = 'ProductTemplateForm';

export default ProductTemplateForm;
