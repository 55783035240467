/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  addDays,
  differenceInCalendarDays,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  getDate,
  getMonth,
  isEqual,
  isToday,
  Locale,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { DateInfo, MonthInfo } from './types';

export const findNextRangeIndex = (ranges: any[], currentRangeIndex = -1): number => {
  const nextIndex = ranges.findIndex(
    (range, i) => i > currentRangeIndex && range.autoFocus !== false && !range.disabled,
  );
  if (nextIndex !== -1) return nextIndex;
  return ranges.findIndex((range) => range.autoFocus !== false && !range.disabled);
};

export const getMonthDisplayRange = (
  date: number | Date,
  fixedHeight?: number | boolean,
  selectedDate?: Date,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  },
): MonthInfo => {
  const today = startOfDay(new Date());
  const startDateOfMonth = startOfMonth(date);
  const endDateOfMonth = endOfMonth(date);
  const startDateOfCalendar = startOfWeek(startDateOfMonth, options);
  let endDateOfCalendar = endOfWeek(endDateOfMonth, options);

  if (fixedHeight && differenceInCalendarDays(endDateOfCalendar, startDateOfCalendar) <= 34) {
    endDateOfCalendar = addDays(endDateOfCalendar, 7);
  }
  const datesInfo: DateInfo[] = eachDayOfInterval({ end: endDateOfCalendar, start: startDateOfCalendar }).map((d) => ({
    date: d,
    day: getDate(d),
    disabled: d < today,
    isSelectedMonth: getMonth(startDateOfMonth) === getMonth(d),
    isSelected: selectedDate && isEqual(d, selectedDate),
    isToday: isToday(d),
  }));

  return {
    datesInfo,
    end: endDateOfCalendar,
    endDateOfMonth,
    start: startDateOfCalendar,
    startDateOfMonth,
  };
};
