import { FinishOrderProductRequest, FinishOrderRequest } from 'src/api';
import { SignatureValue } from 'src/components/Signature';
import { z } from 'zod';

export type FinishOrderFormValues = Omit<FinishOrderRequest, 'products' | 'slConfigOutput'> & {
  orderSignatureCustomer: SignatureValue;
  orderSignatureTechnician: SignatureValue;
  products: Array<FinishOrderProductRequest & { images: File[] }>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slConfigOutput: any;
};

const OrderFinishProductMaterialSchema = z.object({
  material: z.string().nullable().default(null),
  price: z.number().nullable().default(null),
  quantity: z.number().nullable().default(null),
  code: z.string().nullable().default(null),
  unit: z.string().nullable().default(null),
});

const OrderFinishProductSchema = z
  .object({
    materials: z.array(OrderFinishProductMaterialSchema).default([]),
    repairDescription: z.string().nullable().default(null),
    problemDescription: z.string().nullable().default(null),
    orderProductId: z.number(),
  })
  .passthrough();

const customerAbsentSignatureSchema = z.object({
  absentCustomer: z.literal(true),
  orderSignatureCustomer: z.any().nullish(),
});
const customerPresentSignatureSchema = z.object({
  absentCustomer: z.literal(false),
  orderSignatureCustomer: z.object({
    dataUrl: z.string(),
    image: z.unknown(),
  }),
});
const CustomerSignatureSchema = z.discriminatedUnion('absentCustomer', [
  customerAbsentSignatureSchema,
  customerPresentSignatureSchema,
]);
export type CustomerSignatureSchema = z.infer<typeof CustomerSignatureSchema>;

export const OrderFinishSchema = z
  .object({
    contactEmail: z.string().nullable().default(null),
    products: z.array(OrderFinishProductSchema).default([]),
    serviceMileage: z.number().nullable().default(null),
    serviceTimeOnRoad: z.number().nullable().default(null),
    serviceDuration: z.number().nullable().default(null),
    serviceTotalPrice: z.number().nullable().default(null),
    servicePaymentMethodId: z.number().nullable().default(null),
    preferredCurrency: z.string().nullable().default(null),
    signingTechnicianId: z.number().nullable().default(null),
    slConfigOutput: z.any().nullable().default(null),
    deliveryType: z.nativeEnum(FinishOrderRequest.deliveryType).nullable().default(null),
    workTariffId: z.number().nullable().default(null),
    deliveryRateId: z.number().nullable().default(null),
    signingCustomerName: z.string().nullable().default(null),
    deliverySaleType: z.nativeEnum(FinishOrderRequest.deliverySaleType).nullable().default(null),
    deliverySaleValue: z.number().nullable().default(null),
    workSaleType: z.nativeEnum(FinishOrderRequest.workSaleType).nullable().default(null),
    workSaleValue: z.number().nullable().default(null),
    materialSaleType: z.nativeEnum(FinishOrderRequest.materialSaleType).nullable().default(null),
    materialSaleValue: z.number().nullable().default(null),
    orderSignatureTechnician: z.object({
      dataUrl: z.string(),
      image: z.unknown(),
    }),
    customerSignature: CustomerSignatureSchema,
  })
  .passthrough();
export type OrderFinishSchema = z.infer<typeof OrderFinishSchema>;

export const OrderFinishWithRealTimeSchema = OrderFinishSchema.extend({
  realStart: z.string().min(1),
  realEnd: z.string().min(1),
});
export type OrderFinishWithRealTimeSchema = z.infer<typeof OrderFinishWithRealTimeSchema>;
