import clsx from 'clsx';
import { ChangeEvent, forwardRef, InputHTMLAttributes, useCallback } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import withController, { WithControllerProps } from 'src/hoc/withController';
import { FieldProps } from 'src/types/forms';
import HelperText from '../../common/HelperText';

export type TextFieldBaseProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> & {
  hideErrorMessage?: boolean;
  inputAdornment?: React.ReactNode;
  value?: string | null;
  error?: string | boolean;
};

export type TextFieldProps = FieldProps<string | null | undefined> & TextFieldBaseProps;

const TextFieldBase = forwardRef<HTMLInputElement, TextFieldBaseProps>(
  ({ className, inputAdornment, disabled, error, onChange, hideErrorMessage, type, value, ...rest }, ref) => {
    return (
      <div className='flex flex-col w-full relative'>
        <input
          className={clsx(
            className,
            'w-full border border-gray-300 rounded font-openSans font-normal text-base px-2 h-10',
            inputAdornment && 'pl-8',
            !disabled && error && 'border-error',
            disabled ? 'cursor-not-allowed bg-gray-300' : 'bg-white',
          )}
          disabled={disabled}
          onChange={onChange}
          type={type}
          value={value ?? ''}
          ref={ref}
          {...rest}
        />
        {inputAdornment && (
          <div className='absolute top-5 left-2 translate-y-[-50%] max-w-5 max-h-5'>{inputAdornment}</div>
        )}
        {!disabled && !hideErrorMessage && error && typeof error === 'string' && (
          <HelperText variant='error'>{error}</HelperText>
        )}
      </div>
    );
  },
);

export type TextFieldControlledProps = Omit<TextFieldProps, 'onChange'> & {
  onChange: (value: string) => void;
};

export const TextFieldControlled = forwardRef<HTMLInputElement, TextFieldControlledProps>((props, ref) => {
  const { onChange, ...rest } = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return <TextFieldBase {...rest} onChange={handleChange} ref={ref} />;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const TextFieldRhfc = withController(TextFieldControlled as any) as <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: Omit<TextFieldProps, 'value' | 'onChange'> & WithControllerProps<TFieldValues, TName>,
) => JSX.Element;

export default TextFieldBase;
