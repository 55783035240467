import { ClockIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { format } from 'date-fns';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { GridEvent } from 'src/components/EventsGrid/types';

export type EventsListProps = {
  events: GridEvent[];
  className?: string;
};

const EventsList: FC<EventsListProps> = ({ className, events }) => {
  return (
    <div className={clsx(className, 'flex flex-col w-full rounded-b-lg px-4 py-6 bg-gray-70')}>
      <ul role='list' className='bg-white border border-gray-300 rounded-lg divide-y divide-gray-200'>
        {events.map((event) => (
          <li key={event.id} className='px-6 py-4 flex w-full'>
            <div className='flex flex-col gap-y-2'>
              <span className='text-md font-semibold'>
                <FormattedMessage
                  id='app.technician_account.calendar.month.order_title'
                  values={{
                    orderNumber: event.data.orderNumber,
                    companyName: event.data.companyName,
                  }}
                />
              </span>
              <div className='flex items-center gap-x-1'>
                <ClockIcon className='h-4 w-4 text-gray-600' />
                <span className='text-sm text-gray-500'>{format(event.start, 'dd/MM/yyyy - hh:mm')}</span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

EventsList.displayName = 'EventsList';

export default EventsList;
