import clsx from 'clsx';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderStatusResponse } from 'src/api';
import { match } from 'ts-pattern';

export const getStatusBackgroundClassNames = (name: OrderStatusResponse.name): string =>
  match(name)
    .with(OrderStatusResponse.name.PLANNED, () => 'bg-status-planned')
    .with(OrderStatusResponse.name.INVOICED, () => 'bg-status-paid')
    .with(OrderStatusResponse.name.COMPLETED, () => 'bg-status-success')
    .with(OrderStatusResponse.name.UNSUCCESSFUL, () => 'bg-status-failed')
    .with(OrderStatusResponse.name.ROUTINE, () => 'bg-status-routine')
    .with(OrderStatusResponse.name.DELETED, () => 'bg-status-deleted')
    .with(OrderStatusResponse.name.IN_PROGRESS, () => 'bg-status-progress')
    .exhaustive();

export type StatusLabelProps = {
  name: OrderStatusResponse.name;
};

const StatusLabel: React.FC<StatusLabelProps> = ({ name }) => {
  return (
    <span
      className={clsx(
        'flex items-center justify-center w-44 h-[36px] py-2 rounded-full text-white text-sm font-semibold',
        getStatusBackgroundClassNames(name),
      )}
    >
      <FormattedMessage id={`app.order_status.${name?.toLowerCase() as Lowercase<OrderStatusResponse.name>}`} />
    </span>
  );
};

export default StatusLabel;
