import { eachDayOfInterval, startOfWeek, endOfWeek, isEqual, startOfDay } from 'date-fns';
import { GridEvent } from 'src/components/EventsGrid/types';

const getDailyEvents = (date: Date, events: GridEvent[]): [Date, GridEvent[]][] => {
  const week = eachDayOfInterval({
    start: startOfWeek(date, { weekStartsOn: 1 }),
    end: endOfWeek(date, { weekStartsOn: 1 }),
  });

  const dailyEvents = week.map((date) => [
    date,
    events.filter((event) => isEqual(startOfDay(event.start), startOfDay(date))),
  ]) as [Date, GridEvent[]][];

  return dailyEvents;
};

export default getDailyEvents;
