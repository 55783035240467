import clsx from 'clsx';
import { FC, ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { OrderListItemResponse, useApiClient } from 'src/api';
import Button from 'src/components/Button';
import AutocompleteBe from 'src/components/fields/AutocompleteBe';
import FormDataRow from 'src/components/FormDataRow';
import { DEFAULT_TABLE_PAGINATION } from 'src/constants/table';
import { ServiceFilter } from 'src/types/filters';

export type OrderTargetFormProps = {
  className?: string;
  title: ReactNode;
  buttonText: ReactNode;
  filter?: Partial<Omit<ServiceFilter, 'technicianIds'>>;
  onSubmit: (orderId: number) => Promise<void>;
};

const OrderTargetForm: FC<OrderTargetFormProps> = ({ className, buttonText, filter, title, onSubmit }) => {
  const apiClient = useApiClient();
  const [orderId, setOrderId] = useState<number | null>(null);

  const getOrders = async (query: string): Promise<OrderListItemResponse[]> => {
    const { statusProp, paymentMethods, ...restFilter } = filter ?? {};

    const data = await apiClient.orders.getOrderList({
      orderNumber: query,
      ...DEFAULT_TABLE_PAGINATION,
      ...restFilter,
      ...(statusProp && { statusProp: statusProp.join(',') }),
      ...(paymentMethods && { paymentMethods: paymentMethods.join(',') }),
    });
    return data.items;
  };

  const handleSubmit = async (): Promise<void> => {
    if (!orderId) return;

    await onSubmit(orderId);
  };

  return (
    <div className={clsx(className, 'flex flex-col')}>
      <h2 className=' text-xl font-semibold text-gray-800 mb-8'>{title}</h2>
      <div className='flex'>
        <FormDataRow className='mb-4' label={<FormattedMessage id='app.features_page.order' />}>
          <AutocompleteBe
            value={orderId}
            onChange={setOrderId}
            getOptions={getOrders}
            getOptionLabel={(order) => `${order.orderNumber}`}
            getOptionValue={(order) => order.id}
          />
        </FormDataRow>
        <Button className='ml-12' onClick={handleSubmit}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

OrderTargetForm.displayName = 'OrderTargetForm';

export default OrderTargetForm;
