import { TrashIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import Button from 'src/components/Button';
import { twMerge } from 'tailwind-merge';
import { TextFieldControlled } from '../Textfield';

export type ArrayInputProps = {
  className?: string;
  disabled?: boolean;
  name: string;
  onChange: (value: string[]) => void;
  value: string[];
};

const ArrayInput: FC<ArrayInputProps> = ({ className, disabled, name, onChange, value }) => {
  const onInputValueChange = (index: number, inputValue: string | null | undefined): void => {
    const newInputs = value.map((input, i) => (i === index ? inputValue || '' : input));
    onChange(newInputs);
  };

  const deleteInput = (index: number): void => {
    const newInputs = value.filter((_, i) => i !== index);
    onChange(newInputs);
  };

  return (
    <div className={twMerge('flex flex-col gap-y-2 w-full', className)}>
      {value.map((input, index) => (
        <div className='flex w-full' id={name} key={index}>
          <TextFieldControlled
            name={`${name}[${index}]`}
            className={twMerge('border border-gray-300 rounded-md p-2')}
            value={input}
            onChange={(value) => onInputValueChange(index, value)}
          />
          {value.length > 1 && (
            <Button disabled={disabled} variant='cta' size='small' onClick={() => deleteInput(index)}>
              <TrashIcon className='h-6' />
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default ArrayInput;
