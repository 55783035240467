/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import useDeliveryRates from 'src/api/hooks/queries/useDeliveryRates';
import PageContentHeader from 'src/components/PageContentHeader';
import Popup from 'src/components/Popup';
import useTableState from 'src/components/Table/hooks/useTableState';
import useLastDefined from 'src/hooks/useLastDefined';
import usePageBaseState from 'src/hooks/usePageBaseState';
import { DeliveryRateFilter } from 'src/types/filters';
import AddDeliveryRateForm from './AddDeliveryRateForm';
import DeliveryRatesTable from './DeliveryRatesTable';

const defaultSortingState = [{ id: 'name', desc: false }];

const DeliveryRatesPage: FC = () => {
  const { isFilterOpen, isPopupOpen, handleClosePopup, handleOpenPopup, handleToggleFilter } = usePageBaseState();

  const {
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
    debouncedServerFriendlyFilters,
    sortingQuery,
  } = useTableState<DeliveryRateFilter>({
    defaultSortingState,
  });

  const { data, isLoading } = useDeliveryRates(debouncedServerFriendlyFilters, sortingQuery, pagination);
  const definedData = useLastDefined(data);

  const isEmpty = !isLoading && definedData?.items.length === 0;

  return (
    <>
      <PageContentHeader
        mobilePrimaryButtonType='new'
        title={<FormattedMessage id='app.delivery_rates_page.title' />}
        onSecondaryButtonClick={handleToggleFilter}
        onPrimaryButtonClick={handleOpenPopup}
        primaryButtonText={<FormattedMessage id='app.delivery_rates_page.new_rate' />}
      />
      {isEmpty ? (
        <FormattedMessage id='app.common.no_items' />
      ) : (
        <DeliveryRatesTable
          className='mt-16'
          isLoading={isLoading}
          data={definedData?.items ?? []}
          isFilterOpen={isFilterOpen}
          columnFilters={columnFilters}
          sorting={sorting}
          pagination={pagination}
          handleFilter={setColumnFilters}
          handleSort={setSorting}
          handlePaginationChange={setPagination}
          paginationMeta={definedData?.meta}
        />
      )}
      <Popup
        title={<FormattedMessage id='app.delivery_rates_page.new_rate' />}
        onClose={handleClosePopup}
        open={isPopupOpen}
      >
        <AddDeliveryRateForm />
      </Popup>
    </>
  );
};

export default DeliveryRatesPage;
