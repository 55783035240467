import { FC } from 'react';
import { CreateDeliveryTariffRequest } from 'src/api';
import { useForm, FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import { NumberFieldRhfc } from 'src/components/fields/NumberField';
import { TextFieldRhfc } from 'src/components/fields/Textfield';
import FormDataRow from 'src/components/FormDataRow';
import { DeliveryTariffSchema } from './schema';
import { zodResolver } from '@hookform/resolvers/zod';

export type DeliveryTariffFormProps = {
  defaultValues?: Partial<CreateDeliveryTariffRequest>;
  onSubmit: (data: CreateDeliveryTariffRequest) => void;
  className?: string;
};

const DEFAULT_VALUES: Partial<CreateDeliveryTariffRequest> = {
  name: '',
  price: 0,
  priceEur: 0,
};

const DeliveryTariffForm: FC<DeliveryTariffFormProps> = ({ defaultValues, onSubmit }) => {
  const form = useForm<DeliveryTariffSchema>({
    defaultValues: { ...DEFAULT_VALUES, ...defaultValues },
    resolver: zodResolver(DeliveryTariffSchema),
  });

  const { handleSubmit, control } = form;

  return (
    <FormProvider {...form}>
      <div className='flex-col w-full'>
        <form className='flex flex-col w-full' onSubmit={handleSubmit(onSubmit)}>
          <FormDataRow asterisk label={<FormattedMessage id='app.delivery_tariff.name' />}>
            <TextFieldRhfc control={control} name='name' />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.delivery_tariff.price' />}>
            <NumberFieldRhfc control={control} name='price' />
          </FormDataRow>
          <FormDataRow label={<FormattedMessage id='app.common.price_eur' />}>
            <NumberFieldRhfc control={control} name='priceEur' />
          </FormDataRow>
          <NewItemPopupFooter />
        </form>
      </div>
    </FormProvider>
  );
};

DeliveryTariffForm.displayName = 'DeliveryTariffForm';

export default DeliveryTariffForm;
