import { format } from 'date-fns';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useMoveOrder from 'src/api/hooks/mutations/order/useMoveOrder';
import useUserFnsLocale from 'src/api/hooks/queries/useUserFnsLocale';
import { GridEvent } from 'src/components/EventsGrid/types';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';
import useWithConfirmationPopup from './popups/useWithConfirmationPopup';

export type MoveTechnicianFormValues = {
  sendTechnicianNotification: boolean;
  sendCustomerNotification: boolean;
};

const defaultValues = {
  sendTechnicianNotification: true,
  sendCustomerNotification: true,
};

const useMoveTechnicianEvent = (
  events: GridEvent[],
): ((id: number, newStart: Date, oldTechnicianId: number, newTechnicianId?: number) => void) => {
  const moveOrder = useMoveOrder();
  const withConfirmationPopup = useWithConfirmationPopup();
  const form = useForm<MoveTechnicianFormValues>({
    defaultValues,
  });
  const { control, handleSubmit } = form;
  const { data: fnsLocale } = useUserFnsLocale();

  return useCallback(
    (id: number, newStart: Date, oldTechnicianId: number, newTechnicianId?: number) => {
      const event = events.find((event) => event.id === id);
      if (!event) return;

      withConfirmationPopup({
        title: <FormattedMessage id='app.technician_calendar.confirm_move_event_popup.title' />,
        onOk: handleSubmit((data) => {
          moveOrder.mutate({
            id,
            start: newStart,
            oldStart: event.start,
            technicianId: oldTechnicianId,
            newTechnicianId,
            ignoreCustomerNotification: !data.sendCustomerNotification,
            ignoreTechnicianNotification: !data.sendTechnicianNotification,
          });
        }),
        text: (
          <div className='flex flex-col'>
            <div className='mb-6'>
              <FormattedMessage
                id='app.technician_calendar.confirm_move_event_popup.text'
                values={{
                  orderNumber: event.data.orderNumber,
                  oldStart: format(event.start, 'HH:mm d.M.yyyy', { locale: fnsLocale }),
                  newStart: format(newStart, 'HH:mm d.M.yyyy', { locale: fnsLocale }),
                }}
              />
            </div>
            <div className='flex mb-2'>
              <div className='w-64'>
                <FormattedMessage id='app.technician_calendar.send_technician_notification' />
              </div>
              <CheckboxRhfc control={control} name='sendTechnicianNotification' />
            </div>
            <div className='flex'>
              <div className='w-64'>
                <FormattedMessage id='app.technician_calendar.send_customer_notification' />
              </div>
              <CheckboxRhfc control={control} name='sendCustomerNotification' />
            </div>
          </div>
        ),
      })();
    },
    [events, moveOrder, withConfirmationPopup],
  );
};

export default useMoveTechnicianEvent;
