import React, { useEffect } from 'react';
import Textfield from 'src/components/fields/Textfield';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { CreateRoutineServicesRequest, UpdateRoutineServicesRequest } from 'src/api';
import FormDataRow from 'src/components/FormDataRow';
import { ServiceRoutineSchema } from './schema';
import NewItemPopupFooter from 'src/components/common/NewItemPopupFooter';
import NumberField from 'src/components/fields/NumberField';
import { zodResolver } from '@hookform/resolvers/zod';

interface RoutineServicesFormProps {
  defaultValues?: Partial<ServiceRoutineSchema>;
  onSubmit: SubmitHandler<ServiceRoutineSchema>;
  className?: string;
}

const RoutineServicesForm: React.FC<RoutineServicesFormProps> = ({ defaultValues, onSubmit, className }) => {
  const form = useForm<CreateRoutineServicesRequest & UpdateRoutineServicesRequest>({
    resolver: zodResolver(ServiceRoutineSchema),
    defaultValues,
  });
  const { handleSubmit, control, setValue } = form;

  useEffect(() => {
    if (!defaultValues) return;
    Object.entries(defaultValues).forEach(([k, v]) => setValue(k as keyof ServiceRoutineSchema, v));
  }, [defaultValues]);

  return (
    <div className={clsx(className, 'flex-col w-full')}>
      <form className='flex flex-col w-full' onSubmit={handleSubmit(onSubmit, console.error)}>
        <FormProvider {...form}>
          <FormDataRow asterisk label={<FormattedMessage id='app.routine_service.name' />}>
            <Controller
              control={control}
              name='routineName'
              render={({ field, fieldState }) => <Textfield {...field} type='text' error={fieldState.error?.message} />}
            />
          </FormDataRow>

          <FormDataRow asterisk label={<FormattedMessage id='app.routine_service.value' />}>
            <Controller
              control={control}
              name='routineValue'
              render={({ field, fieldState }) => (
                <NumberField {...field} type='text' error={fieldState.error?.message} />
              )}
            />
          </FormDataRow>
          <NewItemPopupFooter />
        </FormProvider>
      </form>
    </div>
  );
};

export default RoutineServicesForm;
