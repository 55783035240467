import { FC } from 'react';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';
import { TextFieldControlled } from 'src/components/fields/Textfield';
import Button from 'src/components/Button';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

export type TranslationSearchBoxProps = {
  className?: string;
  onAddMissingTranslations: () => void;
  searchValue: string;
  onSearchValueChange: (value: string) => void;
};

const TranslationSearchBox: FC<TranslationSearchBoxProps> = ({
  className,
  searchValue,
  onAddMissingTranslations,
  onSearchValueChange,
}) => {
  return (
    <div className={clsx(className, 'flex items-center gap-x-4 mb-4')}>
      <span>
        <FormattedMessage id='app.translations_page.search' />
      </span>
      <TextFieldControlled name='search' value={searchValue} onChange={onSearchValueChange} />
      <Button
        variant='cta'
        size='small'
        className='flex w-max whitespace-nowrap'
        onClick={onAddMissingTranslations}
        disabled={!!searchValue}
      >
        <PlusCircleIcon className='h-6 mr-1' />
        <FormattedMessage id='app.translations_page.button.add_missing' />
      </Button>
    </div>
  );
};

TranslationSearchBox.displayName = 'TranslationSearchBox';

export default TranslationSearchBox;
