import { XMarkIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

export type DemoBannerContentProps = {
  onDismiss: () => void;
  onContact: () => void;
  demoDaysRemaining: number;
};

const DemoBannerContent: FC<DemoBannerContentProps> = ({ onDismiss, onContact, demoDaysRemaining }) => {
  return (
    <div className='sticky justify-self-start py-2.5 top-0 z-[99999] flex items-center gap-x-6 bg-yellow-400 px-4 md:px-8 sm:before:flex-1'>
      {' '}
      <p className='text-sm leading-6 text-black'>
        <FormattedMessage id='app.banner.demo.days_remaining' values={{ value: demoDaysRemaining }} />
      </p>
      <button
        type='button'
        onClick={onContact}
        className='flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900'
      >
        <span aria-hidden='true'>
          <FormattedMessage id='app.banner.demo.contact_us' />
          &rarr;
        </span>
      </button>
      <div className='flex flex-1 justify-end'>
        <button onClick={onDismiss} type='button' className='-m-3 p-3 focus-visible:outline-offset-[-4px]'>
          <span className='sr-only'>
            <FormattedMessage id='app.banner.demo.dismiss' />
          </span>
          <XMarkIcon aria-hidden='true' className='h-5 w-5 text-black' />
        </button>
      </div>
    </div>
  );
};

export default DemoBannerContent;
