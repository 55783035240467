import clsx from 'clsx';
import { FC, ReactNode, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import useAllTechnicians from 'src/api/hooks/queries/useAllTechnicians';
import Tabs from 'src/components/common/Tabs';
import Select from 'src/components/fields/Select';

export type CalendarTabsProps = {
  className?: string;
};

export type CalendarPageTab = 'single' | 'all';

const TABS: Array<{ renderLabel: () => ReactNode; value: CalendarPageTab }> = [
  {
    renderLabel: () => <FormattedMessage id='app.calendar_page.tab.all' />,
    value: 'all',
  },
  {
    renderLabel: () => <FormattedMessage id='app.calendar_page.tab.single' />,
    value: 'single',
  },
];

const getTabFromPath = (path: string): CalendarPageTab => {
  const chunks = path.split('/');
  const calendarChunkIndex = chunks.findIndex((chunk) => chunk === 'calendar');

  if (!calendarChunkIndex) {
    throw new Error('useCalendarTab used outside of CalendarPage');
  }

  const tab = chunks[calendarChunkIndex + 1] as CalendarPageTab;

  return tab || 'all';
};

const CalendarTabs: FC<CalendarTabsProps> = ({ className }) => {
  const navigate = useNavigate();
  const technicianIdFormParams = Number(useParams().technicianId);
  const [showTechnicianSelector, setShowTechnicianSelector] = useState(!!technicianIdFormParams);
  const [selectedTechnicianId, setSelectedTechnicianId] = useState<number>(technicianIdFormParams ?? 0);
  const [tab, setTab] = useState(getTabFromPath(window.location.pathname));
  const { data: technicians } = useAllTechnicians({ enabled: showTechnicianSelector });

  const handleTabChange = useCallback(
    (value: CalendarPageTab): void => {
      if (value === 'all') {
        setTab(value);
        setSelectedTechnicianId(0);
        setShowTechnicianSelector(false);
        navigate('/calendar/all');
        return;
      }

      setShowTechnicianSelector(true);
      setTab(value);
    },
    [navigate],
  );

  const handleTechnicianChange = useCallback(
    (technicianId: number) => {
      setSelectedTechnicianId(technicianId);
      navigate(`/calendar/single/${technicianId}`);
    },
    [navigate],
  );

  return (
    <div className={clsx(className, 'flex')}>
      <Tabs value={tab} onChange={handleTabChange} tabs={TABS} variant='line' />
      {showTechnicianSelector && (
        <Select
          className='min-w-48 w-fit ml-4'
          name='technician'
          onChange={handleTechnicianChange}
          value={selectedTechnicianId}
          options={technicians ?? []}
          getOptionLabel={(u) => u.profile?.name ?? u.id.toString()}
          getOptionValue={(u) => u.id}
        />
      )}
    </div>
  );
};

CalendarTabs.displayName = 'CalendarTabs';

export default CalendarTabs;
