import clsx from 'clsx';
import { format, parse } from 'date-fns';
import React from 'react';
import { FieldProps } from 'src/types/commonTypes';
import HelperText from '../../common/HelperText';

export type DatePickerProps = Omit<
  FieldProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement, Date | null>,
  'onChange'
> & {
  error?: boolean | string;
  hideErrorMessage?: boolean;
  onChange?: (date: Date | null) => void;
};

const DatePicker: React.ForwardRefRenderFunction<HTMLInputElement, DatePickerProps> = (props, ref) => {
  const { value, onChange, className, disabled, hideErrorMessage, error, ...rest } = props;

  return (
    <div className='flex flex-col w-full'>
      <input
        type='date'
        className={clsx('w-full h-10 rounded border border-gray-300 px-3', className)}
        value={value ? format(value, 'yyyy-MM-dd') : ''}
        disabled={disabled}
        onChange={(e) => {
          const date = e.target.value;

          if (!date) {
            onChange?.(null);
            return;
          }

          const prevDate = value ?? new Date();

          const parsedDate = parse(date, 'yyyy-MM-dd', prevDate);
          parsedDate.setHours(
            prevDate.getHours(),
            prevDate.getMinutes(),
            prevDate.getSeconds(),
            prevDate.getMilliseconds(),
          );

          onChange?.(parsedDate);
          return;
        }}
        {...rest}
        ref={ref}
      ></input>
      {!disabled && !hideErrorMessage && error && typeof error === 'string' && (
        <HelperText variant='error'>{error}</HelperText>
      )}
    </div>
  );
};

export default React.forwardRef(DatePicker);
